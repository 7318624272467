import React, { useContext, useState, useEffect } from 'react';

import FichaCarvao from './ficha-carvao.jsx';
import { useForm } from 'react-hook-form';
import coalService from '../../services/CoalService/coal-service.js';
import adminService from '../../services/AdminService/admin-service.js';
import { useLocation, useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';

import { AppSettings } from '../../config/app-settings.js';

import { useTranslation } from 'react-i18next';

export default function RegistroCarvao(props){
    const location = useLocation();
	const history = useHistory();
	const { user, ...settings } = useContext(AppSettings);
    const { t } = useTranslation();

	const { register, handleSubmit, watch, setValue, reset, formState: { errors, isSubmitting, isValid } } = useForm({ mode: "onBlur" });

    const [ listedSites, setListedSites] = useState(null);
    const [ listedFams, setListedFams] = useState(null);
    const [ listedGens, setListedGens] = useState(null);
    const [ listedEsp, setListedEsp] = useState(null);
    const [ listedUser, setListedUsers] = useState(null);
    const [ listedFields, setListedFields ] = useState(null);

    React.useEffect(() => {
        const subscription = watch((value, {name}) => {
            if(name == "family_id"){
                if(value.family_id == 0){ //Caso esteja selecionando nenhuma família agora, recarrega todos os gêneros e famílias e limpa as espécies
                    setListedGens(null);
                    register('species_id', { value: 0 });
                    setValue('species_id', 0);
                    adminService.getGenus().then(
                        response => {
                            setListedGens(response.data)
                        }
                    )
                    setListedEsp(null);
                } else {  
                    if(value.genus_id == 0){//No caso de escolher primeiro o dropdown de família
                        setListedGens(null);
                        adminService.listSpeciesByFamily(value.family_id).then(
                            response => {
                                setListedEsp(response.data);
                            }
                        )
                        adminService.listGenusByFamily(value.family_id).then(
                            response => {
                                setListedGens(response.data);
                            }
                        )
                    }else {//ou de a familia recarregar ao escolher o genero
                        adminService.getFamilyByGenusId(value.genus_id).then(
                            response => {
                                if(response.data[0].id != value.family_id){
                                    setListedGens(null);
                                    register('species_id', { value: 0 });
                                    setValue('species_id', 0);
                                    register('genus_id', { value: 0 });
                                    setValue('genus_id', 0);
                                    adminService.listSpeciesByFamily(value.family_id).then(
                                        response => {
                                            setListedEsp(response.data);
                                        }
                                    )
                                    adminService.listGenusByFamily(value.family_id).then(
                                        response => {
                                            setListedGens(response.data);
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            }else if( name == "genus_id" ){
                if( value.genus_id == 0){ //Está selecionando nenhum gênero, tem q limpar a seleção de espécie
                    if(value.species_id != 0){
                        adminService.listSpeciesByFamily(value.family_id).then(
                            response => {
                                setListedEsp(response.data);
                                register('species_id', { value: 0 });
                                setValue('species_id', 0);
                            }
                        )
                    }                    
                }else{
                    if(value.family_id == 0){ //Esse é o caso de escolher o dropdown de genero antes do dropdown de familia.
                        adminService.getFamilyByGenusId(value.genus_id).then(
                            response => {
                                register('family_id', { value: response.data[0].id });
                                setValue('family_id', response.data[0].id);
                            }
                        )
                    }
                    adminService.listSpeciesByGenus(value.genus_id).then(
                        response => {
                            setListedEsp(response.data)
                        }
                    )
                } 
                
            }else if ( name == "species_id" ){ //Aqui é quando a família é escolhida primeiro, e então a espécie.
                if(value.genus_id == 0){
                    adminService.getGenusBySpeciesId(value.species_id).then(
                        response => {
                            setListedGens(response.data)
                            register('genus_id', { value: response.data[0].id });
                            setValue('genus_id', response.data[0].id);
                        }
                    )
                }
            }
        });

        return () => {subscription.unsubscribe();};
    }, [watch]);

    const search = async (data) => {
        if(user) data.isPrivate = true;
        else data.isPrivate = false;
        let charsString = '';
        listedFields?.map((tab, i) => {
            tab.children?.map((label, j)=>{
                if(label.type == 'multi-select'){
                    label.children.map((option, k)=>{
                        if(option.type == 'multi-select-option'){
                            if(!data.charsAr[option.id]) charsString += '0';
                            else{
                                if(data.charsAr[option.id]==false) charsString += '0';
                                else charsString += '1';
                            }
                            option.children?.map((mso, l)=>{
                                if(mso.type == 'mso-child'){
                                    if(!data.charsAr[mso.id]) charsString += '0';
                                    else{
                                        if(data.charsAr[mso.id]==false) charsString += '0';
                                        else charsString += '1';
                                    }
                                }
                            })
                        }
                    })
                }
            })
        })

        data.chars = charsString;

        try {
            const response = await coalService.searchCoal(data);
            if (response.data.length == 0) {
                settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('carvão.pesquisa.modal.semResult'));
            } else {
                history.push({pathname: '/pesquisa-resultado', state: {coals : response.data, fields: listedFields, search: data}, from: '/pesquisa'});
            }
        } catch (err) {
            settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
            console.log(err);
        } 
    }

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;
        adminService.listAllFamilies().then(
            response => {
                if(_isMounted){
                    setListedFams(response.data)
                }
            }
        )
        adminService.listUsers().then(
			response => {
				if(_isMounted){
					setListedUsers(response.data);
				}
			}
		)
        coalService.getAllSites().then(
            response => {
                if(_isMounted && (response.data.length > 0)){
                    setListedSites(response.data);
                }
            }
        )  
        adminService.getGenus().then(
            response => {
                if(_isMounted){
                    setListedGens(response.data)
                }
            }
        )
        if(location.from){
            location.state.searchData.charsAr.map((c, id) => { //recebendo e marcando os chars dinamicos tb
                if(c == true){
                    register('charsAr.'+id, {value: true});
                }else{
                    register('charsAr.'+id, {value: false});
                }
            })
        }
       
		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	}, [])

    if(user === null){
        return(
            <div className="fa-3x carregando-pagina ">
                <i className="fas fa-spinner fa-pulse"></i>
            </div>
        )
    }else{
        return(<>
            <form onSubmit={handleSubmit(search)}>
                <div className="position-fixed h-auto d-flex flex-row justify-content-between page-header-fixed align-items-right" >
                    <h1 className="page-header ph-no-margin d-flex flex-column fs-18px" >{t('carvão.pesquisa.titulo')}</h1>
                    <div className='ph-no-margin d-flex flex-row align-items-center' >
                        <div className="form d-flex flex-row align-items-center">
                            <label className="form-label me-2 w-auto">{t('carvão.pesquisa.botão.margemDeErro')}:</label>
                            <input name="tolerance" type="number" defaultValue={0} className="form-control fs-14px fw-lighter w-75px h-38px me-2" min="0" id="tolerance" {...register('tolerance')}/>
                        </div>
                        <button type="submit" disabled={isSubmitting || !isValid}
                            className="btn btn-success d-block w-100 btn-lg h-38px fs-13px me-2" >
                                {isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
                                {t('carvão.pesquisa.botão.pesquisa')}
                        </button>
                    </div>  
                </div>
            
                <div className='row h-auto' style={{ marginTop: '55px' }}>
                    <div className='col'>
                        <Panel>
                            <PanelHeader className="pt-1 pb-1" noButton={true}>
                                <div className="d-flex align-items-center">
                                    <div className="col d-flex align-items-center">

                                        <select name="type" className="col form-select me-3 " id="nome" {...register('type')} >
                                            <option value='0'>{t('carvão.dropdown.categoria.selecioneCategoria')}</option>
                                            <option value='1'>{t('carvão.dropdown.categoria.arqueologicos')}</option>
                                            <option value='2'>{t('carvão.dropdown.categoria.lenhosReferencia')}</option>
                                            <option value='3'>{t('carvão.dropdown.categoria.literatura')}</option>
                                        </select>    

                                        {(user && (user.is_admin == 1 || user.is_admin == 2) && listedUser === null) &&
                                            <div>
                                                <i className="fas fa-spinner fa-pulse"></i>
                                            </div>
                                        }

                                        {(user && (user.is_admin == 1 || user.is_admin == 2) && listedUser  !== null) &&
                                            <select name="user_id" className="form-select fs-12px fw-lighter w-25" id="user_id" {...register('user_id')}>
                                                <option value='0'>{t('carvão.EscolhaUser')}</option>
                                                {
                                                    listedUser.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                }
                                            </select>
                                        } 
                                        
                                        <div className="col-4"></div>

                                        <div className="col-1 align-items-center ms-3">
                                            <div className="form-floating">
                                            <input name="number" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.fichaNum')} id="snumbercientific_name" {...register('number')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.fichaNum')}</label>    
                                            </div>
                                        </div>        
                                                                 
                                    </div>
                                </div>                                        
                            </PanelHeader>
                            <PanelBody className="mb-n2">
                                <div className='row mb-1'>                         
                                    <div className='col'>
                                        <div className="form mb-1">
                                            {
                                                listedFams === null &&
                                                <div>
                                                    <br/><i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            }
                                            {
                                                listedFams &&
                                                <select name="family_id" className="form-select fs-12px fw-lighter" id="family_id" {...register('family_id')}>
                                                    <option value='0'>{t('carvão.dropdown.selecioneFamília')}</option>
                                                    {
                                                        listedFams.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('glossario.taxonomias.familia')}</label>
                                            }
                                        </div>   
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <input name="sub_fam" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder={t('carvão.subFamilia')} id="sub_fam" {...register('sub_fam')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.subFamilia')}</label>
                                        </div> 
                                    </div>
                                    <div className='col'>
                                        <div className="form">                                            
                                            {
                                                listedGens === null &&
                                                <div>
                                                    <br/><i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            }
                                            {
                                                listedGens &&
                                                <select name="genus_id" className="form-select fs-12px fw-lighter" id="genus_id" {...register('genus_id')}>
                                                    <option value='0'>{t('carvão.dropdown.selecioneGênero')}</option>
                                                    {
                                                        listedGens.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('glossario.taxonomias.genero')}</label>
                                            }
                                        </div> 
                                    </div>
                                    <div className='col'>
                                        <div className="form">
                                            {
                                                listedEsp === null &&
                                                <select className="form-select fs-12px fw-lighter">
                                                    <option value='0'>{t('carvão.dropdown.selecioneFamíliaOuGênero')}</option>
                                                </select>
                                            }
                                            {
                                                listedEsp &&
                                                <select name="species_id" className="form-select fs-12px fw-lighter" id="species_id" {...register('species_id')}>
                                                    <option value='0'>{t('carvão.dropdown.selecioneEspécie')}</option>
                                                    {
                                                        listedEsp.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('glossario.taxonomias.especie')}</label>
                                            }
                                        </div> 
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-floating">
                                            <input name="determination" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder={t('carvão.determinação')} id="determination" {...register('determination')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.determinação')}</label>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className='row mb-1'>
                                    <div className='col-5'>
                                        <div className="form-floating">
                                            <input name="origin" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.procedência')} id="origin" {...register('origin')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.procedência')}</label>
                                        </div>                                        
                                    </div>
                                    <div className='col'>
                                        <div className="form">
                                            {
                                                listedSites === null &&
                                                <div>
                                                    <br/><i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            }
                                            {
                                                listedSites &&                                                
                                                <select name="site" className="form-select fs-12px fw-lighter" id="site" {...register('site')}>
                                                    <option value="0">{t('carvão.dropdown.selecioneSítio')}</option>
                                                    {
                                                        listedSites.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('carvão.sítio')}</label>
                                            }
                                        </div>  
                                    </div>                                  
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <input name="code" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.código')} id="code" {...register('code')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.código')}</label>
                                        </div>                                        
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <input name="locale" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.localização')} id="locale" {...register('locale')}/>
                                            <label className="d-flex align-items-center fs-13px text-gray-600">{t('carvão.localização')}</label>
                                        </div>                                        
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                    <FichaCarvao register={register} setListedFields={setListedFields} textFields={false} coalSearch={true}/>
                </div>  
            </form>              
        </>)
    }
}