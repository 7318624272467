// import React, { useContext, useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

export default{
    // const { t, i18n } = useTranslation();

    insertEspecInText(t, coal, fieldList, i18n){
        let text = '';
        let coalTabs = [];
        let coalFields = [];
        let language = "name_" + i18n.language;

        coal.espec.map((e, i) => {
            coalTabs.push(e.id);
            e.fields.map((f, ind) => {
                coalFields.push(f.id)
            });
        });
        
        if(coalTabs.includes(fieldList[0].id)){
            let e = coal.espec.find(element => element.id == fieldList[0].id);
            text += fieldList[0][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[0].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[0].children[0].id);
                text += t('relatório.texto.complementos.limites') + " ";
                f.values.map((v, index) => {
                    text += v.name + ", ";
                })
            }
            if(coalFields.includes(fieldList[0].children[1].id)){
                let f = e.fields.find(element => element.id == fieldList[0].children[1].id);
                text += t('relatório.texto.complementos.marcadosPor') + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[0].children[2].id]);
        }
        else{
            text += this.insertObservationInText(t, coal, [fieldList[0].children[2].id], fieldList[0][language]);
        }
        
        if(coalTabs.includes(fieldList[1].id)){
            let e = coal.espec.find(element => element.id == fieldList[1].id);
            text += fieldList[1][language] + t('relatório.texto.doisPontos') + " ";
            
            if(coalFields.includes(fieldList[1].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[0].id);
                f.values.map((v, index) => {
                    text += v.name + ", ";
                })
            }
            if(coalFields.includes(fieldList[1].children[1].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[1].id);
                f.values.map((v, index) => {
                    text += v.name + ", ";
                })
            }
            if(coalFields.includes(fieldList[1].children[2].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[1].children[4].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[4].id);
                text += t('relatório.texto.complementos.diametroTangencialMédio') + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[1].children[3].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[3].id);
                text += fieldList[1].children[3][language].toLowerCase() + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[1].children[5].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[5].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + " ") : (v.name + ", ");
                })
                text += t('relatório.texto.complementos.vasos/mm') + t('relatório.texto.pontoVírgula') + " ";
            }
            if(coalFields.includes(fieldList[1].children[6].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[6].id);
                text += fieldList[1].children[6][language].toLowerCase() + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[1].children[7].id)){
                let f = e.fields.find(element => element.id == fieldList[1].children[7].id);
                text += fieldList[1].children[7][language].toLowerCase() + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalTabs.includes(fieldList[2].id)){
                let ee = coal.espec.find(element => element.id == fieldList[2].id);
                if(coalFields.includes(fieldList[2].children[0].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[0].id);
                    text += fieldList[2].children[0][language] + " ";
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                    })
                }
                if(coalFields.includes(fieldList[2].children[1].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[1].id);
                    text += fieldList[2].children[1][language].toLowerCase() + " ";
                    f.values.map((v, index) => {
                        text += v.name + ", ";
                    })
                }
                if(coalFields.includes(fieldList[2].children[5].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[5].id);
                    text += t('relatório.texto.complementos.guarnecidas') + " ";
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name) : (v.name + ", ");
                    })
                    text += t('relatório.texto.pontoVírgula') + " ";
                }
                if(coalFields.includes(fieldList[2].children[2].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[2].id);
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name.toLowerCase() + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                    })
                }
                if(coalFields.includes(fieldList[2].children[3].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[3].id); // em francês aqui tem dois caras com o mesmo nome aí tá pegando o errado
                    text += fieldList[2].children[3][language].toLowerCase() + " ";
                    f.values.map((v, index) => {
                        text += v.name + ", ";
                    })
                }
                if(coalFields.includes(fieldList[2].children[4].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[4].id);
                    text += fieldList[2].children[4][language].toLowerCase() + " ";
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                    })
                }
                if(coalFields.includes(fieldList[2].children[6].id)){
                    let f = ee.fields.find(element => element.id == fieldList[2].children[6].id);
                    text += t('relatório.texto.complementos.raioVasculares') + " ";
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                    })
                }
                if(coalFields.includes(fieldList[2].children[7].id)){
                    text += fieldList[2].children[7].children[0][language].toLowerCase() + ". ";
                }
            }
            if(coalTabs.includes(fieldList[3].id)){
                let ee = coal.espec.find(element => element.id == fieldList[3].id);
                if(coalFields.includes(fieldList[3].children[0].id)){
                    let f = ee.fields.find(element => element.id == fieldList[3].children[0].id);
                    text += fieldList[3].children[0][language] + " ";
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                    })
                }
            }
            if(coalFields.includes(fieldList[1].children[8].id)){
                text += (fieldList[1].children[8].children[0][language] + ". ");
            }
            text += this.insertObservationInText(t, coal, [fieldList[1].children[9].id, fieldList[2].children[8].id, fieldList[3].children[1].id]);
        }
        else{
            text += this.insertObservationInText(t, coal, [fieldList[1].children[9].id, fieldList[2].children[8].id, fieldList[3].children[1].id], fieldList[1][language]);
        }

        if(coalTabs.includes(fieldList[4].id)){
            let e = coal.espec.find(element => element.id == fieldList[4].id);
            text += fieldList[4][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[4].children[2].id)){
                let f = e.fields.find(element => element.id == fieldList[4].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[4].children[1].id)){
                text += (fieldList[4].children[1].children[0][language].toLowerCase() + t('relatório.texto.pontoVírgula') + " ");
            }
            if(coalFields.includes(fieldList[4].children[3].id)){
                let f = e.fields.find(element => element.id == fieldList[4].children[3].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[4].children[5].id)){
                let f = e.fields.find(element => element.id == fieldList[4].children[5].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[4].children[0].id)){
                text += (fieldList[4].children[0].children[0][language] + ". ")
            }
            if(coalFields.includes(fieldList[4].children[4].id)){
                text += (fieldList[4].children[4].children[0][language] + ". ")
            }
            text += this.insertObservationInText(t, coal, [fieldList[4].children[6].id]);
        }
        else{
            text += this.insertObservationInText(t, coal, [fieldList[4].children[6].id], fieldList[4][language]);
        }

        if(coalTabs.includes(fieldList[5].id)){
            let e = coal.espec.find(element => element.id == fieldList[5].id);
            text += fieldList[5][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[5].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[5].children[0].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[5].children[4].id)){
                let f = e.fields.find(element => element.id == fieldList[5].children[4].id);
                if(language == "name_pt") { text += t('relatório.texto.complementos.corpoFormado') + " "; }
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[5].children[1].id)){
                let f = e.fields.find(element => element.id == fieldList[5].children[1].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + " ") : (v.name + ", ");
                })
                text += t('relatório.texto.complementos.raios/mm') + ". ";
            }
            if(coalFields.includes(fieldList[5].children[2].id)){
                let f = e.fields.find(element => element.id == fieldList[5].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[5].children[3].id)){
                let f = e.fields.find(element => element.id == fieldList[5].children[3].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalTabs.includes(fieldList[6].id)){
                let ee = coal.espec.find(element => element.id == fieldList[6].id);
                if(coalFields.includes(fieldList[6].children[0].id)){                           
                    let f = ee.fields.find(element => element.id == fieldList[6].children[0].id);
                    text += fieldList[6].children[0][language] + " ";
                    f.values.map((v, index) => {
                        text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                    })
                }
            }
            if(coalFields.includes(fieldList[5].children[5].id)){
                text += (fieldList[5].children[5].children[0][language] + ". ")
            }
            text += this.insertObservationInText(t, coal, [fieldList[5].children[6].id, fieldList[6].children[1].id]);
        }
        else{
            text += this.insertObservationInText(t, coal, [fieldList[5].children[6].id, fieldList[6].children[1].id], fieldList[5][language]);
        }

        if(coalTabs.includes(fieldList[7].id)){
            let e = coal.espec.find(element => element.id == fieldList[7].id);
            text += fieldList[7][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[7].children[1].id)){
                let f = e.fields.find(element => element.id == fieldList[7].children[1].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[7].children[2].id)){
                let f = e.fields.find(element => element.id == fieldList[7].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[7].children[3].id)){
                if(language != "name_en") { text += t('relatório.texto.complementos.paredeCelular') + " "; }
                let f = e.fields.find(element => element.id == fieldList[7].children[3].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[7].children[4].id)){
                text += (fieldList[7].children[4].children[0][language] + ". ")
            }
            if(coalFields.includes(fieldList[7].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[7].children[0].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[7].children[5].id]);
        }
        else{
            text += this.insertObservationInText(t, coal, [fieldList[7].children[5].id], fieldList[7][language]);
        }

        if(coalTabs.includes(fieldList[8].id)){
            let e = coal.espec.find(element => element.id == fieldList[8].id);
            text += fieldList[8][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[8].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[8].children[0].id);
                text += fieldList[8].children[0][language].toLowerCase() + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[8].children[1].id)){
                let f = e.fields.find(element => element.id == fieldList[8].children[1].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + " ") : (v.name + ", ");
                })
                text += t('relatório.texto.complementos.comDiametroTangencial') + " ";
                text += this.insertObservationInText(t, coal, [fieldList[8].children[1].children[1].children[3].id], undefined, 3);
            }
            if(coalFields.includes(fieldList[8].children[2].id)){
                let f = e.fields.find(element => element.id == fieldList[8].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[8].children[3].id]);
        }
        else{
            text += fieldList[8][language] + t('relatório.texto.doisPontos') + " " + t('relatório.texto.complementos.nãoObservados') + ". ";
            text += this.insertObservationInText(t, coal, [fieldList[8].children[3].id]);
        }

        if(coalTabs.includes(fieldList[9].id)){
            let e = coal.espec.find(element => element.id == fieldList[9].id);
            text += fieldList[9][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[9].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[9].children[0].id);
                text += fieldList[9].children[0][language].toLowerCase() + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[9].children[1].id)){
                let f = e.fields.find(element => element.id == fieldList[9].children[1].id);
                if(language == "name_pt") { text += t('relatório.texto.complementos.presençaMin') + " "; }
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[9].children[2].id)){
                let f = e.fields.find(element => element.id == fieldList[9].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[9].children[3].id)){
                let f = e.fields.find(element => element.id == fieldList[9].children[3].id);
                text += fieldList[9].children[3][language] + " ";
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[9].children[4].id]);
        }
        else{
            text += fieldList[9][language] + t('relatório.texto.doisPontos') + " " + t('relatório.texto.complementos.nãoObservadas') + ". ";
            text += this.insertObservationInText(t, coal, [fieldList[9].children[4].id]);
        }

        if(coalTabs.includes(fieldList[10].id)){
            let e = coal.espec.find(element => element.id == fieldList[10].id);
            text += fieldList[10][language] + t('relatório.texto.doisPontos') + " ";

            if(coalFields.includes(fieldList[10].children[0].id)){
                let f = e.fields.find(element => element.id == fieldList[10].children[0].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + t('relatório.texto.pontoVírgula') + " ") : (v.name + ", ");
                })
            }
            if(coalFields.includes(fieldList[10].children[1].id)){
                text += (fieldList[10].children[1].children[0].id + ". ")
            }
            if(coalFields.includes(fieldList[10].children[2].id)){
                if(language == "name_pt"){ 
                    text += t('relatório.texto.complementos.presençaMai') + " " + 
                    fieldList[10].children[2].children[0][language] + ". "; 
                }
                else{ 
                    text += fieldList[10].children[2].children[0][language] + " " + 
                    t('relatório.texto.complementos.presente') + ". ";
                }
            }
            text += this.insertObservationInText(t, coal, [fieldList[10].children[3].id]);
        }
        else{
            text += fieldList[10][language] + t('relatório.texto.doisPontos') + " " + t('relatório.texto.complementos.nãoObservadas') + ". ";
            text += this.insertObservationInText(t, coal, [fieldList[10].children[3].id]);
        }

        text += "\n";

        if(coalTabs.includes(fieldList[11].id)){
            let e = coal.espec.find(element => element.id == fieldList[11].id);
            text += fieldList[11][language] + t('relatório.texto.doisPontos') + "\n";

            e.fields.map((f, ind) => {
                text += (f.id == 333) ? (t('relatório.texto.complementos.areaDeDistribuição') + t('relatório.texto.doisPontos') + " ") : 
                (f.name + t('relatório.texto.doisPontos') + " ");
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
                text += this.insertObservationInText(t, coal, [fieldList[11].children[ind].children[fieldList[11].children[ind].children.length - 1].id],
                    undefined, 0);
            })
            text += "\n";
        }

        if(coalTabs.includes(fieldList[12].id)){
            let e = coal.espec.find(element => element.id == fieldList[12].id);
            text += t('relatório.texto.dadosEtno') + t('relatório.texto.doisPontos') + "\n";

            text += this.insertObservationInText(t, coal, [fieldList[12].children[0].id], t('relatório.texto.complementos.nomesPopulares'), 1);

            if(coalFields.includes(fieldList[12].children[1].id)){
                text += fieldList[12].children[1][language] + t('relatório.texto.doisPontos') + " ";
                let f = e.fields.find(element => element.id == fieldList[12].children[1].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[12].children[1].children[16].id], undefined, 0);

            if(coalFields.includes(fieldList[12].children[2].id)){
                text += fieldList[12].children[2][language] + t('relatório.texto.doisPontos') + " ";
                let f = e.fields.find(element => element.id == fieldList[12].children[2].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[12].children[2].children[13].id], undefined, 0);

            text += this.insertObservationInText(t, coal, [fieldList[12].children[3].id], fieldList[12].children[3][language], 1);

            if(coalFields.includes(fieldList[12].children[5].id)){
                text += fieldList[12].children[5][language] + t('relatório.texto.doisPontos') + " ";
                let f = e.fields.find(element => element.id == fieldList[12].children[5].id);
                f.values.map((v, index) => {
                    text += (index == f.values.length - 1) ? (v.name + ". ") : (v.name + ", ");
                })
            }
            text += this.insertObservationInText(t, coal, [fieldList[12].children[5].children[4].id], undefined, 0);
            
            text += "\n";
        }
        
        text += fieldList[13][language] + t('relatório.texto.doisPontos') + "\n";
        text += this.insertObservationInText(t, coal, [fieldList[13].children[0].id, fieldList[13].children[2].id, fieldList[13].children[1].id, fieldList[13].children[3].id],
            fieldList[13][language], 2, [fieldList[13].children[0][language], t('relatório.texto.complementos.notasTaxonomia'), fieldList[13].children[1][language], fieldList[13].children[3][language]]);
        
        return(text);
    },

    insertObservationInText(t, coal, obsevationList, tabText, type, fieldNameList){
        let text = '';
        if(coal.observations.length > 0){
            obsevationList.map((obs, id) => {
                coal.observations.map((o, i) => {
                    if(o.fields_id == obs && o.text != ''){
                        if(id == 0 && type != 2 && tabText) { text += tabText + t('relatório.texto.doisPontos') + " "; }

                        if(type == 0){
                            text += t('relatório.texto.nota') + t('relatório.texto.doisPontos') + " " + o.text + ".\n";
                        }
                        else if(type == 1){
                            text += o.text + ".\n";
                        }
                        else if(type == 2){
                            text += fieldNameList[id] + t('relatório.texto.doisPontos') + " " + o.text + ".\n";
                        }
                        else if(type == 3){
                            text += o.text + " μm. ";
                        }
                        else{
                            if(id == 0) { text += t('relatório.texto.notas') + t('relatório.texto.doisPontos') + " "; }
                            text += (id == obsevationList.length - 1) ? (o.text + ".\n") : (o.text + t('relatório.texto.pontoVírgula') + " ");
                        }
                    }
                })
            })
        }
        if(text == '') { text = "\n"; }
        return text;
    },

    getCoalReport(selectedCoalList, fieldList, t, i18n){
        let coalInfoAsString = selectedCoalList.reduce((accumulator, currentValue) =>
            accumulator + 
            
            t('glossario.taxonomias.familia') + t('relatório.texto.doisPontos') + " " + (currentValue.family ? (currentValue.family.name + (currentValue.sub_fam ? (", " + currentValue.sub_fam) : (''))) : '') + "\n" +
            t('relatório.texto.taxon') + t('relatório.texto.doisPontos') + " " + (currentValue.species ? (currentValue.species.name) : (currentValue.genus ? (currentValue.genus.name) : '')) + "\n" +
            t('relatório.texto.identificação') + t('relatório.texto.doisPontos') + " " + (currentValue.determination ? (currentValue.determination) : '') + "\n" +
            t('carvão.procedência') + t('relatório.texto.doisPontos') + " " + (currentValue.origin ? (currentValue.origin) : '') + "\n" +
            t('carvão.sítio') + t('relatório.texto.doisPontos') + " " + (currentValue.site ? (currentValue.site.name) : '') + "\n" +
            t('carvão.código') + t('relatório.texto.doisPontos') + " " + (currentValue.code ? (currentValue.code) : '') + "    " +
            t('carvão.localização') + t('relatório.texto.doisPontos') + " " + (currentValue.locale ? (currentValue.locale) : '') + "    " +
            t('relatório.texto.registro') + t('relatório.texto.doisPontos') + " " + currentValue.id + "\n" +
            t('navegação.dbs.titulo') + t('relatório.texto.doisPontos') + " " + (currentValue.type == 1 ? t('carvão.dropdown.categoria.arqueologicos') :
                (currentValue.type == 2 ? t('carvão.dropdown.categoria.lenhosReferencia') :
                    t('carvão.dropdown.categoria.literatura'))) + "\n\n" +

            this.insertEspecInText(t, currentValue, fieldList, i18n) + 
            
            t('relatório.texto.autoria') + t('relatório.texto.doisPontos') + " " + currentValue.user.name + ".\n\n\n\n\n\n"
            
            , ''
        );

        const link = document.createElement("a");

        const file = new Blob([coalInfoAsString], { type: 'text/rtf' });

        link.href = URL.createObjectURL(file);

        link.download = t('carvão.result.botão.relatorio') + " - " + t('glossario.carvão') + ".rtf";

        link.click();
        URL.revokeObjectURL(link.href);
    }
}