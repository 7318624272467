import { Redirect } from 'react-router-dom';

import Inicial from './../pages/dashboard/dashboard-v2.js';

import Login from './../pages/user/login-v3.js';
import NovaSenha from './../pages/user/nova-senha.js';
import TrocaSenha from './../pages/user/troca-senha.js';
import RegisterV3 from './../pages/user/register-v3.js';

import RegistroCarvao from '../pages/carvao/registro-carvao.js';

import MeusRegistros from '../pages/user-logado/meus-registros.js';
import MeusDados from './../pages/user-logado/meus-dados.js';

import GerenciaUsuarios from '../pages/admin/gerencia-usuarios.js';
import AutorizarPendentes from '../pages/admin/autorizar-pendentes.js';
import GerenciaSitios from '../pages/admin/gerencia-sitios.js';

import GerenciaTaxonomiaFamilia from '../pages/admin/taxonomia/gerencia-tax-fam.js';
import GerenciaTaxonomiaGenero from '../pages/admin/taxonomia/gerencia-tax-gen.js';
import GerenciaTaxonomiaEspecie from '../pages/admin/taxonomia/gerencia-tax-esp.js';
import Sobre from '../pages/dashboard/sobre.jsx';
import PesquisaCarvao from '../pages/carvao/pesquisa-carvao.js';
// import Registros from '../pages/carvao/listagem.js';
import Resultados from '../pages/carvao/resultados-pesquisa.js';

import BuscaTaxFam from '../pages/filtragens/busca-taxon-fam.js';
import BuscaTaxGen from '../pages/filtragens/busca-taxon-gen.js';

import FiltroTipo from '../pages/filtragens/buscas-tipo.js'

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/inicio'/>
  },
  {
    path: '/inicio',
    title: 'Inicio',
    component: () => <Inicial />
  },

  /* Login e Registro */

  {
    path: '/acesso',
    title: 'Login',
    component: () => <Login />,
  },
  {
    path: '/cadastro',
    title: 'Cadastro',
    component: () => <RegisterV3 />,
  },
  {
    path: '/esqueci-minha-senha',
    title: 'Nova Senha',
    component: () => <NovaSenha />,
  },
  {
    path: "/recuperar-senha/:token",
    title: 'Trocar Senha',
    component: () => <TrocaSenha />,
  },

  /* Banco de Dados - Taxonomia */

  {
    path: '/taxonomia/familia',
    title: 'Família',
    component: () => <BuscaTaxFam/>
  },
  {
    path: '/taxonomia/genero',
    title: 'Gênero',
    component: () => <BuscaTaxGen/>
  },
  
  /* Banco de Dados - Registros */

  {
    path: '/registros/todos',
    title: 'Todos os Registros',
    component: () => <FiltroTipo tipo={0}/>
  },
  {
    path: '/registros/arq-fosseis',
    title: 'Carvões Arqueológicos/Fósseis',
    component: () => <FiltroTipo tipo={1}/>
  },
  {
    path: '/registros/referencia',
    title: 'Carvões/Lenhos de Referência',
    component: () => <FiltroTipo tipo={2}/>
  },
  {
    path: '/registros/literatura',
    title: 'Literatura',
    component: () => <FiltroTipo tipo={3}/>
  },
  {
    path: '/registros/publicos',
    title: 'Registros públicos',
    component: () => <FiltroTipo tipo={4}/>
  },

  /* Pesquisa e Cadastro de carvão*/

  {
    path: '/pesquisa',
    title: 'Pesquisa',
    component: () => <PesquisaCarvao />
  },
  {
    path: '/pesquisa-resultado',
    title: 'Resultados',
    component: () => <Resultados />
  },
  {
    path: '/editar-carvao/:idToken',
    title: 'Editar Carvão',
    component: () => <RegistroCarvao />
  },
  {
    path: '/novo-registro',
    title: 'Novo Registro',
    component: () => <RegistroCarvao />
  },

  /* Administração */

  {
    path: '/sitios',
    title: 'Sítios',
    component: () => <GerenciaSitios/>
  },
  {
    path: '/admin/taxonomia/familias',
    title: 'Taxonomia',
    component: () => <GerenciaTaxonomiaFamilia />
  },
  {
    path: '/admin/taxonomia/generos',
    title: 'Taxonomia',
    component: () => <GerenciaTaxonomiaGenero />
  },
  {
    path: '/admin/taxonomia/especies',
    title: 'Taxonomia',
    component: () => <GerenciaTaxonomiaEspecie />
  },
  {
    path: '/admin/usuaries/pendentes',
    title: 'Autorizações Pendentes',
    component: () => <AutorizarPendentes/>
  },
  {
    path: '/admin/usuaries/gerencia',
    title: 'Gerência de Usuáries',
    component: () => <GerenciaUsuarios/>
  },

  /* Do usuário */

  {
    path: '/meus-dados',
    title: 'Perfil',
    component: () => <MeusDados />
  },
  {
    path: '/registros/meus',
    title: 'Meus Registros',
    component: () => <MeusRegistros/>
  },

  /* Padrão */

  {
    path: '/sobre',
    title: 'sobre',
    component: () => <Sobre />
  },
  {
    path: '/instrucoes',
    title: 'instruções',
    component: () => <Sobre />
  }

];

  


export default routes;