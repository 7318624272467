import React from 'react';

const PanelStat = React.createContext();

class Panel extends React.Component {
	constructor(props) {
		super(props);
		
		this.toggleExpand = () => {
			this.setState(state => ({
				expand: !this.state.expand
			}));
		}
		
		this.state = {
			expand: false,
			toggleExpand: this.toggleExpand
		}
	}
	render() {
		return (
			<PanelStat.Provider value={this.state}>
				{( 
					<div className={'panel panel-'+ (this.props.theme ? this.props.theme : 'inverse') +' '+ (this.state.expand ? 'panel-expand ' : ' ') + (this.props.className ? this.props.className : '')}>
						{ this.props.children }
					</div>
				)}
			</PanelStat.Provider>
		);
	}
};

class PanelHeader extends React.Component {
	render() {
		return (
			<div className={'panel-heading '+ this.props.className }>
				<h4 className="panel-title">{ this.props.children }</h4>
				{(!this.props.noButton &&
					<PanelStat.Consumer>
						{({ toggleExpand }) => (
							
							<div className="panel-heading-btn">
								<button type="button" className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp;
							</div>
						)}
					</PanelStat.Consumer>
				)}
			</div>
			
		)
	}
}

class PanelBody extends React.Component {
	render() {
		return (
			<PanelStat.Consumer>
				{() => (
					<div className={"panel-body " + this.props.className}>
						{ this.props.children }
					</div>
				)}
			</PanelStat.Consumer>
		);
	}
};

class PanelFooter extends React.Component {
	render() {
		return (
			<div className={"panel-footer "+ this.props.className}>
				{ this.props.children }
			</div>
		);
	}
};

export { Panel, PanelHeader, PanelBody, PanelFooter };
