import React, { useContext, useState, useEffect } from 'react';

import { AppSettings } from '../../config/app-settings.js';
import { useLocation, useHistory } from 'react-router-dom';
import coalService from '../../services/CoalService/coal-service.js';
import SemResultPanel from '../../components/nenhum-resultado-panel/nenhum-resultado.jsx';

export default function FiltroTipo(props){
    const location = useLocation();
    const history = useHistory();
    const { user, ...settings } = useContext(AppSettings);

    const [coalList, setCoalList] = useState();

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;
        let data;
        if(props.tipo == 4){
            data = {
                public: true,
                type: 0
            }
        }
        else{
            data = {
                public: true,
                type: props.tipo
            }
            if(user) data.public = false;
        }

        coalService.filterCoalByType(data).then(response => {
            if(response.data.length == 0){
                setCoalList('empty');
            }else{
                let resp = [];
                response.data.map((c, i) => {
                    resp.push([c, 'null']);
                })
                history.push({pathname: '/pesquisa-resultado', state: {coals : resp}, from: '/registros/todos'});
            }
        }).catch(
            err => {
                console.log(err);
            }
        )

		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	})

    return(
        <>
            { coalList == null &&
                <div className="fa-3x carregando-pagina">
                    <i className="fas fa-spinner fa-pulse"></i>
                </div>
		    }
            { coalList == 'empty' &&
                <SemResultPanel/>
			}
        </>    	
    )
}