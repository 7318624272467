import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';

import { AppSettings } from '../../config/app-settings.js';
import { useLocation, useHistory } from 'react-router-dom';
import userService from '../../services/UserService/user-service.js';
import SemPermissaoPanel from '../../components/sem-permissao-panel/sem-permissao-panel.jsx';


export default function MeusRegistros(props){
    const location = useLocation();
    const history = useHistory();
    const { user, ...settings } = useContext(AppSettings);

    
    const [coalList, setCoalList] = useState();

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;
        if(user){
            userService.getCoalsByUser(user.id).then(response =>{
                if(response.data.length == 0){
                    setCoalList('empty');
                }else{
                    let resp = [];
                    response.data.map((c, i) => {
                        resp.push([c, 'null']);
                    })
                    history.push({pathname: '/pesquisa-resultado', state: {coals : resp}, from: '/registros/meus'});
                }
            }).catch(
                err => {
                    console.log(err);
                }
            )

        }

		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	})

    return(
        <>
            { (user === null || ( user && coalList == null)) &&
                <div className="fa-3x carregando-pagina">
                    <i className="fas fa-spinner fa-pulse"></i>
                </div>
		    }
            { user===undefined && 
				<>
					<SemPermissaoPanel/>
				</>
			}
            { coalList == 'empty' &&
                <Panel> 
                    <PanelHeader noButton={true} className="h-40px">
                    </PanelHeader>
                    <PanelBody className="d-flex flex-column">
                        <p>Nenhum resultado encontrado</p>
                    </PanelBody>
                </Panel>
            }
        </>       
			
    )
}