import instance from '../../axios-instance';
import Cookies from 'js-cookie'

export default{    
    
    
    /*                      Administração de usuários                     */


    async listPendingUsers(page){ //lista usuarios ainda não aprovados pelo admin
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.get('/toApprove?page=' + page, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;
        }
    },

    async listUsers() { //lista usuários ativos/aprovados pelo admin
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.get('users/active', {
                headers: { 'Authorization': 'Bearer ' + token}
            });

            for(let i = 0; i< response.data.length; i++){
                if(response.data[i].is_admin === 0){
                    response.data[i].is_admin = "Usuárie"
                }else if(response.data[i].is_admin === 1){
                    response.data[i].is_admin = "Admin"
                }else{
                    response.data[i].is_admin = "SuperAdmin" 
                }
            }
            return response;
        } catch (err){
            throw err;
        }
    },

    async approveUser(id){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/aprovar', id, {
                headers: {'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;
        }
    },

    async removeUser(id){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/remover', id, {
                headers: {'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;
        }
    },

    async addAdmin(id) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/add/admin', id, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;

        }
    },

    async removeAdmin(id) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/remove/admin/' , id, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;

        }
    },










    /*                      Administração de sítios                     */


    async getSites(page) { //pega os sitios com paginacao
        try{
            const response = await instance.get('site?page=' + page,);

            return response;
        } catch (err){
            throw err;
        }
    },

    async getAllSites(page) { //pega os sitios sem paginacao
        try{
            const response = await instance.get('allSites');

            return response;
        } catch (err){
            throw err;
        }
    },

    async updateSite(data, id) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('site/' + id, data,
		    {
			    headers: { 'Authorization': 'Bearer ' + token}
		    });
            return response;
        } catch (err){
            throw err;
        }
    },

    async removeSite(id) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.delete('/removeSite/' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;
        }
    },

    async addSite(data) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.post('site', data, {
				headers: { 'Authorization': 'Bearer ' + token}
			});
            return response;
        } catch (err){
            throw err;
        }
    },







    /*                      Administração de taxonomia                     */

    async getFamilies(page){
        try{
            const response = await instance.get('family?page=' + page);

            return response;
        } catch (err){
            throw err;
            //alert('não foi possível pegar lista de famílias');
        }
    },

    async listAllFamilies(){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.get('/families', {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err){
            throw err;
            //alert('não foi possível pegar lista de famílias');
        }
    },

    async getFamilyByGenusId(id) { //pra listar especies + nome dos generos q estao relacionadas
        try {
            const response = await instance.get('showFamilyByGenus/' + id);
            return response;
        } catch (err) {
            console.log(err);
        }
    },

    async createFamily(form) {
		try {
            const token = Cookies.get('anth-token')
			const response = await instance.post('family',form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
			return response;
		} catch (err) {
			throw err;
		}
	},
	async updateFamily(form,id) {
		try {
            const token = Cookies.get('anth-token')
			const response = await instance.put('family/'+id,form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
			return response;
		} catch (err) {
			console.log(err);
		}
	},
	async deleteFamily(id) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.delete('family/'+ id,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return 1;
        } catch (err) {
            return 0;
        }
    },



    async getGenus() { //pra listar os generos + nome das familias q estao relacionadas
        try {
            const response = await instance.get('listGenera');
            return response;
        } catch (err) {
            console.log(err);
        }
    },
    
    async listGenusByFamily(id) { //pra listar especies + nome dos generos q estao relacionadas
        try {
            const response = await instance.get('listByFamily/' + id);
            return response;
        } catch (err) {
            console.log(err);
        }
    },

    async listAllGenus() {
        try {
            const response = await instance.get('listAllGenus');
            return response;
        } catch (err) {
            console.log(err);
        }
    },

    async getGenusBySpeciesId(id) { //pra listar especies + nome dos generos q estao relacionadas
        try {
            const response = await instance.get('showGenusBySpecies/' + id);
            return response;
        } catch (err) {
            console.log(err);
        }
    },

	async createGenus(form) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.post('genus/',form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err) {
            throw err;
        }
    },
	async updateGenus(form,id) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.put('genus/'+id,form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    },
	async deleteGenus(id) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.delete('genus/'+id,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    },





    async getSpecies() { //pra listar especies + nome dos generos q estao relacionadas
        try {
            const response = await instance.get('listAllSpecies');
            return response;
        } catch (err) {
            console.log(err);
        }
    },

    async listSpeciesByFamily(id) { //pra listar especies + nome dos generos q estao relacionadas
        try {
            const response = await instance.get('listSpeciesByFamily/' + id);
            return response;
        } catch (err) {
            console.log(err);
        }
    },

    async listSpeciesByGenus(id) { //pra listar especies + nome dos generos q estao relacionadas
        try {
            const response = await instance.get('listSpeciesByGenus/' + id);
            return response;
        } catch (err) {
            console.log(err);
        }
    },

	async createSpecies(form) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.post('species',form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err) {
            throw err;
        }
    },
	async updateSpecies(form,id) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.put('species/'+id,form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    },
	async deleteSpecies(id) {
        try {
            const token = Cookies.get('anth-token')
            const response = await instance.delete('species/'+id,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    },


}