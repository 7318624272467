import React from 'react';

import { useTranslation } from 'react-i18next';

export default function RelatoriosCarvao({printCoalList, fieldList}) {
    const { t, i18n } = useTranslation();

    function coalObservationWithoutCheckbox(i, o, tab, campo, firstElement){
        return(
            <div className='row' key={i}>
                {
                    firstElement == o.fields_id &&
                    (
                        <div>{t(tab['name_' + i18n.language])}</div>
                    )
                }
                <div key={i}>
                    {campo['name_' + i18n.language] + ": "}
                    {o.text}
                </div>
            </div>
        )
    }

    return(
        <div id="coal-pdf" className="print inputForPrint">
            <h4 style={{marginTop: "-100px"}}>{t('carvão.result.botão.relatorio')} - {t('glossario.carvão')}</h4>
            {
                printCoalList.map((coal, index) => {
                    return(
                        <div key={index}>
                            <div>
                                <div className='row'>
                                    <div className='col-2 w-50'>
                                        {t('glossario.taxonomias.familia') + ": "}
                                        {coal.family ? (coal.family.name) : ''}
                                    </div>
                                    <div className='col-2 w-50'>
                                        {t('carvão.subFamilia') + ": "}
                                        {coal.sub_fam ? (coal.sub_fam) : ''}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 w-50'>
                                        {t('glossario.taxonomias.genero') + ": "}
                                        {coal.genus ? (coal.genus.name) : ''}
                                    </div>
                                    <div className='col-2 w-50'>
                                        {t('glossario.taxonomias.especie') + ": "}
                                        {coal.species ? (coal.species.name) : ''}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 w-50'>
                                        {t('carvão.determinação') + ": "}
                                        {coal.determination ? (coal.determination) : ''}
                                    </div>
                                    <div className='col-2 w-50'>
                                        {t('carvão.fichaNum') + ": "}
                                        {coal.id}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 w-50'>
                                        {t('carvão.procedência') + ": "}
                                        {coal.origin ? (coal.origin) : ''}
                                    </div>
                                    <div className='col-2 w-50'>
                                        {t('carvão.sítio') + ": "}
                                        {coal.site ? (coal.site.name) : ''}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 w-50'>
                                        {t('carvão.código') + ": "}
                                        {coal.code ? (coal.code) : ''}
                                    </div>
                                    <div className='col-2 w-50'>
                                        {t('carvão.categoria') + ": "}
                                        {coal.type == 1 ? t('carvão.dropdown.categoria.arqueologicos') :
                                            coal.type == 2 ? t('carvão.dropdown.categoria.lenhosReferencia') :
                                                t('carvão.dropdown.categoria.literatura')}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 w-50'>
                                        {t('carvão.localização') + ": "}
                                        {coal.locale ? (coal.locale) : ''}
                                    </div>
                                    <div className='col-2 w-50'>
                                        {t('carvão.autor') + ": "}
                                        {coal.user.name}
                                    </div>
                                </div>
                            </div>

                            <br></br>

                            {
                                fieldList.map((tab, id, tabList) => {
                                    let firstElement = 0;
                                    let aba = coal.espec.find(element => element.id == tab.id);
                                    if(aba){
                                        return(
                                            <div key={id}>
                                                {t(aba.tab)}
                                                {
                                                    aba.fields.map((f, ind, elements) => {
                                                        return(
                                                            <div key={ind}>
                                                                { // Observacao com checkbox (antes das checkboxes)
                                                                    coal.observations.length > 0 &&
                                                                    (
                                                                        coal.observations.map((o, i) => {
                                                                            if(o.fields_id == f.id - 1 && o.fields_id == tab.id + 1){

                                                                                let campo = fieldList[id].children.find(element2 => element2.id == o.fields_id);

                                                                                if(campo && o.text != ''){
                                                                                    return(
                                                                                        <div key={i}>
                                                                                            {campo['name_' + i18n.language] + ": "}
                                                                                            {o.text}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })
                                                                    )
                                                                }

                                                                {(f.name != "") ? t(f.name) + ": " : ""}
                                                                {
                                                                    f.values.map((v, index) => {
                                                                        return(
                                                                            (index == 0) ? (v.name) : (", " + v.name)
                                                                        )
                                                                    })
                                                                }

                                                                { // Observacao com checkbox (depois das checkboxes)
                                                                    coal.observations.length > 0 &&
                                                                    (
                                                                        coal.observations.map((o, i) => {
                                                                            if((elements[ind + 1] && o.fields_id > f.id && o.fields_id < elements[ind + 1].id) ||
                                                                                (ind == elements.length - 1 && o.fields_id > f.id && o.fields_id < tabList[id + 1].id)){

                                                                                let campo = fieldList[id].children.find(element2 => element2.id == o.fields_id);

                                                                                if(!campo){ 
                                                                                    campo = fieldList[id].children.find(element => element.id == f.id)
                                                                                        .children.find(element2 => element2.id == o.fields_id)
                                                                                }

                                                                                if(!campo){
                                                                                    f.values.map((v, index) => {
                                                                                        let auxCampo = fieldList[id].children.find(element => element.id == f.id)
                                                                                                .children.find(element2 => element2.id == v.id);
                                                                                        if(auxCampo && auxCampo.children){
                                                                                            campo = auxCampo.children.find(element3 => element3.id == o.fields_id);
                                                                                        }
                                                                                    })
                                                                                }

                                                                                if(campo && o.text != ''){
                                                                                    return(
                                                                                        <div key={i}>
                                                                                            {campo['name_' + i18n.language] + ": "}
                                                                                            {o.text}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <br></br>
                                            </div>
                                        )
                                    }
                                    else{
                                        return(
                                            <div key={id}>
                                                {
                                                    coal.observations.length > 0 &&
                                                    ( // Observacao sem checkbox
                                                        coal.observations.map((o, i) => {
                                                            let campo = tab.children.find(element => element.id == o.fields_id);
                                                            if(campo){
                                                                if(o.text != ''){
                                                                    if(firstElement == 0) { firstElement = o.fields_id }
                                                                    return coalObservationWithoutCheckbox(i, o, tab, campo, firstElement);
                                                                }
                                                            } else {
                                                                return tab.children.map((element, j) => {
                                                                    if(element.children){
                                                                        campo = element.children.find(element2 => element2.id == o.fields_id);
                                                                        if(campo){
                                                                            if(o.text != ''){
                                                                                if(firstElement == 0) { firstElement = o.fields_id }
                                                                                return coalObservationWithoutCheckbox(i, o, tab, campo, firstElement);
                                                                            }
                                                                        } else {
                                                                           return element.children.map((element2, k) => {
                                                                                if(element2.children){
                                                                                    campo = element2.children.find(element3 => element3.id == o.fields_id);
                                                                                    if(campo){
                                                                                        if(o.text != ''){
                                                                                            if(firstElement == 0) { firstElement = o.fields_id }
                                                                                            return coalObservationWithoutCheckbox(i, o, tab, campo, firstElement);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        })
                                                    )
                                                }
                                                <br></br>
                                            </div>
                                        )
                                    }
                                })
                            }

                            <br></br>

                            {
                                coal.images.length > 0 &&
                                (
                                    <div>
                                        {t('carvão.result.camposImagem')}
                                        {
                                            coal.images.map((image, index, elements) => {
                                                return(
                                                    <div className="d-flex justify-content-center" key={index}>
                                                    {
                                                        index % 2 != 1 && (
                                                            <>
                                                                <img src={image.photo} className="rounded mx-auto d-block h-250px w-250px"/>
                                                                {
                                                                    index != coal.images.length - 1 && (
                                                                        <img src={elements[index + 1].photo} className="rounded mx-auto d-block h-250px w-250px"/>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                        <br></br>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                            {
                                printCoalList.length > 0 && index != printCoalList.length - 1 &&
                                (
                                    <div className="pagebreak-after"></div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}