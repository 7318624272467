import React, { useContext, useState, useEffect } from 'react';

import RelatoriosCarvao from './relatorios-pdf-carvao.jsx';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { AppSettings } from '../../config/app-settings.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import coalService from '../../services/CoalService/coal-service.js';
import fieldService from '../../services/CoalService/field-service.js';
import relatoriosRTF from './relatorios-rtf-carvao.js';

const MAX_QNTD_CARVAO_REALTORIO = 201;

export default function Resultados(){
    const location = useLocation();
    const history = useHistory();
    const { user, ...settings } = useContext(AppSettings);
    const { t, i18n } = useTranslation();

    const [ coalList, setCoalList ] = useState(null);
    const [ filteredList, setFilteredList ] = useState(null);
	const [ currentPage, setCurrentPage ] = useState(1);
    const [ fieldList, setFieldList ] = useState(null);
	const [ lastPage, setLastPage ] = useState(1);
    const [ length, setLength ] = useState(0);
    const [ selectedCoal, setSelectedCoal ] = useState(null);
    const [ selectedCoalList, setSelectedCoalList ] = useState([]);
    const [ printCoalList, setPrintCoalList ] = useState([]);
    const [ currentPageId, setCurrentPageId ] = useState(null);
    const [ search, setSearch ] = useState(null);
    const [ value, setValue ] = useState(1);
    const [ coalReportModal, setCoalReportModal ] = useState(false);
    const [ searchReportModal, setSearchReportModal ] = useState(false);
    const [ coalDeleteModal, setCoalDeleteModal ] = useState(false);
    const [ imageModal, setImageModal ] = useState([false, null]);
    const [ historyModal, setHistoryModal ] = useState([false, null]);
    const [ historyList, setHistoryList ] = useState(null);
    
	const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const handleToggle = () => { setDropdownOpen(!dropdownOpen); }

    const swapName = e => {return Object.assign({}, e, {name: e["name_" + i18n.language]});}

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;

        if(location.from){
            if(location.state.fields){
                setFieldList(location.state.fields);
            }else{
                fieldService.getAllFields().then(
                    response => {        
                        setFieldList(response.data);
                    }
                ).catch(
                    err => {
                        console.log(err);
                    }
                )
            }

            if(location.state.search) setSearch(location.state.search);

            setCoalList(location.state.coals);

            setLength(location.state.coals.length);
            if (location.state.coals.length <= 20){
                setFilteredList(location.state.coals);
            }else{
                setFilteredList(location.state.coals.slice(0, 20));
                setLastPage(Math.ceil(location.state.coals.length/20));
            }

            if(selectedCoal) getCoalInfo(selectedCoal.id);
        }else{  //redireciona de volta pra página de pesquisa caso tenha sido acesso direto da url e não a partir de uma pesquisa
            history.push('/pesquisa');
        }

		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	}, [i18n.language])

    useEffect(() => {
        if(coalList){
            if(coalList.length < MAX_QNTD_CARVAO_REALTORIO){
                let coalId = [];
                coalList.map((coal, i) => {
                    coalId.push(coal[0].id);
                })
                getCoalListInfo(coalId, coalList);
            }
            else{
                setSelectedCoalList(false);
            }
        }
        
    }, [coalList])

    const getCoalListInfo = async (coalId, searchListCoals) => {
        const coalSearchInfo = await coalService.getInfoCoalSearch(coalId, coalId.length);
        coalSearchInfo.data.map((coal, id) => {
            coal.espec = getCoalEspecs(coalSearchInfo.data[id], coalSearchInfo.data[id].id, undefined, undefined, searchListCoals);
        })
        setSelectedCoalList(coalSearchInfo.data);
    }

    const getPage = (direction, changingCoal) => {
        let current = currentPage;
		let isMounted = true;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));

                return 0;
            }
            current = current - 1;
        }else if (direction === 1){
            if (current === lastPage){
				settings.handleSetAlert('info', '', t('alerta.paginação.fim'));

                return 0;
            }
            current = 1 + parseInt(current);
        }
        setValue(current);
        
		if(isMounted){
            const newList = coalList.slice( (current-1)*(20), ((current-1)*(20)+20) );       
			setCurrentPage(current);
            setValue(current);
			setFilteredList(newList);
            if(changingCoal == true){
                if(direction === 0){
                    getCoalInfo(newList[19][0].id, newList, current);
                }else{
                    getCoalInfo(newList[0][0].id, newList, current);
                }
            }
		}
    }

    const handlePageInputChange = (e) => {
        setValue(e.target.value.replace(/\+|-/ig, ''));
        setCurrentPage(value);
    }

    const handlePageSubmitChange = (e, changingCoal) =>{
        e.preventDefault();
        let isMounted = true;

        if(value > lastPage){
            settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
            setCurrentPage(lastPage);
            setValue(lastPage);
        }else if(value < 1){
            settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
            setCurrentPage(1);  
            setValue(currentPage);
        }else if(isMounted){
            const newList = coalList.slice( (value-1)*(20), ((value-1)*(20)+20) );       
			setCurrentPage(value);
			setFilteredList(newList);
            if(changingCoal == true){
                if(e !== 0){
                    getCoalInfo(newList[19][0].id, newList, value);
                }else{
                    getCoalInfo(newList[0][0].id, newList, value);
                }
            }
		}
    }

    const handleCoalDelete = () => {
        deleteCoal();
        toggleModal("delete");
    }

    const handleReportType = (type) => {
        if(type === 'coal-pdf'){
            setPrintCoalList([selectedCoal]);
            openReportType(type, "pdf");
        }
        else if(type === 'coal-rtf'){
            toggleModal("coalReport");
            relatoriosRTF.getCoalReport([selectedCoal], fieldList, t, i18n);
            openReportType(type, "rtf");
        }
        else if(type === 'search-pdf'){
            if(selectedCoalList == false){
                toggleModal("searchReport");
                settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('relatório.alerta.limiteDeTamanho', {size: MAX_QNTD_CARVAO_REALTORIO-1}));
            }
            else{
                setPrintCoalList(selectedCoalList);
                openReportType(type, "pdf");
            }
        }
        else if(type === 'search-rtf'){
            if(selectedCoalList == false){
                toggleModal("searchReport");
                settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('relatório.alerta.limiteDeTamanho', {size: MAX_QNTD_CARVAO_REALTORIO-1}));
            }
            else{
                toggleModal("searchReport");
                relatoriosRTF.getCoalReport(selectedCoalList, fieldList, t, i18n);
                openReportType(type, "rtf");
            }
        }
    }

    const openReportType = (type, file) => {
        if(type === "coal-pdf") { toggleModal("coalReport"); }
        else if(type === "search-pdf") { toggleModal("searchReport"); }
        if(file == "pdf"){
            setTimeout(function(){
                window.print();
                settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('relatório.alerta.relatórioFeito'));
            }, 200);
        }
        else if(file == "rtf"){
            settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('relatório.alerta.relatórioFeito'));
        }
    }

    const coalHistory = async () => {
        const response = await coalService.coalHistory(selectedCoal.id);
        const history = response.data;
        setHistoryList(history);
        setHistoryModal([true, historyList]);
    }

    const getCoalEspecs = (data, id, newPageList, newCurrent, searchListCoals) => {
        let especificacoes = [];
        let charIndex = -1;
        var wrongChars;
        var tempList;
        var current;
        if(newPageList) { tempList = newPageList; current = newCurrent; }
        else if(searchListCoals) { tempList = searchListCoals; current = 1; }
        else { tempList = filteredList; current = currentPage; }
        tempList.map((coal, i) => {
            if(id == coal[0].id){
                setCurrentPageId(((current - 1) * 20) + i + 1);
                wrongChars = coal[1];
            }
        })
        fieldList?.forEach(function (tab, i_1) {
            var espec = {
                tab: tab['name_' + i18n.language],
                fields: [],
                id: tab.id
            };
            tab.children?.forEach(function (label, j) {
                if (label.type == 'multi-select') {
                    var f = {
                        name: label['name_' + i18n.language],
                        values: [],
                        wrongValues: [],
                        id: label.id
                    };
                    label.children?.forEach(function (option, k) {
                        if (option.type == 'multi-select-option') {
                            var v = {
                                name: option['name_' + i18n.language],
                                id: option.id
                            };
                            charIndex++;
                            if (data.chars[charIndex] == 1) {
                                f.values.push(v);
                            }
                            if(wrongChars.charAt(charIndex) == 1){
                                f.wrongValues.push(v);
                            }
                            option.children?.forEach(function (mso, l) {
                                if (mso.type == 'mso-child') {
                                    var vc = {
                                        name: mso['name_' + i18n.language],
                                        id: mso.id
                                    };
                                    charIndex++;
                                    if (data.chars[charIndex] == 1) {
                                        f.values.push(vc);
                                    }
                                    if(wrongChars.charAt(charIndex) == 1){
                                        f.wrongValues.push(vc);
                                    }
                                }
                            });
                        }
                    });
                    if (f.values.length > 0 || f.wrongValues.length > 0) {
                        espec.fields.push(f);
                    }
                }
            });

            if (espec.fields.length > 0) {
                especificacoes.push(espec);
            }
        });

        return especificacoes;
    }

    const getCoalInfo = async (id, newPageList, newCurrent) => {
        try {
            const response = await coalService.getInfoCoal(id);

            if(newPageList) { response.data.espec = getCoalEspecs(response.data, id, newPageList, newCurrent); }
            else { response.data.espec = getCoalEspecs(response.data, id); }
            setSelectedCoal(response.data);
            window.scrollTo(0, 0);

        } catch (err) {
            settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
            console.log(err);
        } 
    }

    const nextCoal = (next) => {
        const listaTemp = filteredList;
        if(next == true){   //aqui vai pra frnte, no outro pra tras
            listaTemp.map((coal, index) => {
                if(selectedCoal.id == coal[0].id){    //ao achar o carvao selecionado, ele tem que achar o próximo da lista
                    if(index != 19){
                        if(!listaTemp[index+1]){
                            settings.handleSetAlert('info', '', 'Último registro.');
                        }else{
                            getCoalInfo(listaTemp[index+1][0].id); //chama a função de infos pro prox carvao
                        }
                    }else{  //sendo 19, quer dizer que esse é o último dessa pagina e tem que ir pra próxima
                        getPage(1, true);
                    }
                    return;
                }
            })
        }else{
            listaTemp.map((coal, index) => {
                if(selectedCoal.id == coal[0].id){    //ao achar o carvao selecionado, ele tem que achar o próximo da lista
                    if(index != 0){  
                        getCoalInfo(listaTemp[index-1][0].id); //chama a função de infos pro prox carvao
                    }else{  //sendo 0, quer dizer que esse é o primeiro dessa pagina e tem que ir pra anterior
                        getPage(0, true);
                    }
                    return;
                }
            })
        }
    }
    
    const newSearch = () => {
        history.push({pathname: '/pesquisa'});
    }

    const searchAgain = () => {
        if(search)
        history.push({pathname: '/pesquisa', state: {searchData : search}, from: '/registros/todos'});
        else history.push({pathname: '/pesquisa'});
    }

    const deleteCoal = async () => {
        const response = await coalService.deleteCoal(selectedCoal.id);

        if (response.data == 'Carvão deletado') {    
            const newCoalList = coalList.filter(coal => coal[0].id != selectedCoal.id)
            setCoalList(newCoalList);
            const newList = newCoalList.slice( (currentPage-1)*(20), ((currentPage-1)*(20)+20) );
            setFilteredList(newList);
            setLength(length-1);
            setSelectedCoal(null);
            settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('carvão.result.botão.alerta.carvaoDeletado'));
        }
    }

    const publishCoal = async () => {
        const response = await coalService.publishCoal(selectedCoal.id);

        if (response.data == 'Carvão publicado') {
            setSelectedCoal({...selectedCoal, status: 2});
            const newCoalList = coalList.map(coal => coal[0].id == selectedCoal.id ? [{...(coal[0]), status:2}, coal[1]] : coal)
            setCoalList(newCoalList);
            const newList = newCoalList.slice( (currentPage-1)*(20), ((currentPage-1)*(20)+20) );
            setFilteredList(newList);
            settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('carvão.result.botão.alerta.carvaoPublicado'));
        }
    }

    const unpublishCoal = async () => {
        const response = await coalService.unpublishCoal(selectedCoal.id);

        if (response.data == 'Carvão privado') {   
            setSelectedCoal({...selectedCoal, status: 1}); 
            const newCoalList = coalList.map(coal => coal[0].id == selectedCoal.id ? [{...(coal[0]), status:1}, coal[1]] : coal)
            setCoalList(newCoalList);
            const newList = newCoalList.slice( (currentPage-1)*(20), ((currentPage-1)*(20)+20) );
            setFilteredList(newList);
            settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('carvão.result.botão.alerta.carvaoPrivado'));
        }
    }

    const editCoal = async () => {
        if(selectedCoal){

            const newChars = [null];
            let arrayIndex = 0;

            fieldList.map((tab, i) => {
                newChars.push(null);
                tab.children?.map((label, j) => {
                    newChars.push(null);
                    label.children?.map((option, k) => {
                        if(option.type == 'multi-select-option'){
                            (selectedCoal.chars[arrayIndex] == 1) ? newChars.push(true) : newChars.push(false);
                            arrayIndex++;
                            option.children?.map((mso, l) => {
                                if(mso.type == 'mso-child'){
                                    (selectedCoal.chars[arrayIndex] == 1) ? newChars.push(true) : newChars.push(false);
                                    arrayIndex++;
                                }
                                else{
                                    newChars.push(null);
                                }
                            });
                        }
                        else{
                            newChars.push(null);
                        }
                    });
                });
            });

            selectedCoal.chars = newChars;

            history.push({pathname: '/editar-carvao/' + selectedCoal.id, state: {searchData : selectedCoal}, from: '/pesquisa-resultado'});
        } else{
            history.push({pathname: '/editar-carvao/' + selectedCoal.id});
        } 
    }

    const reportCoal = () => {
        setCoalReportModal(true);
    }

    const reportSearch = () => {
        setSearchReportModal(true);
    }

    const deleteCoalModal = () => {
        setCoalDeleteModal(true);
    }

    const toggleModal = (name) => {
        if(name === 'coalReport') setCoalReportModal(!coalReportModal);
        else if(name === 'searchReport') setSearchReportModal(!searchReportModal);
        else if(name === 'image') setImageModal([false, null]);
        else if(name === 'history') setHistoryModal([false, null]);
        else if(name === 'delete') setCoalDeleteModal(!coalDeleteModal);
	}

    const coalObservationWithoutCheckbox = (i, o, tab, campo, firstElement) => {
        return(
            <div className='row' key={i}>
                {
                    firstElement == o.fields_id &&
                    (
                        <div className='mt-1 row'>
                            <hr className="bg-gray-600 opacity-2" />
                            <div className='col w-50'>
                                <p>{t(tab['name_' + i18n.language])}:</p>
                            </div>
                        </div>
                    )
                }
                <div className='col-4'>
                    <p>{campo['name_' + i18n.language]}</p>
                </div>
                <div className='col'>
                    <p className='ms-1'>{o.text}</p>
                </div>
            </div>
        )
    }

    return(
        <>
            {
                (filteredList && fieldList) &&
                <>
                    <div className="position-fixed h-auto d-flex flex-row justify-content-between page-header-fixed align-items-center screen-content">
                        {
                            search!=null &&
                            <h1 className="page-header ph-no-margin d-flex flex-column fs-18px">{t('carvão.result.todos')}</h1>
                        }
                        <h1 className="page-header ph-no-margin d-flex flex-column"></h1>
                        <div className='d-flex flex-row fs-5 justify-content-end' >                                            
                            {   search!=null &&
                                <div className="d-flex flex-row align-items-center justify-content-center">
                                    <button type="submit" onClick={reportSearch}
                                        className="btn btn-primary d-block w-auto btn-lg h-38px fs-13px me-2 d-flex align-items-center flex-row">
                                            {t('carvão.result.botão.relatorioPesquisa')}
                                    </button>
                                    <button type="submit" onClick={searchAgain}
                                        className="btn btn-success d-block w-auto btn-lg h-38px fs-13px me-2 d-flex align-items-center flex-row">
                                            {t('carvão.result.botão.refazer')}
                                    </button>
                                    <button type="submit" onClick={newSearch}
                                        className="btn btn-success d-block w-auto btn-lg h-38px fs-13px d-flex align-items-center">
                                            {t('carvão.result.botão.pesquisa')}
                                    </button>
                                </div>
                            }
                        </div>  
                    </div>

                    <div className='row screen-content' style={{ marginTop: '55px' }}>
                        <div className='col w-50'>
                            <Panel> 
                                <PanelHeader noButton={true} className="h-40px">
                                    <div className="d-flex flex-row fs-14px justify-content-between"> 
                                         <div className="d-flex flex-row align-items-center justify-content-center">
                                            <div className="col d-flex align-items-center">
                                                <div className="col d-flex align-items-center">
                                                    <div onClick={() => getPage(0)} style={{cursor: "pointer"}}><i className="fas fa-angle-left me-3"></i></div>
                                                        {t('paginação.page')}: 
                                                        <form className="ps-2 pe-1 mx-1" onSubmit={handlePageSubmitChange}>
                                                            <input type="text" className="form-control fs-14px fw-lighter w-50px h-35px l-20px border-0 text-end" value={value} onChange={handlePageInputChange} /> 
                                                        </form>
                                                         / {lastPage}
                                                    <div onClick={() => getPage(1)} style={{cursor: "pointer"} }><i className="fas fa-angle-right ms-3"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="mt-2 me-3">{t('paginação.total')}: {length}</div>                                           
                                       
                                    </div>  
                                </PanelHeader>
                                <PanelBody className="p-0">
                                    {
                                        <div className="table-responsive d-flex flex-row justify-content-between">
                                            <table className="table m-0 fs-13px">
                                                <tbody>
                                                    {   
                                                        filteredList.map((coal, index)=> 
                                                            <tr key={index} className={'d-flex justify-content-start mt-n1' + (selectedCoal? ( selectedCoal.id === coal[0].id ? ' table-primary' : '') : '')} onClick={() => getCoalInfo(coal[0].id)}>
                                                                <td width="5%" style={{paddingLeft: "10px"}}><i className={coal[0].status == 1 ? "fa fa-lock" : 'fa fa-globe'}></i></td>
                                                                <td width="5%" >
                                                                    {
                                                                        coal[0].type == 1 &&
                                                                        'A'
                                                                    }
                                                                    {
                                                                        coal[0].type == 2 &&
                                                                        'R'
                                                                    }
                                                                    {
                                                                        coal[0].type == 3 &&
                                                                        'L'
                                                                    }
                                                                </td>
                                                                <td width="5%"><i className={coal[0].image == 0 ? "far fa-folder" : 'far fa-images'}></i></td>
                                                                <td width="85%">
                                                                    {
                                                                        coal[0].family_name? (coal[0].family_name + (coal[0].scientific_name ? ' > ' + coal[0].scientific_name : (coal[0].genus_name? ' > ' + coal[0].genus_name : '')) + (coal[0].determination? ' > ' + coal[0].determination : ''))
                                                                                        : (coal[0].determination? coal[0].determination : t('carvão.result.nãoInformado'))
                                                                    }
                                                                </td>
                                                            </tr> 
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </PanelBody>
                            </Panel>
                        </div>

                        <div className='col w-50'>
                            <Panel> 
                                <PanelHeader className='p-2 h-40px'>
                                    { selectedCoal != null &&
                                        <div className=" d-flex flex-row justify-content-between">                                            
                                            <div className='d-flex flex-row fs-14px' >                                          
                                                <div className="d-flex flex-row align-items-center justify-content-center">
                                                    <div onClick={() => nextCoal(false)} style={{cursor: "pointer"}}><i className="fas fa-angle-left me-3"></i></div>
                                                        <div>{t('carvão.result.registro')}: {currentPageId} / {length}</div>
                                                    <div onClick={() => nextCoal(true)} style={{cursor: "pointer"}}><i className="fas fa-angle-right ms-3 me-3"></i></div>
                                                </div>
                                            </div>
                                            
                                            <Dropdown isOpen={dropdownOpen} toggle={handleToggle} className="navbar-item navbar-user dropdown me-3 align-self-end" tag="div">
                                                <DropdownToggle  className="navbar-link dropdown-toggle d-flex align-items-center" >
                                                    <span style={{ cursor:"pointer"}}>
                                                        <span> {t('carvão.result.ação')} </span>
                                                    
                                                        <b className="caret text-white-100"/>
                                                    </span>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-end" tag="div">
                                                    {
                                                        (user && (user.is_admin == 1 || user.is_admin == 2)) &&
                                                        <>
                                                            <DropdownItem onClick={reportCoal}>{t('carvão.result.botão.relatorio')}</DropdownItem>
                                                            {selectedCoal.status === 1 && 
                                                                <DropdownItem onClick={publishCoal}>{t('carvão.result.botão.publicar')}</DropdownItem>}
                                                            {selectedCoal.status === 2 && 
                                                                <DropdownItem onClick={unpublishCoal}>{t('carvão.result.botão.privar')}</DropdownItem>}
                                                            <DropdownItem onClick={coalHistory}>{t('carvão.result.botão.historico')}</DropdownItem>
                                                            
                                                            <div className="dropdown-divider"></div>

                                                            <DropdownItem onClick={editCoal}>{t('carvão.result.botão.editar')}</DropdownItem>
                                                            <DropdownItem onClick={deleteCoalModal}>{t('carvão.result.botão.excluir')}</DropdownItem>
                                                        </>
                                                    }
                                                    {
                                                        (user && user.is_admin == 0 && user.name == selectedCoal.user.name) &&
                                                        <>
                                                            {selectedCoal.status === 1 && 
                                                                <DropdownItem onClick={publishCoal}>{t('carvão.result.botão.publicar')}</DropdownItem>}
                                                            {selectedCoal.status === 2 && 
                                                                <DropdownItem onClick={unpublishCoal}>{t('carvão.result.botão.privar')}</DropdownItem>}

                                                            <div className="dropdown-divider"></div>

                                                            <DropdownItem onClick={editCoal}>{t('carvão.result.botão.editar')}</DropdownItem>
                                                            <DropdownItem onClick={deleteCoalModal}>{t('carvão.result.botão.excluir')}</DropdownItem>
                                                        </>
                                                    }
                                                    {
                                                        (!user || (user && user.is_admin == 0 && !(user.name == selectedCoal.user.name))) &&                                                            
                                                            <DropdownItem disabled>{t('carvão.result.botão.relatorio')}</DropdownItem>
                                                    }
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    }
                                </PanelHeader>
                                <PanelBody className="fs-13px">
                                    {
                                        selectedCoal === null &&
                                        (<p>
                                            {t('carvão.result.informações.selecioneParaSaberMais')}
                                        </p>)
                                    }
                                    {
                                        selectedCoal &&
                                        (<div>
                                            <div className='row'>
                                                <div className='row'>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('glossario.taxonomias.familia')}: {selectedCoal.family ? (selectedCoal.family.name) : ''} </p>
                                                    </div>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('carvão.subFamilia')}: {selectedCoal.sub_fam ? selectedCoal.sub_fam : ''} </p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('glossario.taxonomias.genero')}: {selectedCoal.genus ? selectedCoal.genus.name : ''} </p>
                                                    </div>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('glossario.taxonomias.especie')}: {selectedCoal.species ? selectedCoal.species.name : ''} </p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('carvão.determinação')}: {selectedCoal.determination ? selectedCoal.determination : ''} </p>
                                                    </div>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('carvão.procedência')}: {selectedCoal.origin ? selectedCoal.origin : ''} </p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('carvão.sítio')}: {selectedCoal.site ? selectedCoal.site.name : ''} </p>
                                                    </div>
                                                    <div className='col-2 w-50'>
                                                        <p>{t('carvão.código')}: {selectedCoal.code ? selectedCoal.code : ''} </p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col w-50'>
                                                        <p>{t('carvão.localização')}: {selectedCoal.locale ? selectedCoal.locale : ''} </p>
                                                    </div>
                                                    <div className='col w-50'>
                                                        {
                                                            selectedCoal.type == 1 &&
                                                            <p>{t('carvão.categoria')}: {t('carvão.dropdown.categoria.arqueologicos')}</p>
                                                        }
                                                        {
                                                            selectedCoal.type == 2 &&
                                                            <p>{t('carvão.categoria')}: {t('carvão.dropdown.categoria.lenhosReferencia')}</p>
                                                        }
                                                        {
                                                            selectedCoal.type == 3 &&
                                                            <p>{t('carvão.categoria')}: {t('carvão.dropdown.categoria.literatura')}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col w-50'>
                                                        <p>{t('carvão.fichaNum')}: {selectedCoal.id} </p>
                                                    </div>
                                                    <div className='col w-50'>
                                                        <p>{t('carvão.autor')}: {selectedCoal.user.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                fieldList.map((tab, id, tabList) => {
                                                    let firstElement = 0;
                                                    let aba = selectedCoal.espec.find(element => element.id == tab.id);
                                                    if(aba){
                                                        return(
                                                            <div key={id}>
                                                                <hr className="bg-gray-600 opacity-2" />
                                                                <div className='mt-1 row'>
                                                                    <div className='col w-50'>
                                                                        <p>{t(aba.tab)}:</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    aba.fields.map((f, ind, elements) => {
                                                                        return(
                                                                            <div key={ind} className='row'>
                                                                                { // Observacao com checkbox (antes das checkboxes)
                                                                                    selectedCoal.observations.length > 0 &&
                                                                                    (
                                                                                        selectedCoal.observations.map((o, i) => {
                                                                                            if(o.fields_id == f.id - 1 && o.fields_id == tab.id + 1){

                                                                                                let campo = fieldList[id].children.find(element2 => element2.id == o.fields_id);

                                                                                                if(campo && o.text != ''){
                                                                                                    return(
                                                                                                        <div className='row' key={i}>
                                                                                                            <div className='col-4'>
                                                                                                                <p>{campo['name_' + i18n.language]}</p>
                                                                                                            </div>
                                                                                                            <div className='col'>
                                                                                                                <p className='ms-2 ps-1'>{o.text}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    )
                                                                                }
                                                                                <div className='col-4'>
                                                                                    <p>{t(f.name)}</p>
                                                                                </div>
                                                                                <div className='col'>
                                                                                    {
                                                                                        f.values.length > 0 && (
                                                                                            f.values.map((v, index)=>{
                                                                                                return(
                                                                                                    <p key={index} className='m-1'>{v.name}</p>
                                                                                                )
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        f.wrongValues.length > 0 && (
                                                                                            f.wrongValues.map((va, indexx)=>{
                                                                                                return(
                                                                                                    <p key={indexx} className='m-1'><del>{va.name}</del></p>
                                                                                                )
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                { // Observacao com checkbox (depois das checkboxes)
                                                                                    selectedCoal.observations.length > 0 &&
                                                                                    (
                                                                                        selectedCoal.observations.map((o, i) => {
                                                                                            if((elements[ind + 1] && o.fields_id > f.id && o.fields_id < elements[ind + 1].id) ||
                                                                                                (ind == elements.length - 1 && o.fields_id > f.id && o.fields_id < tabList[id + 1].id)){

                                                                                                let campo = fieldList[id].children.find(element2 => element2.id == o.fields_id);

                                                                                                if(!campo){ 
                                                                                                    campo = fieldList[id].children.find(element => element.id == f.id)
                                                                                                        .children.find(element2 => element2.id == o.fields_id)
                                                                                                }

                                                                                                if(!campo){
                                                                                                    f.values.map((v, index) => {
                                                                                                        let auxCampo = fieldList[id].children.find(element => element.id == f.id)
                                                                                                                .children.find(element2 => element2.id == v.id);
                                                                                                        if(auxCampo && auxCampo.children){
                                                                                                            campo = auxCampo.children.find(element3 => element3.id == o.fields_id);
                                                                                                        }
                                                                                                    })
                                                                                                }

                                                                                                if(campo && o.text != ''){
                                                                                                    return(
                                                                                                        <div className='row' key={i}>
                                                                                                            <div className='col-4'>
                                                                                                                <p>{campo['name_' + i18n.language]}</p>
                                                                                                            </div>
                                                                                                            <div className='col'>
                                                                                                                <p className='ms-2 ps-1'>{o.text}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else{
                                                        return(
                                                            <div key={id}>
                                                                { // Observacao sem checkbox
                                                                    selectedCoal.observations.length > 0 &&
                                                                    (
                                                                        selectedCoal.observations.map((o, i) => {
                                                                            let campo = tab.children.find(element => element.id == o.fields_id);

                                                                            if(campo){
                                                                                if(o.text != ''){
                                                                                    if(firstElement == 0) { firstElement = o.fields_id }
                                                                                    return coalObservationWithoutCheckbox(i, o, tab, campo, firstElement)
                                                                                }
                                                                            } else {
                                                                                return tab.children.map((element, j) => {
                                                                                    if(element.children){
                                                                                        campo = element.children.find(element2 => element2.id == o.fields_id);
                                                                                        if(campo){
                                                                                            if(o.text != ''){
                                                                                                if(firstElement == 0) { firstElement = o.fields_id }
                                                                                                return coalObservationWithoutCheckbox(i, o, tab, campo, firstElement)
                                                                                            }
                                                                                        } else {
                                                                                           return element.children.map((element2, k) => {
                                                                                                if(element2.children){
                                                                                                    campo = element2.children.find(element3 => element3.id == o.fields_id);
                                                                                                    if(campo){
                                                                                                        if(o.text != ''){
                                                                                                            if(firstElement == 0) { firstElement = o.fields_id }
                                                                                                            return coalObservationWithoutCheckbox(i, o, tab, campo, firstElement)
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                });
                                                                            }
                                                                        })
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                selectedCoal.images.length > 0 &&
                                                (
                                                    <div>
                                                        <hr className="bg-gray-600 opacity-2" />
                                                        <div className='row'>
                                                            <div className='col w-50'>
                                                                <p><b>{t('carvão.result.camposImagem')}:</b></p>
                                                            </div>
                                                        </div>
                                                        {
                                                            selectedCoal.images.map((image, index)=>{
                                                                return(
                                                                    <div className='row' key={index}>
                                                                        <div className='col w-50'>
                                                                            <img src={image.photo} className="rounded mx-auto d-block h-250px w-250px" style={{cursor: "pointer"}} onClick={() => {
                                                                                setImageModal([true, image.photo]);
                                                                            }}/>
                                                                            <br></br>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>)
                                    }
                                </PanelBody>
                            </Panel>
                        </div>

                        <Modal isOpen={searchReportModal} toggle={() => toggleModal('searchReport')} className="screen-content">
                            <ModalHeader 
                                toggle={() => toggleModal('searchReport')} 
                                close={
                                    <button className="btn-close" onClick={() => toggleModal('searchReport')}></button>
                                }>
                                    <i className="fa fa-circle-exclamation"></i> {t('carvão.result.botão.relatorioPesquisa')}
                            </ModalHeader>    

                            <ModalBody>
                                <div className="alert mb-0 fs-5">
                                    <p>{t('relatório.modal.texto')}</p>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <button className="btn btn-white fs-11px" onClick={ () => toggleModal("searchReport") }>
                                    {t('botão.cancelar')}</button>
                                <button className="btn btn-primary fs-11px" type="submit" onClick={ () => (handleReportType("search-pdf"))} disabled={selectedCoalList.length == 0}>
                                    {t('relatório.botão.pdf-pesquisa')}</button>
                                <button className="btn btn-success fs-11px" type="submit" onClick={ () => (handleReportType("search-rtf"))} disabled={selectedCoalList.length == 0}>
                                    {t('relatório.botão.rtf-pesquisa')}</button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={coalReportModal} toggle={() => toggleModal('coalReport')} className="screen-content">
                            <ModalHeader 
                                toggle={() => toggleModal('coalReport')} 
                                close={
                                    <button className="btn-close" onClick={() => toggleModal('coalReport')}></button>
                                }>
                                    <i className="fa fa-circle-exclamation"></i> {t('carvão.result.botão.relatorio')}
                            </ModalHeader>    

                            <ModalBody>
                                <div className="alert mb-0 fs-5">
                                    <p>{t('relatório.modal.texto')}</p>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <button className="btn btn-white fs-11px" onClick={ () => toggleModal("coalReport") }>
                                    {t('botão.cancelar')}</button>
                                <button className="btn btn-primary fs-11px" type="submit" onClick={ () => (handleReportType("coal-pdf"))}>
                                    {t('relatório.botão.pdf')}</button>
                                <button className="btn btn-success fs-11px" type="submit" onClick={ () => (handleReportType("coal-rtf"))}>
                                    {t('relatório.botão.rtf')}</button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={coalDeleteModal} toggle={() => toggleModal('delete')}>
                            <ModalHeader toggle={() => toggleModal('delete')} close={<button className="btn-close" onClick={() => toggleModal('delete')}></button>}><i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}</ModalHeader>
                                <ModalBody>
                                    <div className="alert alert-danger mb-0 fs-5">
                                        <p>{t('carvão.result.modal.remover.texto1')}</p>
                                        <p>{t('carvão.result.modal.remover.texto2')}</p>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button className="btn btn-white" onClick={() => toggleModal('delete')}>{t('botão.cancelar')}</button>
                                    <button className="btn btn-danger" onClick={() => handleCoalDelete()} type="submit" >
                                    {t('gerencia.botão.removerComCerteza')}</button>
                                </ModalFooter>
					    </Modal>
                        
                        <Modal size='xl' isOpen={imageModal[0]} toggle={() => toggleModal('image')}>
                            <ModalHeader 
                                toggle={() => toggleModal('image')} 
                                close={
                                    <button className="btn-close" onClick={() => toggleModal('image')}></button>
                                }>
                                    <i className="fa-regular fa-image" aria-hidden="true"></i> {t('imagens.modal.imagem')}
                            </ModalHeader>  

                            <ModalBody>
                                <img src={imageModal[1]} className="container-sm"></img>
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={historyModal[0]} toggle={() => toggleModal('history')}>
                            <ModalHeader
                                toggle={() => toggleModal('history')}
                                close={
                                    <button className="btn-close" onClick={() => toggleModal('history')}></button>
                                }>
                            </ModalHeader>
                            <ModalBody>
                                <div className="row">
                                    <div className="col">
                                        {
                                            historyList?.map(value => {
                                                let typeString;
                                                switch (value.tipo) {
                                                    case 1:
                                                        typeString = t('carvão.result.historico.criar');
                                                        break;
                                                    case 2:
                                                        typeString = t('carvão.result.historico.editar');
                                                        break;
                                                    case 3:
                                                        typeString = t('carvão.result.historico.publicar');
                                                        break;
                                                    case 4:
                                                        typeString = t('carvão.result.historico.privar');
                                                        break;
                                                    case 5:
                                                        typeString = t('carvão.result.historico.inativado');
                                                        break;
                                                    case 6:
                                                        typeString = t('carvão.result.historico.relatorio');
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                return (
                                                    <div className="row ">
                                                        <div className="col w-50">
                                                            <p>{t('carvão.result.historico.texto1')} <b>{typeString}</b> {t('carvão.result.historico.em')} <b>{value.data}</b> {t('carvão.result.historico.por')} <b>{value.name}</b>.</p>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>

                    { 
                        coalList &&
                            <RelatoriosCarvao printCoalList={printCoalList} fieldList={fieldList}></RelatoriosCarvao>
                    }
                    
                </>
            }
        </>
    )
}