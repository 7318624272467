import DropdownLanguage from '../../components/header/dropdown/language';

export function LoginHeader({title, subtitle=null}) {
return(
        <div className="login-header mb-30px">
            <div className="brand">
                <div className="d-flex align-items-center">
                    <img src='/assets/img/logo/LogoAnthrakosFinal.svg'  width="40" height="auto " style={{  borderRadius: "25%", marginRight: "10px" }} />					
                    <b>{title}</b>
                </div>
                {subtitle && (
                    <small>{subtitle}</small>)}
            </div>
            <DropdownLanguage />
        </div>)
}