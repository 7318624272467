import React, { useContext } from 'react';


import { NewsFeedLogin } from '../../components/content/newsFeedLogin.jsx';
import { AppSettings } from '../../config/app-settings.js';

import Sobre from './sobre.jsx';


export default function Inicial () {

	const { user } = useContext(AppSettings);

	return (
		<>
			{ user === null &&
				<div className="fa-3x carregando-pagina">
					<i className="fas fa-spinner fa-pulse"></i>
				</div>
			}
			{ user === undefined &&
				<NewsFeedLogin bgImg='url(/assets/img/login-bg/bk.jpg)'/>
			}
			{ user && 
				<NewsFeedLogin bgImg='url(/assets/img/login-bg/bk.jpg)'/>
			}
		</>
	)	
	
}