import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';

import { useTranslation, Trans } from 'react-i18next';

export default function SemPermissaoPanel () {
	const { t } = useTranslation();

	return(
		<Panel>
			<PanelHeader>{t('erroPag.titulo')}</PanelHeader>
			<PanelBody >
				<div className="nao-logado">
					<Trans i18nKey="erroPag.texto.semPermissao">
						<p>Você precisa das permissões necessárias para acessar essa página.</p>
						<p>Verifique com um administrador do sistema e tente novamente.</p>
					</Trans>
				</div>
			</PanelBody>
		</Panel>
	)
}