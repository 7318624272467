import { useTranslation, Trans } from 'react-i18next';

export function NewsFeedLogin({bgImg}) {
    const {t} = useTranslation();

    return (
        <div className="news-feed">
            <div className="news-image" style={{ backgroundImage: bgImg }}></div>
            <div className="news-caption">
                <h4 className="caption-title">
                    <Trans i18nKey="titulo">
                        Sistema Anthrakos
                    </Trans>
                </h4>
                <p>
                    {t('subtitulo')}
                </p>
            </div>
        </div>
    )
}