import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import userService from '../../../services/UserService/user-service.js';
import { useHistory } from 'react-router';
import { AppSettings } from '../../../config/app-settings';

import { useTranslation } from 'react-i18next';

export default function DropdownProfile (props) {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { user, ...settings } = useContext(AppSettings);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	
	function logout(){
		userService.logout()
			.then( () => {
				settings.handleSetAlert('info', t('alerta.titulo.sucesso'), t('menuUser.alerta.saidaBemSucedida.mensagem'));
				settings.setUser(undefined);
				push('/');
			})
			.catch( (error) => {
				console.log(error);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
			});
	}

	const handleToggle = () => { setDropdownOpen(!dropdownOpen); }

	return (
		<Dropdown isOpen={dropdownOpen} toggle={handleToggle} className="navbar-item navbar-user dropdown" tag="div">
			{ !user &&
				<>
					<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
						<span style={{ cursor:"pointer"}}>
							<i className="fas fa-key fa-fw"></i> {t('menuUser.naoLogado.title')}
							<b className="caret"></b>
						</span>
					</DropdownToggle>
					
					<DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
						<DropdownItem onClick={() => push('/acesso')}>{t('menuUser.naoLogado.acesso')}</DropdownItem>
						<DropdownItem onClick={() => push('/cadastro')}>{t('menuUser.naoLogado.cadastro')}</DropdownItem>
					</DropdownMenu>
				</>
			}{ user &&
				<>
					<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
						<span>
							<span style={{ cursor:"pointer"}}>
							<i className="fas fa-user fa-fw"></i>
							{' '} {user.username} {' '}
							<b className="caret"></b>	
							</span> 
							
						</span>
					</DropdownToggle>
					
					<DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
						<DropdownItem onClick={() => push('/meus-dados')}>{t('menuUser.logado.meusDados')}</DropdownItem>
						<DropdownItem onClick={() => push('/registros/meus')}>{t('menuUser.logado.meusRegistros')}</DropdownItem>
						<div className="dropdown-divider"></div>
						<DropdownItem onClick={logout} >{t('menuUser.logado.sair')}</DropdownItem>
					</DropdownMenu>
				</>
			}
		</Dropdown>
	);
}

