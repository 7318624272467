import React from 'react';

import {ReactNotifications} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';

class AlertPopup extends React.Component {

    constructor(props){
        super(props);
        this.addNotification = this.addNotification.bind(this);
        this.teste = this.teste.bind(this)
    }

    teste(color, title, text){
        this.addNotification(color, title, text)
    }

    addNotification(notificationType, notificationTitle, notificationMessage) {
		
		Store.addNotification({
			title: notificationTitle,
			message: notificationMessage,
			type: notificationType,
			insert: "bottom",
			container: "bottom-center",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: { duration: 3500, onScreen: false, showIcon: true },
			dismissable: { click: true }
		});
	}

    render() {
        return(
            <ReactNotifications className="position-absolute bottom-0 mb-5"/>
        )
    }
}

export default AlertPopup;