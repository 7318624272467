import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import { NewsFeedLogin } from '../../components/content/newsFeedLogin';

import userService from '../../services/UserService/user-service.js';
import { LoginHeader } from '../../components/header/loginHeader.jsx';

import { useTranslation, Trans } from 'react-i18next';

const MAX_STEPS = 3;

export default function Register(props) {
	const { push } = useHistory();
	const settings = useContext(AppSettings);
	const { t } = useTranslation();
	const [ countriesList, setCountriesList ] = useState([]);
	const [ titlesList, setTitlesList ] = useState([]);
	const [ formStep, setFormStep ] = useState(0);

	const { register, handleSubmit, reset, formState: { errors, isSubmitting, isValid }, watch } = useForm({ mode: "onTouched" });

	const registerOptions = {
		name: { required: t('registerOptions.obrigatorio') },
		username: { 
			required: t('registerOptions.obrigatorio'),
			minLength: {
				value: 4,
				message: t('registerOptions.minDigito', {x: 4})
			}
		},
		email: { 
			required: t('registerOptions.obrigatorio'),
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: t('registerOptions.nãoÉVálido')
			}
		},
		password: {
		  required: t('registerOptions.obrigatorio'),
		  minLength: {
			value: 8,
			message: t('registerOptions.minDigito', {x: 8})
		  },
		},
		confirmPwd: {
			validate: (val) => {
				if (watch('password') != val) {
				  return t('registerOptions.naoConfere');
				}
			  }
		}
	  };

	useEffect(() => {
		// acontece quando o componente é montado
		let isMounted = true;
		settings.handleSetAppSidebarNone(true);
		settings.handleSetAppHeaderNone(true);
		settings.handleSetAppContentClass('p-0');
		userService.listCountries().then( (response) =>{
			if (isMounted) setCountriesList( response.data );
		});
		userService.listTitles().then( (response) =>{
			if (isMounted) setTitlesList( response.data );
		});

		//acontece quando o componente é desmontado
		return () => {
			settings.handleSetAppSidebarNone(false);
			settings.handleSetAppHeaderNone(false);
			settings.handleSetAppContentClass('');
		}
	}, [])

	const normalizeData = (data) =>
		Object.fromEntries(
			Object.entries(data).map(([k, v]) => { 
				if ((k === 'country_id' || k==='title_id') && v === -1) return [k, null];
				return [k,v]; 
			}).filter(([k]) => k !== 'confirmPwd')
		)

	const handleFormSubmit = (data) => { 
 		return userService.register (
			normalizeData(data)
		).then( () => {
			settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('user.cadastro.alerta.sucessoRegistro'));
			push('/');
		}).catch( (err) => {
			reset({
				password: '',
				confirmPwd: '',
			})
			if(err.request.status === 400){
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('user.cadastro.alerta.faltaInfo'));
			}else{
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
			}
		}); 
	}

	const completeFormStep = (data) => {
		if(formStep<2) return setFormStep(current => current + 1);
		else return handleFormSubmit(data);
	}

	const handleFormBack = () => {
		if(formStep>0) setFormStep(current => current - 1);
	}

	return (
		<div className="register register-with-news-feed">
			<div className="register-container">
				<LoginHeader title={t('user.cadastro.titulo')} />

				<div className="register-content">
					<form onSubmit={handleSubmit(completeFormStep)} className="fs-13px">
						<div><p>
							{formStep > 0 && 
								<>
									<a href="#" onClick={handleFormBack} className="link-info">
										&le; {t('user.cadastro.botão.voltar')}
									</a>{' '} 
								</>
							}
							{t('user.cadastro.passos', { atual: formStep+1, total:  MAX_STEPS })}
						</p></div>
						{formStep === 0 && 
							<section>
								<div className="form-floating mb-15px">
									<input name="name" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder="Nome" id="nome" 
										{...register('name', registerOptions.name)} />
									<label className="d-flex align-items-center fs-14px text-gray-600">
										{t('userInfo.nome')}
										<span className="text-danger">* {errors?.name && errors.name.message}</span>
									</label>
								</div>
								<div className="form-floating mb-15px">
									<input name="username" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder="Login" id="username" 
										{...register('username', registerOptions.username)} />
									<label className="d-flex align-items-center fs-14px text-gray-600">
										{t('userInfo.apelido')}
										<span className="text-danger">* {errors?.username && errors.username.message}</span>
									</label>
								</div>
								<div className="form-floating mb-15px">
									<input name="email" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder="Email" id="email" 
										{...register('email', registerOptions.email)} />
									<label className="d-flex align-items-center fs-14px text-gray-600">
										{t('userInfo.email')}
										<span className="text-danger">* {errors?.email && errors.email.message}</span>
									</label>
								</div>
								<div className="form-floating mb-15px">
									<input name="password"	type="password" className="form-control h-35px fs-16px fw-lighter" placeholder="Senha" id="senha" 
										{...register('password', registerOptions.password)} />
									<label className="d-flex align-items-center fs-14px text-gray-600">
										{t('userInfo.senha')} 
										<span className="text-danger">*  {errors?.password && errors.password.message}</span>
									</label>
								</div>
								<div className="form-floating mb-15px">
									<input name="confirmPwd" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder="Confirme a senha" id="confirma-senha" 
										{...register('confirmPwd', registerOptions.confirmPwd)} />
									<label className="d-flex align-items-center fs-14px text-gray-600">
										{t('userInfo.confirmeSenha')}
										<span className="text-danger">*  {errors?.confirmPwd && errors.confirmPwd.message}</span>
										</label>
								</div>
							</section>
						}
						
						{formStep === 1 && 
							<section>
								<div className="form-floating mb-15px">
									<input
										name="institution" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder="Instituição" id="instituicao" 
										{...register('institution')}>
										
									</input>
								
									<label className="d-flex align-items-center fs-14px text-gray-600">{t('userInfo.instituição')}<span className="text-danger">*</span></label>
								</div>
								<div className="form mb-15px">
									<select defaultValue={-1} className="form-select h-35px fs-14px fw-lighter" {...register('country_id')}>
										
										<option disabled value={-1}>{t('dropDown.país')}</option>
								
										{countriesList.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
									</select>
								</div>
								<div className="form mb-15px">
									<select defaultValue={-1} className="form-select h-35px fs-14px fw-lighter" {...register('title_id')}>
										<option disabled value={-1}>{t('dropDown.título')}</option>
										{titlesList.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
									</select>
								</div>
							</section>
						}
						

						{formStep === 2 && 
							<section>
								<div className="form-floating mb-15px">
									<textarea 
										type="text" name="academic" className="form-control fs-16px h-100px fw-lighter" 
										placeholder="Descreva resumidamente sua formação acadêmica." 
										{...register('academic')}
									/>
									<label  className="d-flex fs-14px text-gray-600">{t('userInfo.formaçãoAcadêmica')}<span className="text-danger">*</span></label>
								</div>
								<div className="form-floating mb-15px">
									<textarea 
										type="text" name="interest" className="form-control fs-14px h-100px fw-lighter" 
										placeholder="Apresente suas áreas de interesse em Arqueologia e Arqueobotânica." 
										{...register('interest')}
									/>
									<label htmlFor='interest' className="d-flex fs-14px text-gray-600">{t('userInfo.areasDeInteresse')}<span className="text-danger">*</span></label>
								</div>

							</section>
						}
						<div className="mb-4">
							<button type="submit" 
									disabled={isSubmitting || !isValid}
									className="btn btn-primary d-block w-100 btn-lg h-35px fs-13px" >
								{isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
								{formStep < 2 &&
									t('user.cadastro.botão.continuar')}
								{formStep === 2 &&
									t('user.cadastro.botão.finalizar')}
							</button>
						</div>
						
					</form>


					<div className="mb-4 pb-5" >
						<Trans i18nKey='user.link.acesso'>		
							Já possui uma conta? Clique <Link to="/acesso" className="text-info">aqui</Link> para acessar o sistema.
						</Trans>
							<br></br>
						<Trans i18nKey='user.link.voltarInicio'>	
							Para Voltar a página inicial como convidade clique <Link to="/" className="text-info">aqui</Link>.
						</Trans>
					</div>
				</div>
			</div>
			
			<NewsFeedLogin bgImg='url(/assets/img/login-bg/login-2.jpeg)' />
		</div>	
	)
}