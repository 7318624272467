import instance from '../../axios-instance';
import Cookies from 'js-cookie'

export default{

    async getAllSites(){
        try{
            const response = await instance.get('allSites');
            return response;
        }catch (err){
            throw err;
        }
    },

    async registerCoal(form){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.post('/registroCoal', form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });

            return response;
        }catch (err){
            throw err;
        }
    },
    
    async searchCoal(form){
        try{
            const token = Cookies.get('anth-token');
            const response = await instance.post('/searchCoal', form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },

    async updateCoal(form, id){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.post('/updatecoal/' + id, form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },

    async listAllCoal(){
        try{
            const response = await instance.get('/listAllCoal');
            return response;
        }catch (err){
            throw err;
        }
    },

    async getInfoCoal(id){
        try{
            const response = await instance.get('info/' + id);
            return response;
        }catch (err){
            throw err;
        }
    },

    async getInfoCoalSearch(list, length){
        try{
            const response = await instance.post('/infoSearch/' + length, list);
            return response;
        }catch (err){
            throw err;
        }
    },

    async coalHistory(id){
        try{
            const response = await instance.get('coalHistory/' + id);
            return response;
        }catch (err){
            throw err;
        }
    },

    async publishCoal(id){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.delete('/publish/' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },

    async unpublishCoal(id){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.delete('/unpublish/' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },

    async deleteCoal(id){
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.delete('/delete/' + id, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },
    
    async filterCoalByFamily(form){
        try{
            const token = Cookies.get('anth-token');
            const response = await instance.post('/coal/families/', form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },
    async filterCoalByGenus(form){
        try{
            const token = Cookies.get('anth-token');
            const response = await instance.post('coal/genera', form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    },

    
    async filterCoalByType(form){
        try{
            const token = Cookies.get('anth-token');
            const response = await instance.post('/coals/type', form,
            {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch (err){
            throw err;
        }
    }
}