import React, { useContext, useState, useEffect } from 'react';

import SemPermissaoPanel from '../../components/sem-permissao-panel/sem-permissao-panel.jsx';
import FichaCarvao from './ficha-carvao.jsx';
import { useForm } from 'react-hook-form';
import coalService from '../../services/CoalService/coal-service.js';
import adminService from '../../services/AdminService/admin-service.js';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { AppSettings } from '../../config/app-settings.js';

import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from 'react-router-dom';

export default function RegistroCarvao(props){
    const location = useLocation();
    const history = useHistory();
    const { user, ...settings } = useContext(AppSettings);
    const { t } = useTranslation();

    const { register, handleSubmit, watch, setValue, reset, formState: { errors, isSubmitting, isValid }, getValues } = useForm({ mode: "onBlur" });

    const { idToken } = useParams();

    //const watchFamily = watch("family_id"); // you can supply default value as second argument

    const [ listedSites, setListedSites] = useState(null);
    const [ listedFams, setListedFams] = useState(null);
    const [ listedGens, setListedGens] = useState(null);
    const [ listedEsp, setListedEsp] = useState(null);
    const [ listedFields, setListedFields ] = useState(null);
    const [ coalImages, setCoalImages ] = useState(null);
    const [ genSearch, setGenSearch ] = useState(false);
    const [ resetTabs, setResetTabs ] = useState(false);

    const [ savedCoal, setSavedCoal ] = useState(null); //para manter o carvão e ser possivel ficar salvando ele aos poucos
    const [ newModal, setNewModal ] = useState(false);
    const [ imageModal, setImageModal ] = useState([false, null]);

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, {name}) => {
            if(name == "family_id"){
                if(value.family_id == 0){ //Caso esteja selecionando nenhuma família agora, recarrega todos os gêneros e famílias e limpa as espécies
                    setListedGens(null);
                    register('species_id', { value: 0 });
                    setValue('species_id', 0);
                    adminService.getGenus().then(
                        response => {
                            setListedGens(response.data)
                        }
                    )
                    setListedEsp(null);
                } else {  
                    if(value.genus_id == 0){//No caso de escolher primeiro o dropdown de família
                        setListedGens(null);
                        adminService.listSpeciesByFamily(value.family_id).then(
                            response => {
                                setListedEsp(response.data);
                            }
                        )
                        adminService.listGenusByFamily(value.family_id).then(
                            response => {
                                setListedGens(response.data);
                            }
                        )
                    }else {//ou de a familia recarregar ao escolher o genero
                        adminService.getFamilyByGenusId(value.genus_id).then(
                            response => {
                                if(response.data[0].id != value.family_id){
                                    setListedGens(null);
                                    register('species_id', { value: 0 });
                                    setValue('species_id', 0);
                                    register('genus_id', { value: 0 });
                                    setValue('genus_id', 0);
                                    adminService.listSpeciesByFamily(value.family_id).then(
                                        response => {
                                            setListedEsp(response.data);
                                        }
                                    )
                                    adminService.listGenusByFamily(value.family_id).then(
                                        response => {
                                            setListedGens(response.data);
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            }else if( name == "genus_id" ){
                if( value.genus_id == 0){ //Está selecionando nenhum gênero, tem q limpar a seleção de espécie
                    if(value.species_id != 0){
                        adminService.listSpeciesByFamily(value.family_id).then(
                            response => {
                                setListedEsp(response.data);
                                register('species_id', { value: 0 });
                                setValue('species_id', 0);
                            }
                        )
                    }                    
                }else{
                    if(value.family_id == 0){ //Esse é o caso de escolher o dropdown de genero antes do dropdown de familia.
                        adminService.getFamilyByGenusId(value.genus_id).then(
                            response => {
                                register('family_id', { value: response.data[0].id });
                                setValue('family_id', response.data[0].id);
                            }
                        )
                    }
                    adminService.listSpeciesByGenus(value.genus_id).then(
                        response => {
                            setListedEsp(response.data)
                        }
                    )
                } 
                
            }else if ( name == "species_id" ){ //Aqui é quando a família é escolhida primeiro, e então a espécie.
                if(value.genus_id == 0){
                    adminService.getGenusBySpeciesId(value.species_id).then(
                        response => {
                            setListedGens(response.data)
                            register('genus_id', { value: response.data[0].id });
                            setValue('genus_id', response.data[0].id);
                        }
                    )
                }
            }
        });

        return () => {subscription.unsubscribe();};
    }, [watch]);

    // Para o botão "Novo" e ampliar as imagens
    const toggleModal = (name) => {
		if(name === 'new') setNewModal(!newModal);
        if(name === 'image') setImageModal([false, null]);
	}

    const newRegister = (data) => {
        setNewModal(true);
    }

    const continueNewRegister = (data) => {
        setResetTabs(true);
        if(genSearch == false){
            setListedGens(null);
            adminService.getGenus().then(
                response => {
                    setListedGens(response.data)
                }
            )
            setListedEsp(null);
            setSavedCoal(null);
            reset();
            toggleModal('new');
        }else{
            if(!data.species_id){
                data.species_id = "0";
            }
            history.push({pathname: '/pesquisa', state: {searchData : data}, from: '/novo-registro'});
        }
    }

    const goPublic = (data) => {
        register('status', { value: 2 }); //adicionando esse campo 'status' nos daods sendo passados pro saveRegister 
        data.status = 1;
        saveRegister(data);
    }

    const generateSearch = (data) => {
        setNewModal(true);
        setGenSearch(true);
    }

    // Para o botão "Salvar"
    const saveRegister = (data) => {
        setResetTabs(true);
        if(data.type == 0){
            settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('carvão.registro.alerta.selecioneCategoria'));
            return;
        }
        if(data.family_id != 0 || data.determination != ''){

            let charsString = '';
            listedFields.map((tab, i) => {
                tab.children?.map((label, j)=>{
                    if(label.type == 'multi-select'){
                        label.children.map((option, k)=>{
                            if(option.type == 'multi-select-option'){
                                if(!data.charsAr[option.id]) charsString += '0';
                                else{
                                    if(data.charsAr[option.id]==false) charsString += '0';
                                    else charsString += '1';
                                }
                                option.children?.map((mso, l)=>{
                                    if(mso.type == 'mso-child'){
                                        if(!data.charsAr[mso.id]) charsString += '0';
                                        else{
                                            if(data.charsAr[mso.id]==false) charsString += '0';
                                            else charsString += '1';
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
            })
            let observations = {};
            for(let text in data.texts){
                observations[text] = data.texts[text];
            }

            data.observations = observations;
            data.chars = charsString;
	    data.teste = 'teste';

            delete data.texts;

            //caso não seja o primeiro save
            if(!savedCoal){
                return coalService.registerCoal(data).then((response)=>{
                    settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('carvão.registro.alerta.carvaoRegistrado'));
                    if(newModal == false){
                        setSavedCoal(response.data);
                    }else if(genSearch == false){
                        setSavedCoal(null);
                        reset();
                        toggleModal('new');
                    }else{
                        continueNewRegister(data);
                    }
                }).catch((err)=>{
                    console.log(err);
                    settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
                })
            }else{
                return coalService.updateCoal(data, savedCoal.id).then((response)=>{
                    settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('carvão.registro.alerta.carvaoAtualizado'));
                    if(newModal == false){
                        setSavedCoal(response.data);
                    }else{
                        continueNewRegister(response.data);
                    }
                }).catch((err)=>{
                    console.log(err);
                    settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
                })
            }
            
        }else{
            settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('Por favor selecione ou preencha pelo menos uma classificação taxonômica.'));
            return;
        }
    }

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;

        coalService.getAllSites().then(
            response => {
                if(_isMounted && (response.data.length > 0)){
                    setListedSites(response.data);
                }
            }
        )

        adminService.listAllFamilies().then(
            response => {
                if(_isMounted){
                    setListedFams(response.data);
                }
            }
        )

        adminService.getGenus().then(
            response => {
                if(_isMounted){
                    setListedGens(response.data);
                }
            }
        )

        if(location.from){
            let coal = location.state.searchData;
            setSavedCoal(coal);

            if(coal.family_id == "0"){ // Começa checando se n tem uma família selecionada       
                adminService.listAllFamilies().then(
                    response => {
                        if(_isMounted){
                            setListedFams(response.data);
                        }
                    }
                )
                adminService.getGenus().then(
                    response => {
                        if(_isMounted){
                            setListedGens(response.data);
                        }
                    }
                )
            }else if(coal.genus_id == "0"){  // Se tiver, checa se n tem um genero
                register('family_id', { value: coal.family_id });
                setValue('family_id', coal.family_id);
                adminService.listSpeciesByFamily(coal.family_id).then(
                    response => {
                        setListedEsp(response.data);
                    }
                )
                adminService.listGenusByFamily(coal.family_id).then(
                    response => {
                        setListedGens(response.data);
                    }
                )
            }else if(coal.species_id == "0"){ //se tiver, checa se n tem uma espécie
                register('genus_id', { value: coal.genus_id });
                setValue('genus_id', coal.genus_id);
                register('family_id', { value: coal.family_id });
                setValue('family_id', coal.family_id);
                adminService.listSpeciesByGenus(coal.genus_id).then(
                    response => {
                        setListedEsp(response.data);
                    }
                )
                adminService.listGenusByFamily(coal.family_id).then(
                    response => {
                        setListedGens(response.data);
                    }
                )
            }else{
                register('species_id', { value: coal.species_id });
                setValue('species_id', coal.species_id);
                register('genus_id', { value: coal.genus_id });
                setValue('genus_id', coal.genus_id);
                register('family_id', { value: coal.family_id });
                setValue('family_id', coal.family_id);
            }
            
            if(coal.sub_fam != ""){
                register('sub_fam', { value: coal.sub_fam });
                setValue('sub_fam', coal.sub_fam);
            }
            if(coal.determination != ""){
                register('determination', { value: coal.determination });
                setValue('determination', coal.determination);
            }
            if(coal.origin != ""){
                register('origin', { value: coal.origin });
                setValue('origin', coal.origin);
            }
            if(coal.site_id != 0){
                register('site_id', { value: coal.site_id });
                setValue('site_id', coal.site_id);
            }
            if(coal.code != ""){
                register('code', { value: coal.code });
                setValue('code', coal.code);
            }
            if(coal.locale != ""){
                register('locale', { value: coal.locale });
                setValue('locale', coal.locale);
            }              
            if(coal.type != 0){
                register('type', { value: coal.type });
                setValue('type', coal.type);
            }

            // Essa é a parte responsável por marcar as checkboxes
            coal.chars.map((char, id) => { //recebendo e marcando os chars dinamicos tb
                if(char == true){
                    register('charsAr.'+id, {value: true});
                    setValue('charsAr.'+id, true);
                }else if(char == false){
                    register('charsAr.'+id, {value: false});
                    setValue('charsAr.'+id, false);
                }
            });

            // Preenche os campos de texto
            coal.observations.map((obsevation, id) => {
                register('texts.'+obsevation.fields_id, {value: obsevation.text});
                setValue('texts.'+obsevation.fields_id, obsevation.text);
            });

            if(coal.images.length > 0){
                let images = [];
                coal.images.map((image, id) => {
                    images.push(image.photo);
                });
                setCoalImages(images);
            }

        }else{
            if(idToken != null){
                history.push('/pesquisa');
            }
        }

		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	}, [])

    if(user === null){
        return(
            <div className="fa-3x carregando-pagina">
                <i className="fas fa-spinner fa-pulse"></i>
            </div>
        )
    }
    else if(!user){
        return(
            <SemPermissaoPanel/>
        )
    }else{
        return(<>
            <div className="position-fixed h-auto d-flex flex-row justify-content-between page-header-fixed align-items-center" >
                <h1 className="page-header ph-no-margin d-flex flex-column fs-18px">{savedCoal != null ? t('carvão.edição.titulo') : t('carvão.registro.botão.novoRegistro')}</h1>
                <div className='ph-no-margin d-flex flex-row align-items-center'>
                    <div className='d-flex flex-row '>
                        <div className="ms-1">
                            <button type="submit" onClick={handleSubmit(newRegister)}
                                className="btn btn-primary d-block w-100 btn-lg h-38px fs-13px" >
                                    {t('carvão.registro.botão.novoRegistro')}
                            </button>
                        </div>
                        <div className="ms-1">
                            <button type="submit" onClick={handleSubmit(saveRegister)}
                                disabled={isSubmitting || !isValid} className="btn btn-success d-block w-100 btn-lg h-38px fs-13px" >
                                    {isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
                                    {t('carvão.registro.botão.salvarRegistro')}
                            </button>
                        </div>
                        <div className="ms-1">
                            <button type="submit" onClick={handleSubmit(goPublic)}
                                className="btn btn-success d-block w-100 btn-lg h-38px fs-13px" >
                                    {t('carvão.registro.botão.publicar')}
                            </button>
                        </div>
                        <div className="ms-1">
                            <button type="submit" onClick={handleSubmit(generateSearch)}
                                className="btn btn-success d-block w-100 btn-lg h-38px fs-13px" >
                                    {t('carvão.registro.botão.gerarPesquisa')}
                            </button>
                        </div>
                    </div>  
                </div>
            </div>
            <form>
                <div className='row h-auto' style={{ marginTop: '55px' }}>
                    <div className='col'>
                        <Panel>
                            <PanelHeader className="pt-1 pb-1" noButton={true}>
                                <div className="d-flex align-items-center">                                       
                                    <select name="type" className="col form-select w-auto" id="nome" {...register('type')} >
                                        <option value='0'>{t('carvão.dropdown.categoria.selecioneCategoria')}</option>
                                        <option value='1'>{t('carvão.dropdown.categoria.arqueologicos')}</option>
                                        <option value='2'>{t('carvão.dropdown.categoria.lenhosReferencia')}</option>
                                        <option value='3'>{t('carvão.dropdown.categoria.literatura')}</option>
                                    </select>      

                                    <div className="col-5 flex-grow-1"></div>                       
                                </div>                                        
                            </PanelHeader>
                            <PanelBody className="mb-n2">
                                <div className='row mb-1'>                              
                                    <div className='col'>
                                        <div className="form mb-1">
                                            {
                                                listedFams === null &&
                                                <div>
                                                    <br/><i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            }
                                            {
                                                listedFams &&
                                                <select name="family_id" className="form-select fs-12px fw-lighter" id="family_id" {...register('family_id')}>
                                                    <option value='0'>{t('carvão.dropdown.selecioneFamília')}</option>
                                                    {
                                                        listedFams.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('glossario.taxonomias.familia')}</label>
                                            }
                                        </div>   
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <input name="sub_fam" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.subFamilia')} id="sub_fam" {...register('sub_fam')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.subFamilia')}</label>
                                        </div> 
                                    </div>
                                    <div className='col'>
                                        <div className="form">                                            
                                            {
                                                listedGens === null &&
                                                <div>
                                                    <br/><i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            }
                                            {
                                                listedGens &&
                                                <select name="genus_id" className="form-select fs-12px fw-lighter" id="genus_id" {...register('genus_id')}>
                                                    <option value='0'>{t('carvão.dropdown.selecioneGênero')}</option>
                                                    {
                                                        listedGens.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('glossario.taxonomias.genero')}</label>
                                            }
                                        </div> 
                                    </div>
                                    <div className='col'>
                                        <div className="form">
                                            {
                                                listedEsp === null &&
                                                <select className="form-select fs-12px fw-lighter">
                                                    <option value='0'>{t('carvão.dropdown.selecioneFamíliaOuGênero')}</option>
                                                </select>
                                            }
                                            {
                                                listedEsp &&
                                                <select name="species_id" className="form-select fs-12px fw-lighter" id="species_id" {...register('species_id')}>
                                                    <option value='0'>{t('carvão.dropdown.selecioneEspécie')}</option>
                                                    {
                                                        listedEsp.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('glossario.taxonomias.especie')}</label>
                                            }
                                        </div> 
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-floating">
                                            <input name="determination" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.determinação')} id="determination" {...register('determination')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.determinação')}</label>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className='row mb-1'>
                                    <div className='col-5'>
                                        <div className="form-floating">
                                            <input name="origin" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.procedência')} id="origin" {...register('origin')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.procedência')}</label>
                                        </div>                                        
                                    </div>
                                    <div className='col'>
                                        <div className="form">
                                            {
                                                listedSites === null &&
                                                <div>
                                                    <br/><i className="fas fa-spinner fa-pulse"></i>
                                                </div>
                                            }
                                            {
                                                listedSites &&                                                
                                                <select name="site_id" className="form-select fs-12px fw-lighter" id="site_id" {...register('site_id')}>
                                                    <option value="0">{t('carvão.dropdown.selecioneSítio')}</option>
                                                    {
                                                        listedSites.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                //<label className="form-label col-form-label">{t('carvão.sítio')}</label>
                                            }
                                        </div>  
                                    </div>                                  
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <input name="code" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.código')} id="code" {...register('code')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.código')}</label>
                                        </div>                                        
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <input name="locale" type="text" className="form-control h-35px fs-12px fw-lighter" placeholder={t('carvão.localização')} id="locale" {...register('locale')}/>
                                            <label className="d-flex align-items-center fs-12px text-gray-600">{t('carvão.localização')}</label>
                                        </div>                                        
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div> 
                    <FichaCarvao register={register} setListedFields={setListedFields} textFields={true} setImageModal={setImageModal} coalImages={coalImages} resetTabs={resetTabs} setResetTabs={setResetTabs} settings={settings}/>
                </div>

                <Modal isOpen={newModal} toggle={() => toggleModal('new')}>
                    <ModalHeader 
                        toggle={() => toggleModal('new')} 
                        close={
                            <button className="btn-close" onClick={() => toggleModal('new')}></button>
                        }>
                            <i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
                    </ModalHeader>    
                    
                    <ModalBody>
                        <div className="alert mb-0 fs-5">
                            <p>{t('carvão.registro.modal.novoRegistro.textoAviso1')}</p>
                            <p>{t('carvão.registro.modal.novoRegistro.textoAviso2')}</p>
                        </div>
                    </ModalBody>
                    
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => toggleModal('new')}>{t('botão.cancelar')}</button>
                        <button className="btn btn-primary" type="submit" onClick={handleSubmit(continueNewRegister)} >
                        {t('botão.continuar')}</button>
                        <button className="btn btn-success" type="submit" onClick={handleSubmit(saveRegister)} >
                        {t('carvão.registro.modal.novoRegistro.salvarContinuar')}</button>
                    </ModalFooter>
                </Modal>

                <Modal size='xl' isOpen={imageModal[0]} toggle={() => toggleModal('image')}>
                    <ModalHeader 
                        toggle={() => toggleModal('image')} 
                        close={
                            <button className="btn-close" onClick={() => toggleModal('image')}></button>
                        }>
                            <i className="fa-regular fa-image" aria-hidden="true"></i> {t('imagens.modal.imagem')}
                    </ModalHeader>  

                    <ModalBody>
                        <img src={imageModal[1]} className="container-sm"></img>
                    </ModalBody>
                </Modal> 
            </form>        
        </>)
    }
}
