import React, { useContext, useState, useEffect } from 'react';

import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { AppSettings } from '../../config/app-settings.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminService from '../../services/AdminService/admin-service.js';
import coalService from '../../services/CoalService/coal-service.js';
import classnames from 'classnames';

export default function BuscaTaxFam(props){
    const location = useLocation();
    const history = useHistory();
    const { user, ...settings } = useContext(AppSettings);
    const { t, i18n } = useTranslation();

    const [ activeTab, setActiveTab ] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    const [ currentPage, setCurrentPage ] = useState(1);
	const [ lastPage, setLastPage ] = useState(1);

    const swapName = e => {return Object.assign({}, e, {name: e["name_" + i18n.language]});}

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;

        adminService.listAllFamilies().then(response =>{
            let tempTabList = [];
            let firstLetter = response.data[0].name.charAt(0);
            let tempFams = [];
            response.data.map((fam, i) => {
                if(fam.name.charAt(0) == firstLetter){
                    tempFams.push(fam);
                }
                else{
                    tempTabList.push({
                        name: firstLetter,
                        fams: tempFams
                    });
                    firstLetter = fam.name.charAt(0);
                    tempFams = [];
                    tempFams.push(fam);               
                }
            });
            tempTabList.push({
                name: firstLetter,
                fams: tempFams
            });
            setTabList(tempTabList);
            if(tempTabList[0].fams.length <= 36){
                setFilteredList(tempTabList[0].fams);
                setLastPage(1);
            }else{
                setFilteredList(tempTabList[0].fams.slice(0, 36));
                setLastPage(Math.ceil(tempTabList[0].fams.length/36));
            }
        }).catch(
            err => {
                console.log(err);
            }
        )

		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	}, [i18n.language])

    function toggleTab(tab) {
		if (activeTab !== tab) {
            setActiveTab(tab);
            setCurrentPage(1);
            if(tabList[tab].fams.length <= 36){
                setFilteredList(tabList[tab].fams);
                setLastPage(1);
            }else{
                setFilteredList(tabList[tab].fams.slice(0, 36));
                setLastPage(Math.ceil(tabList[tab].fams.length/36));
            }
		}
	}
    
    const getPage = (direction) => {
        let current = currentPage;
		let isMounted = true;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
                return 0;
            }
            current = current - 1;
        }else if (direction === 1){
            if (current === lastPage){
				settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
                return 0;
            }
            current = current + 1;
        }
		if(isMounted){
            const newList = tabList[activeTab].fams.slice( (current-1)*(36), ((current-1)*(36)+36) );       
			setCurrentPage(current);
			setFilteredList(newList);
		}
    }

    const searchForCoals = (id) => {
        const request = {
            'family_id': id
        }
        if(user) request.public = false;
        else request.public = true;

        return coalService.filterCoalByFamily(request).then(response => {
            if(response.data.length == 0){
                settings.handleSetAlert('info', '', t('carvão.pesquisa.modal.semResult'));
            }else{
                let resp = [];
                response.data.map((c, i) => {
                    resp.push([c, 'null']);
                })
                history.push({pathname: '/pesquisa-resultado', state: {coals : resp}, from: '/taxonomia/familia'});
            }
        }).catch((err)=>{
            settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
            console.log(err);
        }) 
    }

    return(
        <>
            <div className="position-fixed h-auto d-flex flex-row justify-content-between page-header-fixed align-items-center" >
                <h1 className="page-header ph-no-margin d-flex flex-column fs-18px" >{t('carvão.buscaPorTaxon.tituloFamilia')}</h1>
            </div>
            <div className='row' style={{ marginTop: '55px' }}>
                <div className='col'>
                    {
                        tabList.length > 0 &&
                        <Panel> 
                            <PanelHeader noButton={true} className="h-40px">
                                <div className='d-flex flex-row fs-6 justify-content-end' >                                            
                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                        <div onClick={() => getPage(0)} style={{cursor: "pointer"}}><i className="fas fa-angle-left me-3"></i></div>
                                        <div>{t('paginação.page')}: {currentPage} / {lastPage}</div>
                                        <div onClick={() => getPage(1)} style={{cursor: "pointer"}}><i className="fas fa-angle-right ms-3"></i></div>
                                    </div>
                                </div>  
                            </PanelHeader>
                            <PanelBody className="d-flex flex-column">
                                <Nav tabs className='d-flex flex-row align-self-center'>
                                    {
                                        tabList.map((tab, index) => (
                                            <NavItem key={index} >
                                                <NavLink onClick={() => { toggleTab(index); }} className={classnames({ active: activeTab === index, 'bg-black-200' : activeTab === index, '' : activeTab !== index})} >                                                    
                                                    <span>
                                                        {t(tab.name)}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        ))
                                    }
                                </Nav>
                                <TabContent className="tab-content bg-black-200 p-3 panel bg rounded-0 mt-0 mb-0 w-100" activeTab={activeTab}>
                                    <TabPane tabId={activeTab} className="d-flex flex-row">
                                        <div className="w-100" style={{columnCount: '3', cursor: 'pointer'}}>
                                            {
                                                filteredList.map((f, i)=> (
                                                    <p onClick={() => { searchForCoals(f.id); }} key={f.id} className="bg-gray-800 p-2 ps-4 mb-1 fw-bold" >{f.name} {'>'}</p>
                                                ))
                                            }
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </PanelBody>
                        </Panel>
                    }
                    {
                        tabList.length == 0 &&
                        <div className="fa-3x carregando-pagina">
                            <i className="fas fa-spinner fa-pulse"></i>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}