import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useTranslation, Trans } from "react-i18next";

export default function Sobre() {
    const { t } = useTranslation();

    return (
        <div>
            <h1 className="page-header d-flex flex-column">
                {t('sobre.titulo')} <small>{t('sobre.subtitulo')}</small>
            </h1>

            <Panel>
                <PanelHeader>{t('sobre.cabeçalho.alerta').toUpperCase()}</PanelHeader>
                <PanelBody>
                    <section style={{fontSize: "0.9rem"}}>
                        <p>
                            {t('sobre.texto.alerta.paragrafo1')}
                        </p>

                        <p>
                            {t('sobre.texto.alerta.paragrafo2')}
                        </p>

                        <p>
                            {t('sobre.texto.alerta.paragrafo3')}
                        </p>
                    </section>
                </PanelBody>
            </Panel>

            <Panel>
                <PanelHeader>{t('sobre.cabeçalho.apresentacao').toUpperCase()}</PanelHeader>
                <PanelBody>
                    <section style={{fontSize: "0.9rem"}}>
                        <p>
                            {t('sobre.texto.apresentacao.paragrafo1')}
                        </p>

                        <p>
                            {t('sobre.texto.apresentacao.paragrafo2')}
                        </p>

                        <p>
                            {t('sobre.texto.apresentacao.paragrafo3')}
                        </p>

                        <p>
                            {t('sobre.texto.apresentacao.paragrafo4')}
                        </p>

                        <p>
                            {t('sobre.texto.apresentacao.paragrafo5')}
                        </p>

                        <p>
                            {t('sobre.texto.apresentacao.paragrafo6')}
                        </p>
                    </section>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelHeader>{t('sobre.cabeçalho.comoCitar').toUpperCase()}</PanelHeader>
                <PanelBody>
                    <section style={{fontSize: "0.9rem"}}>
                            <p>
                                {t('sobre.texto.como_citar.parte1')}: &lt;
                                <a style={{filter: "brightness(1.25)"}} href={window.location.origin} className="link-info">
                                    {window.location.origin}
                                </a>
                                &gt;. {t('sobre.texto.como_citar.parte2')}: [{new Date().toLocaleDateString()}]. 
                            </p>
                    </section>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelHeader>{t('sobre.cabeçalho.politicaDeUso').toUpperCase()}</PanelHeader>
                <PanelBody>
                    <section style={{fontSize: "0.9rem"}}>
                            <p>
                                {t('sobre.texto.politica_de_uso.paragrafo1')}
                            </p>

                            <p>
                                {t('sobre.texto.politica_de_uso.paragrafo2')}
                            </p>
                    </section>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelHeader>{t('sobre.cabeçalho.contato').toUpperCase()}</PanelHeader>
                <PanelBody>
                    <section style={{fontSize: "0.9rem"}}>
                        <p>
                            <Trans i18nKey="sobre.texto.contato">
                                Para comentários, questões ou sugestões, por favor contactar {' '}
                                <a style={{filter: "brightness(1.25)"}} href = "mailto: anthrakos@mn.ufrj.br" className="link-info">anthrakos@mn.ufrj.br</a> ou {' '}
                                <a style={{filter: "brightness(1.25)"}} href = "mailto: lap@mn.ufrj.br" className="link-info">lap@mn.ufrj.br</a>.
                            </Trans>
                        </p>
                    </section>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelHeader>{t('sobre.cabeçalho.creditos').toUpperCase()}</PanelHeader>
                <PanelBody>
                    <section style={{fontSize: "0.9rem"}}>
                        {t('sobre.texto.creditos.Desenvolvimento')}:<br/>
                        {t('sobre.texto.creditos.LAP')}<br/>
                        {t('sobre.texto.creditos.PPGArq')}<br/>
                        {t('sobre.texto.creditos.MN')}<br/>
                        <br/>
                        <p>
                            {t('sobre.texto.creditos.paragrafo1')}
                        </p>
                        <p>
                            <Trans i18nKey="sobre.texto.creditos.paragrafo2">
                                O logotipo do sistema é um desenho livremente inspirado de um carvão  arqueológico de <i>Pouteria</i> sp. (o gênero do abiu).
                            </Trans>
                        </p>
                    </section>	
                </PanelBody>
            </Panel>
		</div>
    );
}