import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SemPermissaoPanel from '../../components/sem-permissao-panel/sem-permissao-panel.jsx';

import adminService from '../../services/AdminService/admin-service.js';

import { useTranslation } from 'react-i18next';

const AutorizarPendentes = (props) => {
	const { user, ...settings } = useContext(AppSettings);
	const { t } = useTranslation();
	const [ listedUsers, setListedUsers ] = useState(null);
	const [ lastPage, setLastPage ] = useState(null);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ userIndex, setUserIndex ] = useState(-1);
	const [ modalApprove, setModalApprove ] = useState(false);
	const [ modalDelete, setModalDelete ] = useState(false);
	const [ selectedUsers, setSelectedUsers ] = useState([]);
	const [ searchUser, setSearchUser ] = useState('');

	let _isMounted;

	useEffect(() => {
		if (user) listUsers(1);
		return () => { _isMounted = false; }
	}, [user]);

	function listUsers(page){
		_isMounted = true;
		return adminService.listPendingUsers(page).then(
			response => {
				if(_isMounted){
					setListedUsers(response.data.data);
					setCurrentPage(page);
					setLastPage(response.data.last_page);
				}
			}
		).catch(err => {
			console.log(err);
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
		})
	}

	function getUserInfo(index){ // Quando o admin clica no nome de alguem e quer ver mais infos sobre
		setUserIndex(index);
	}

	function handleChange(event) { // Chamado quando algum checkbox muda de selecionado pra não-selecione e vice-versa
		let tam = selectedUsers.length;
		let temp = selectedUsers;
		selectedUsers.forEach((u, i) => {
			if(u.id == event.target.id){
				temp.splice( i, 1 );
			}
		})
		if (temp.length == tam){
			const selected = {
				name: event.target.name,
				id: event.target.id
			}
			temp.push(selected);
		}
		setSelectedUsers(temp);
	}

	function toggleModal(name) {
		if(name === 'aprovar'){
			setModalApprove( !modalApprove );
		}else{
			setModalDelete( !modalDelete );
		}
	}

	function getPage(direction){ // Troca de página da listagem de users
		_isMounted = true;
        
		let current = currentPage;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
                return 0;
            }
            current = current - 1;
			listUsers(current);
        }else{
            if (current === lastPage){
                settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
                return 0;
            }
            current = current + 1;
			listUsers(current);
        }
    }

	function handleSubmit(type){
		if(selectedUsers.length === 0){
			settings.handleSetAlert('danger', t('alerta.erro.titulo'), t('authPendente.alerta.semSeleção'));
			return;
		}

		if(type === 1){
			toggleModal('aprovar');
		}else{
			toggleModal('deletar');
		}
	}

	async function makeApiCall(type){
		let idArray = [];
		selectedUsers.forEach(u => {
			idArray.push(u.id);
		})

		const request = {
			'users_id': idArray
		}

		if(type === 1){
			return adminService.approveUser(request).then(() => {
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('authPendente.alerta.usuarie-aprovado'));
				setUserIndex(0);
				setSelectedUsers([]);
				listUsers(1);
				toggleModal('aprovar');
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}else{
			return adminService.removeUser(request).then(() => {
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('authPendente.alerta.usuarie-recusado'));
				setUserIndex(0);
				setSelectedUsers([]);
				listUsers(1);
				toggleModal('deletar');
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}
	}

	return(<> 
		{ (user === null || (user !== undefined && listedUsers === null)) &&
			<div className="fa-3x carregando-pagina">
				<i className="fas fa-spinner fa-pulse"></i>
			</div>
		}
		{ (user === undefined || ( user && !user.is_admin) ) &&
			<SemPermissaoPanel />
		}
		{ user && user.is_admin && listedUsers && listedUsers.length == 0 &&
			<>
				<h1 className="page-header d-flex flex-column fs-3">{t('authPendente.titulo')}</h1>
				<Panel>
					<PanelHeader>{t('authPendente.titulo')} </PanelHeader>
					<PanelBody>
						<p>{t('Nenhum pedido pendente.')}</p>
					</PanelBody>
				</Panel>
			</>
		}
		{ user && user.is_admin && listedUsers && listedUsers.length > 0 && 
			<div>
				<h1 className="page-header d-flex flex-column fs-18px">{t('authPendente.titulo')}</h1>

				<div className="row">
					<div className="col-xl-6 mb-4">
						<Panel>
							<PanelHeader className="h-40px">
								<div className="d-flex align-items-center">
									<div className="col-6">{t('authPendente.titulo')}</div>

									<div className="col d-flex align-items-left">
										<div className="col d-flex align-items-center ms-n5">
											<div className="col">
												<input name="searchgem" type="text" className="form-control fs-12px fw-lighter" placeholder={t('gerencia.gerencia-usuarios.buscaUser')} id="searchgem" onChange= {(e) => setSearchUser(e.target.value)}/>
											</div>
											<p className="ms-4"></p>
										</div>     						
									</div>

									<div className="d-flex flex-row justify-content-end fs-13px mb-n3">
										<p onClick={() => getPage(0)} style={{cursor: "pointer"}}><i className="fas fa-angle-left me-3"></i></p>
										<p>{t('paginação.page')}: {currentPage} / {lastPage}</p>
										<p onClick={() => getPage(1)} style={{cursor: "pointer"}}><i className="fas fa-angle-right ms-3"></i></p>
										<p className="ms-4"></p>
									</div>
									
								</div>
							</PanelHeader>
							<PanelBody>
								<div className="table-responsive">
									<table className="table m-0 mt-n2 fs-13px">
										<thead>
											<tr>
												<th></th>
												<th>{t('userInfo.nome')}</th>
												<th>{t('userInfo.email')}</th>
											</tr>
										</thead>
										<tbody>
											{
												listedUsers.filter((val) => {
													if (searchUser === 0) {
														
													}else{
													
													if( searchUser == "" ){
														return val
													} else if (val.name.toLowerCase().includes(searchUser.toLowerCase())){
														return val
													}
												}
												
												}).map((u, index)=> 
													<tr key={u.id} onClick={() => getUserInfo(index)}>
														<td>
															<input 
																className="form-check-input"
																type="checkbox" 
																onChange={handleChange} 
																id={u.id} name={u.name} 
															/>
														</td>
														<td>{u.name}</td>
														<td>{u.email}</td>
													</tr> 
												)
											}
										</tbody>
									</table>
								</div>
							</PanelBody>
						</Panel>
						<div className="d-flex flex-row justify-content-center">
							<button type="submit" onClick={() => handleSubmit(1)} className="btn btn-success d-block h-auto w-auto btn-lg fs-12px me-3">
								{t('authPendente.botão.aprovarSelecionados')}
							</button>
							<button type="submit" onClick={() => handleSubmit(0)} className="btn btn-danger d-block h-auto w-auto btn-lg fs-12px">
								{t('authPendente.botão.recusarSelecionados')}
							</button>
						</div>
					</div>
					<div className="col-xl-6">
						<Panel>
							<PanelHeader>{t('authPendente.cabeçalho.dadosUser')}</PanelHeader>
							<PanelBody>
								<div className="d-flex mt-4">
									{
										(userIndex<0) &&
										<p className="fs-5">{t('authPendente.texto.nenhumSelecionado')}</p>
									}
									{
										(userIndex>=0) && 
										<div className="fs-5">
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.nome')}: </p>
												<p className='text-break'> {listedUsers[userIndex].name}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.apelido')}: </p>
												<p className='text-break'> {listedUsers[userIndex].username}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.email')}: </p>
												<p className='text-break'> {listedUsers[userIndex].email}</p>
											</div>
											<hr className="bg-gray-600 opacity-2"/>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.instituição')}: </p>
												<p className='text-break'> {listedUsers[userIndex].institution}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.país')}: </p>
												<p className='text-break'> {listedUsers[userIndex]?.country_name}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.titulação')}: </p>
												<p className='text-break'> {listedUsers[userIndex]?.title_name}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.formaçãoAcadêmica')}: </p>
												<p className='text-break'> {listedUsers[userIndex].academic}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.areasDeInteresse')}: </p>
												<p className='text-break'> {listedUsers[userIndex].interest}</p>
											</div>
										</div>
									}
								</div>
							</PanelBody>
					</Panel>
					</div>
				</div>

				<Modal isOpen={modalApprove} toggle={() => toggleModal('aprovar')}>
					<ModalHeader 
						toggle={() => toggleModal('aprovar')} 
						close={<button className="btn-close" onClick={() => toggleModal('aprovar')}></button>}
					>
							<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<ModalBody>
						<div className="alert alert-warning mb-0 fs-5">
							<p>{t('authPendente.modal.aprovar.texto1')}:</p>
							<ul>
								{
									selectedUsers.map(u => <li key={u.id}> {u.name}</li>)
								}
							</ul>
							<p>{t('authPendente.modal.aprovar.texto2')}</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={() => toggleModal('aprovar')}>{t('botão.cancelar')}</button>
						<button className="btn btn-success" onClick={() => makeApiCall(1)} >{t('authPendente.modal.botão.aprovar')}</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={modalDelete} toggle={() => toggleModal('deletar')}>
					<ModalHeader 
						toggle={() => toggleModal('deletar')} 
						close={<button className="btn-close" onClick={() => toggleModal('deletar')}></button>}
					>
						<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<ModalBody>
						<div className="alert alert-danger mb-0 fs-5">
							<p>{t('authPendente.modal.rejeitar.texto1')}:</p>
							<ul>
								{
									selectedUsers.map(u => <li key={u.id}> {u.name}</li>)
								}
							</ul>
							<p>{t('authPendente.modal.rejeitar.texto2')}</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={() => toggleModal('deletar')}>{t('botão.cancelar')}</button>
						<button className="btn btn-danger" onClick={() => makeApiCall(0)} >{t('authPendente.modal.botão.remover')}</button>
					</ModalFooter>
				</Modal>
			</div>
		}
	</>)
}

export default AutorizarPendentes;