import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list.jsx';
import useMenuTitles from './menu.jsx';
import { useTranslation } from 'react-i18next';

import { AppSettings } from '../../config/app-settings.js';

export default function SidebarNav() {
	const { user } = useContext(AppSettings);

	const { t } = useTranslation();
	const {guest: menu_guest, user: menu_user, admin: menu_admin} = useMenuTitles();
	
	const [active, setActive] = useState(-1);
	const [clicked, setClicked] = useState(-1);
	const [ menuAtual, setMenuAtual ] = useState(0);
	const menus = [menu_guest, menu_user, menu_admin];
	//const [menu, setMenu] = useState(0);

	useEffect(() => {
		if(user?.is_admin == 0) setMenuAtual(1);
		else if(user?.is_admin > 0) setMenuAtual(2);
		else setMenuAtual(0);
	}, [user]);

	function handleExpand(e, i, match) {
		e.preventDefault();

		if (clicked === -1 && match) {
			setActive(-1);
			setClicked(1);
		} else {
			setActive(active === i ? -1 : i);
			setClicked(1);
		}
	}

	return (
		<div className="menu">
			<div className="menu-header">{t('navegação.titulo')}</div>

			{
				menus[menuAtual].map((menu, i) => (
					<Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
						<SidebarNavList
							data={menu} 
							key={i} 
							expand={(e) => handleExpand(e, i, match)}
							active={i === active} 
							clicked={clicked}
						/>
					)} />
				))
			}
		</div>
	);
}