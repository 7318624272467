import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ptLocale from './locale/pt.json';
import enLocale from './locale/en.json';
import frLocale from './locale/fr.json';


const fallbackLng = ['pt'];
const availableLanguages = ['pt', 'en', 'fr'];

export default i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // FIXME VV
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'pt',
    fallbackLng,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        pt: { translation: ptLocale },
        en: { translation: enLocale },
        fr: { translation: frLocale },
    }
  });