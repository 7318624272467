import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';


export default function DropdownAjuda (props) {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleToggle = () => { setDropdownOpen(!dropdownOpen); }

	return (
		<Dropdown isOpen={dropdownOpen} toggle={handleToggle} className="navbar-item navbar-user dropdown" tag="div">
            <>
                <DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center" >
                    <span style={{ cursor:"pointer"}}>
                        <i className="fas fa-circle-question fa-fw"></i> {' '}
                        <b className="caret"></b>
                    </span>
                </DropdownToggle>
                
                <DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
                    <DropdownItem onClick={() => push('/sobre')}>{t('menuInfo.sobre')}</DropdownItem>
                    <DropdownItem onClick={() => push('/instrucoes')} disabled>{t('menuInfo.comoUsar')}</DropdownItem>
                </DropdownMenu>
            </>
		</Dropdown>
	);
}