import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SemPermissaoPanel from '../../components/sem-permissao-panel/sem-permissao-panel.jsx';

import adminService from '../../services/AdminService/admin-service.js';
import { useTranslation } from 'react-i18next';

export default function GerenciaSitios (props) {
	const { user, ...settings } = useContext(AppSettings);
	const { t } = useTranslation();
	const [ loadedSites, setLoadedSites ] = useState(false);
	const [ allSites, setAllSites ] = useState([]);
	const [ listedSites, setListedSites ] = useState([]);
	const [ length, setLength ] = useState(0);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ lastPage, setLastPage ] = useState(1);
	const [ siteModal, setSiteModal ] = useState(false);
	const [ modalDelete, setModalDelete ] = useState(false);
	const [ name, setName ] = useState('');
	const [ siteId, setSiteId ] = useState(0);
	const [searchSite, setSearchSite] = useState('');

	let isMounted = false; // TODO axios cancelation em vez de variável is mounted

	//Em ordem, para o cadastro, update e deletar
	const { register, handleSubmit, reset, formState: { errors, isSubmitting, isValid } } = useForm({ mode: "onBlur" });
	const { register: update, handleSubmit: handleUpdate, reset: reset2, formState: { errors: errors2, isSubmitting: isUpdating, isValid: isValidUp } } = useForm({ mode: "onBlur" });
	const { register: reg3, handleSubmit: handleDelete, formState: { isSubmitting: isDeleting } } = useForm({ mode: "onBlur" });

	const registerOptions = {
		obrigatorio: {
			required: t('registerOptions.obrigatorio')
		}
	}

	useEffect(()=>{
		isMounted = true;

		//acontece quando o componente é desmontado
		return () => {
			isMounted = false;
		}
	}, []);

	useEffect(()=>{
		if(user) getAllSites();
	}, [user]);

	const openModal = (site, type) => {
		setSiteId(site.u.id);
		setName(site.u.name);
		if(type === 1) setSiteModal(true);
		else setModalDelete(true);
	}

	const getAllSites = () => {
		setLoadedSites(false);
		isMounted = true;

		adminService.getAllSites().then(
			response => {
				//checando se existe algum sítio e se tem uma página só
				let listaTemp = [];
				let lastPageTemp = 1;
				if(response.data){
					if (response.data.length <= 10) {
						listaTemp = response.data;
						lastPageTemp = 1;
					}else{
						listaTemp = response.data.slice(0, 11);
						lastPageTemp = (Math.floor(response.data.length/10)+1);
					}
					if(isMounted){
						setAllSites(response.data);
						setListedSites(listaTemp);
						setLength(response.data.length);
						setLastPage(lastPageTemp);
						setCurrentPage(1);
						setLoadedSites(true);
					}
				}else{
					if(isMounted){
						setAllSites(listaTemp);
						setListedSites(listaTemp);
						setLength(0);
						setLastPage(lastPageTemp);
						setCurrentPage(1);
						setLoadedSites(true);
					}
				}
			}
		)
	}

	const makeApiCall = (data) => {
		if(siteModal){
			return adminService.updateSite(data, siteId).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.site.alerta.atualizado'));
				reset2();
				toggleModal('edit');
				getAllSites();
			}).catch((err)=>{
				if(err.request.status === 409) settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.site.alerta.jaExisteNome'));
				else settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			})
		}else if(modalDelete){
			return adminService.removeSite(siteId).then(() =>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.site.alerta.deletado'));
				toggleModal('delete');
				getAllSites();
			}).catch((err)=>{
				if(err.request.status === 500)
					settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
				else
					settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}
		
	}

	const getPage = (direction) => {
        let current = currentPage;
		isMounted = true;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
                return 0;
            }
            current = current - 1;
        }else if (direction === 1){
            if (current === lastPage){
				settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
                return 0;
            }
            current = current + 1;
        }
		if(isMounted){
			setCurrentPage(current);
			setListedSites(
				allSites.slice( (current-1)*(10), ((current-1)*(10)+11) )
			);
		}
    }

	const toggleModal = (name) => {
		isMounted = true;
		if(isMounted){
			if(name === 'edit') setSiteModal(!siteModal);

			else setModalDelete(!modalDelete);
		}
	}

	const addSite = (newSite) => {

		if(newSite === ''){
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.site.alerta.nomeVazio'));
			return;
		}
		return adminService.addSite(newSite).then(
			response => {
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.site.alerta.cadastrado'));
				reset({
					obrigatorio: ''
				});
				getAllSites();
			}
		).catch((err)=>{
			if(err.request.status === 409)
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.site.alerta.jaExiste'));
			else
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
		})
	}

	return(
		<>
			{ 
				user === null &&
				<div className="fa-3x carregando-pagina">
					<i className="fas fa-spinner fa-pulse"></i>
				</div>
			}
			{
				user === undefined &&
				<SemPermissaoPanel/>
			}
			{
				user &&
				<div>
					<h1 className="page-header d-flex flex-column fs-18px">{t('gerencia.site.titulo')}</h1>
	
					<div className="row">
						<div className="col">
							<Panel>
								<PanelHeader>
                                    {t('gerencia.site.cabeçalho.adicionar')}
								</PanelHeader>
								<PanelBody>
									<div className="mb-n2 mt-0 me-4 ms-4">
										<form onSubmit={handleSubmit(addSite)}>
											<div className="row mb-2 center justify-content-between">
												{/* <label htmlFor='name' className="form-label col-form-label ">{t('siteInfo.nome')}:</label> */}
												<input 
													type="text" name="newSiteName" 
													className="form-control fs-14px fw-lighter w-75" 
													placeholder={t('gerencia.site.cabeçalho.novoSite')}
													{...register('name', registerOptions.obrigatorio)}
												/>
												<button type="submit" disabled={isSubmitting || !isValid} className="btn btn-success d-block h-auto w-auto btn-sm fs-14px">
													{isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
													{t('gerencia.botão.adicionar')}</button>
											</div>										
										</form>
									</div>
								</PanelBody>
							</Panel>
							<Panel>
								<PanelHeader>
									<div className="d-flex align-items-center">
										<div className="col-8">{t('gerencia.site.cabeçalho.lista')}</div>
										<div className="col d-flex align-items-center">
											<div className="col d-flex align-items-center me-3">
												<div className="col">
													<input name="site" type="text" className="form-control mt-n2 mb-n2 fs-14px fw-lighter" placeholder={t('carvão.buscasítio')} id="site" onChange= {(e) => setSearchSite(e.target.value)}  />
												</div>
											</div>                           
										</div>
									</div>
								</PanelHeader>
								<PanelBody className="mt-n2">
									{
                                        (!loadedSites) &&
                                        <div className="fa-3x carregando-pagina">
											<i className="fas fa-spinner fa-pulse"></i>
										</div>
                                    }
									{
                                        (loadedSites && length == 0) &&
                                        <p><br></br>{t('gerencia.site.semCadastros')}</p>
                                    }
                                    {
                                        (loadedSites && length != 0) && 
										<div className="table-responsive" >
											<table className="table mb-1 fs-14px">
												<thead>
													<tr>
														{ user.is_admin!==0 && 
															<>
																<th width="80%">{t('siteInfo.nome')}</th>
																<th></th>
															</>
														}
														{
															user.is_admin == 0 &&
															<th width="100%">{t('siteInfo.nome')}</th>
														}
													</tr>
												</thead>
												<tbody>
													{
														(searchSite == "") &&
														listedSites?.map((u)=> 
														<tr key={u.id}>
															{ user.is_admin!==0 && 
																(<>
																	<td width="80%">{u.name}</td>
																	<td width="10%">
																		<button 
																				type="submit" 
																				onClick={() => openModal({u}, 1)} 
																				className="btn btn-warning d-block h-auto w-100 btn-sm fs-14px"
																		>{t('gerencia.botão.editar')} </button>
																	</td>
																	<td width="10%">
																		<button 
																			type="submit" 
																			onClick={() => openModal({u}, 0)} 
																			className="btn btn-danger d-block h-auto w-100 btn-sm fs-14px"
																		>{t('gerencia.botão.remover')} </button>
																	</td>
																</>)}
															{
																user.is_admin == 0 &&
																<td width="100%">{u.name}</td>
															}
														</tr> 
													)
													}
													{
														(searchSite !== "") &&
														allSites?.filter((val) => {
															if( searchSite == "" ){
																return val
															} else if (val.name.toLowerCase().includes(searchSite.toLowerCase())){
																return val
															}
														}).map((u)=> 
														<tr key={u.id}>
															
															{ user.is_admin!==0 && 
																(<>
																	<td width="80%">{u.name}</td>
																	<td width="10%">
																		<button 
																				type="submit" 
																				onClick={() => openModal({u}, 1)} 
																				className="btn btn-warning d-block h-auto w-100 btn-sm fs-14px"
																		>{t('gerencia.botão.editar')} </button>
																	</td>
																	<td width="10%">
																		<button 
																			type="submit" 
																			onClick={() => openModal({u}, 0)} 
																			className="btn btn-danger d-block h-auto w-100 btn-sm fs-14px"
																		>{t('gerencia.botão.remover')} </button>
																	</td>
																</>)}
															{
																user.is_admin == 0 &&
																<td width="100%">{u.name}</td>
															}
														</tr> 
													)
													}
												</tbody>
											</table>
											<div className="d-flex flex-row justify-content-center fs-14px mt-3 mb-n0">
												<p onClick={() => getPage(0)}><i className="fas fa-angle-left me-3"></i></p>
												<p>{t('paginação.page')}: {currentPage} / {lastPage}</p>
												<p onClick={() => getPage(1)}><i className="fas fa-angle-right ms-3"></i></p>
											</div>
										</div>
									}									
								</PanelBody>
							</Panel>
						</div>
					</div>
					
					<Modal isOpen={siteModal} toggle={() => toggleModal('edit')}>
						<ModalHeader 
							toggle={() => toggleModal('edit')} 
							close={
								<button className="btn-close" onClick={() => toggleModal('edit')}></button>
							}>
								<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
							</ModalHeader>
							
						<form onSubmit={handleUpdate(makeApiCall)}>
							<ModalBody>
								<div className="alert mb-0 fs-5">
									<p>{t('gerencia.site.modal.editar.texto1')}:</p>
									<input 
										type="text" name="name" 
										className="form-control h-35px fs-16px fw-lighter" 
										placeholder={t('gerencia.site.cabeçalho.novoSite')}
										{...update('name', registerOptions.obrigatorio)}
									/>
									<br/><p>{t('gerencia.site.modal.editar.texto2')}</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-white" onClick={() => toggleModal('edit')}>{t('botão.cancelar')}</button>
								<button className="btn btn-success" type="submit" disabled={isUpdating || !isValidUp} >
								{isUpdating && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
								{t('botão.confirmar')}</button>
							</ModalFooter>
						</form>
					</Modal>


					<Modal isOpen={modalDelete} toggle={() => toggleModal('deletar')}>
						<ModalHeader toggle={() => toggleModal('deletar')} close={<button className="btn-close" onClick={() => toggleModal('deletar')}></button>}><i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}</ModalHeader>
						<form onSubmit={handleDelete(makeApiCall)}>
							<ModalBody>
								<div className="alert alert-danger mb-0 fs-5">
									<p>{t('gerencia.site.modal.remover.texto1', { nome: name })}</p>
									<p>{t('gerencia.site.modal.remover.texto2')}</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-white" onClick={() => toggleModal('deletar')}>{t('botão.cancelar')}</button>
								<button className="btn btn-danger" type="submit" disabled={isDeleting} >
								{isDeleting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
								{t('gerencia.botão.removerComCerteza')}</button>
							</ModalFooter>
						</form>
					</Modal>

				</div>
			}
		</>
	)

}