import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../../config/app-settings.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useHistory } from 'react-router';
import SemPermissaoPanel from '../../../components/sem-permissao-panel/sem-permissao-panel.jsx';

import adminService from '../../../services/AdminService/admin-service.js';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function GerenciaTaxonomiaFamilia (props) {
	const { push } = useHistory();
	const { user, ...settings } = useContext(AppSettings);
	const { t } = useTranslation();
	const [ listedFamilies, setListedFamilies ] = useState(null);
	const [ filteredList, setFilteredList ] = useState(null);
	const [ lastPage, setLastPage ] = useState(1);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ editModal, setEditModal ] = useState(false);
	const [ modalDelete, setModalDelete ] = useState(false);
	const [ familyId, setFamilyId ] = useState(0);
	const [ name, setName ] = useState('');
	const [searchFam, setSearchFam] = useState('');

	const { register, handleSubmit, reset, formState: { errors, isSubmitting, isValid } } = useForm({ mode: "onBlur" });
	const { register: update, handleSubmit: handleUpdate, reset: reset2, formState: { errors: errors2, isSubmitting: isUpdating, isValid: isValidUp } } = useForm({ mode: "onBlur" });
	const { register: reg3, handleSubmit: handleDelete, formState: { isSubmitting: isDeleting } } = useForm({ mode: "onBlur" });

	let _isMounted;

	const registerOptions = {
		obrigatorio: {
			required: t('registerOptions.obrigatorio')
		}
	}

	useEffect(() => {
		if (user) listFamilies();
		
		return () => { _isMounted = false; }
	} , [user]);

	function listFamilies(){
		_isMounted = true;
		return adminService.listAllFamilies().then(
			response => {
				if(_isMounted){
					let listedFamiliesAuxiliar = response.data;
					setListedFamilies(listedFamiliesAuxiliar);
					setCurrentPage(1);
					if(listedFamiliesAuxiliar.length <= 10) {
						setFilteredList(listedFamiliesAuxiliar);
						setLastPage(1);
					}else{
						setFilteredList(listedFamiliesAuxiliar.slice(0, 10));
						setLastPage(Math.ceil(listedFamiliesAuxiliar.length/10));
					}
				}
			}
		).catch(err => {
			console.log(err);
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
		})
	}

	const openModal = (family, type) => {
		setFamilyId(family.u.id);
		setName(family.u.name);

		if(type === 1) setEditModal(true);
		else setModalDelete(true);
	}
	
	const makeApiCall = (data) => {
		if(editModal){
			return adminService.updateFamily(data, familyId).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.taxonomia.familia.alerta.atualizada'));
				reset2();
				setListedFamilies(null);
				listFamilies();
				toggleModal('edit');
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			})
		}else if(modalDelete){
			return adminService.deleteFamily(familyId).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.taxonomia.familia.alerta.deletado'));
				setListedFamilies(null);
				listFamilies();
				toggleModal('delete');
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'))
			})
		}
	}

	const getPage = (direction) => {
        let current = currentPage;
		let isMounted = true;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
                return 0;
            }
            current = current - 1;
        }else if (direction === 1){
            if (current === lastPage){
				settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
                return 0;
            }
            current = current + 1;
        }
		if(isMounted){
			setCurrentPage(current);
			setFilteredList(
				listedFamilies.slice((current-1)*(10), ((current-1)*(10)+10))
			);
		}
    }

	const toggleModal = (modal) => {
		if(modal === 'edit') setEditModal(!editModal);
		else setModalDelete(!modalDelete);		
	}

	const addFamily = (newFamily) => {
		if(newFamily.name === ''){
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.taxonomia.familia.alerta.nomeVazio'))
			return;
		}
		return adminService.createFamily(newFamily).then(()=>{
			settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.taxonomia.familia.alerta.cadastrado'));
			reset();
			setListedFamilies(null);
			listFamilies();
		}).catch((err)=>{
			if(err.request.status === 409)
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.taxonomia.familia.alerta.jaExiste'));
			else
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
		})
	}

	return (<>
		{ (user === null || (user !== undefined && filteredList === null)) && 
			<div className="fa-3x carregando-pagina">
				<i className="fas fa-spinner fa-pulse"></i>
			</div>
		}
		{ (user === undefined || ( user && !user.is_admin) ) &&
			<SemPermissaoPanel/>
		}
		{ user && user.is_admin && filteredList &&
			<div>
				<h1 className="page-header d-flex flex-column fs-18px">{t('gerencia.taxonomia.titulo')} - {t('glossario.taxonomias.familia_other')}</h1>

				<div className="row">
					<div className="col">
						<Panel>
							<PanelHeader>	
								{t('gerencia.taxonomia.familia.adicionar')}
							</PanelHeader>
							<PanelBody>
								<div className="mb-n2 mt-0 me-4 ms-4">
									<form onSubmit={handleSubmit(addFamily)}>
										<div className="row mb-2 center justify-content-between">
											{/* <label htmlFor='name' className="form-label col-form-label">{t('taxInfo.nome')}:</label> */}
											<input type="text"
												   name="newFamilyName"
												   className="form-control fs-14px fw-lighter w-75"
												   placeholder={t('gerencia.taxonomia.familia.novaFamiliaNome')}
												   {...register('name', registerOptions.obrigatorio)}
											/>
											<button type="submit" disabled={isSubmitting || !isValid} className="btn btn-success d-block h-auto w-auto btn-sm fs-14px ms-2">
												{isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
												{t('gerencia.botão.adicionar')}</button>
										</div>
									</form>
								</div>
							</PanelBody>
						</Panel>
						<Panel>
							<PanelHeader>
								<div className="d-flex align-items-center">
									<div className="col-8">{t('glossario.taxonomias.familia')}</div>
									<div className="col d-flex align-items-center">
										<div className="col d-flex align-items-center me-3">
											<div className="col">
												<input name="searchuser" type="text" className="form-control mt-n2 mb-n2 fs-14px fw-lighter" placeholder={t('gerencia.taxonomia.familia.buscaFamilia')} id="searchuser" onChange= {(e) => setSearchFam(e.target.value)}  />
											</div>
										</div>                           
									</div>
								</div>
							</PanelHeader>
							<PanelBody className="mt-n2">
								{
									filteredList.length === 0 &&
									<p><br></br>{t('gerencia.taxonomia.semFamilia')}</p>
								}
								{
									filteredList.length !== 0 &&
									<div>
										<div className="table-responsive">
											<table className="table mb-1 fs-14px">
												<thead>
													<tr>
														<th width="80%">{t('taxInfo.nome')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{
														(searchFam == "") &&
														filteredList?.map(u => (
															<tr key={u.id}>
																<td width="80%">{u.name}</td>
																<td className='row m-0'>
																	<div className="col">
																		<button type="submit" 
																				onClick={() => openModal({u}, 1)}
																				className="btn btn-warning d-block h-auto w-100 btn-sm fs-14px">
																					{t('gerencia.botão.editar')}
																		</button>
																	</div>
																	<div className="col">
																		<button type="submit" 
																				onClick={() => openModal({u}, 0)}
																				className="btn btn-danger d-block h-auto w-100 btn-sm fs-14px">
																					{t('gerencia.botão.remover')}
																		</button>
																	</div>
																</td>
															</tr> )
														)
													}
													{
														(searchFam !== "") &&
														listedFamilies?.filter((val) => {
															if( searchFam == "" ){
																return val
															} else if (val.name.toLowerCase().includes(searchFam.toLowerCase())){
																return val
															}
														}).map(u => (
															<tr key={u.id}>
															<td width="80%">{u.name}</td>
															<td className='row m-0 '>
																<div className="col">
																	<button type="submit" 
																			onClick={() => openModal({u}, 1)}
																			className="btn btn-warning d-block h-auto w-100 btn-sm fs-14px">
																				{t('gerencia.botão.editar')}
																	</button>
																</div>
																<div className="col">
																	<button type="submit" 
																			onClick={() => openModal({u}, 0)}
																			className="btn btn-danger d-block h-auto w-100 btn-sm fs-14px">
																				{t('gerencia.botão.remover')}
																	</button>
																</div>
															</td>
														</tr> )
														)
													}
												</tbody>
											</table>
										</div>

										<div className="d-flex flex-row justify-content-center fs-14px mt-3 mb-n2">
											<p onClick={() => getPage(0)}><i className="fas fa-angle-left me-3"></i></p>
											<p>{t('paginação.page')}: {currentPage} / {lastPage}</p>
											<p onClick={() => getPage(1)}><i className="fas fa-angle-right ms-3"></i></p>
										</div>
									</div>
								}
							</PanelBody>
						</Panel>
					</div>
				</div>
				
				<Modal isOpen={editModal} toggle={() => toggleModal('edit')}>
					<ModalHeader
						toggle={() => toggleModal('edit')}
						close={
							<button className="btn-close" onClick={() => toggleModal('edit')}></button>
						}>
							<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<form onSubmit={handleUpdate(makeApiCall)}>
						<ModalBody>
							<div className="alert mb-0 fs-5">
								<p>{t('gerencia.taxonomia.familia.modal.novoNome')}:</p>
								<input type="text" name="name" className="form-control h-35px fs-16px fw-lighter" placeholder="Nome da família"
								{...update('name', registerOptions.obrigatorio)}/>
								<br/><p>{t('gerencia.taxonomia.familia.modal.avisoAlteração')}</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('edit')}>{t('botão.cancelar')}</button>
							<button className="btn btn-success" type="submit" disabled={isUpdating || !isValidUp}>
							{isUpdating && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
							{t('botão.confirmar')}</button>
						</ModalFooter>
					</form>
				</Modal>

				<Modal isOpen={modalDelete} toggle={() => toggleModal('deletar')}>
					<ModalHeader toggle={() => toggleModal('deletar')} close={<button className="btn-close" onClick={() => toggleModal('deletar')}></button>}><i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}</ModalHeader>
					<form onSubmit={handleDelete(makeApiCall)}>
						<ModalBody>
							<div className="alert alert-danger mb-0 fs-5">
								<p>{t('gerencia.taxonomia.familia.modal.avisoRemoção', { nome: name })}</p>
								<p>{t('gerencia.taxonomia.familia.modal.cuidado')}</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('deletar')}>{t('botão.cancelar')}</button>
							<button className="btn btn-danger" type="submit" disabled={isDeleting}>
							{isDeleting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}							
							{t('gerencia.botão.removerComCerteza')}</button>
						</ModalFooter>
					</form>
				</Modal>
			</div>
		}
	</>)
}