import React, { useContext, useState, useEffect } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useHistory } from 'react-router';
import SemPermissaoPanel from '../../components/sem-permissao-panel/sem-permissao-panel.jsx';
import userService from '../../services/UserService/user-service.js';

import adminService from '../../services/AdminService/admin-service.js';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

let _isMounted = false; //pra impedir que o componente tente alterar os estados nas funções assíncronas quando não estiver mais montado
let nameArray = [];
let idHMap = new Map();

export default function GerenciaUsuarios(props) {
	const { push } = useHistory();
	const { user, ...settings } = useContext(AppSettings);
	const { t } = useTranslation();

	const [ countriesList, setCountriesList ] = useState([]);
	const [ titlesList, setTitlesList ] = useState([]);
	const [ listedUsers, setListedUsers ] = useState(null);
	const [ userIndex, setUserIndex ] = useState(null);
	const [ lastPage, setLastPage ] = useState(1);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ filteredList, setFilteredList ] = useState(null);

	const [ modalAdmin, setModalAdmin ] = useState(false);
	const [ modalDeleteAdmin, setModalDeleteAdmin ] = useState(false);
	const [ modalDelete, setModalDelete ] = useState(false);

	const { register, handleSubmit, reset, formState: { errors, isSubmitting, isValid } } = useForm({ mode: "onBlur" });
	const { register: update, handleSubmit: handleUpdate, reset: reset2, formState: { errors: errors2, isSubmitting: isUpdating, isValid: isValidUp } } = useForm({ mode: "onBlur" });
	const { register: reg3, handleSubmit: handleDelete, formState: { isSubmitting: isDeleting } } = useForm({ mode: "onBlur" });

	useEffect(() => {
		_isMounted = true;
		if (user) listSystemUsers();
		userService.listCountries().then( (response) =>{
			if (_isMounted) setCountriesList( response.data );
		});
		userService.listTitles().then( (response) =>{
			if (_isMounted) setTitlesList( response.data );
		});
		return () => { _isMounted = false }

	}, [user]);

	function listSystemUsers(){
		return adminService.listUsers().then(
			response => {
				if(_isMounted){
					setListedUsers(response.data);
					setCurrentPage(1);
					if(response.data.length <= 10) {
						setFilteredList(response.data);
						setLastPage(1);
					}else{
						setFilteredList(response.data.slice(0, 10));
						setLastPage(Math.ceil(response.data.length/10));
					}
				}
			}
		)
	}

	const resetCheckboxes = () => { // As checkboxes não usam register | Função para limpar elas após executar uma ação
		idHMap.forEach(function(key, value) {
			document.getElementById(key).checked = false;
		});
		idHMap.clear();
	}

	const handleChange = (event) => { // Chamado quando algum checkbox muda de selecionado pra não-selecione e vice-versa
		const target = event.target;
		const value = target.id;
		const key = target.name;

		if(idHMap.has(key)){
			idHMap.delete(key)
		}else{
			idHMap.set(key, value);
		}
	}

	const handleSubmitUser = (type) => {
		if(idHMap.size === 0){
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('authPendente.alerta.semSeleção'))
			return;
		}
		nameArray = Array.from(idHMap.keys());

		if(type === 1) toggleModal('admin');
		else if(type === 2) toggleModal('deletarAdmin');
		else toggleModal('deletar');
	}

	const makeApiCall = (type) => {
		const idArray = Array.from(idHMap.values());
		const request = {
			'users_id': idArray
		}

		if(modalAdmin){
			return adminService.addAdmin(request).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.gerencia-usuarios.alerta.admin-adicionado'))
				listSystemUsers();
				toggleModal('admin');
				resetCheckboxes();
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}else if(modalDeleteAdmin){
			return adminService.removeAdmin(request).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.gerencia-usuarios.alerta.admin-deletado'))
				listSystemUsers();
				toggleModal('deletarAdmin');
				resetCheckboxes();
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}else if(modalDelete){
			return adminService.removeUser(request).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.gerencia-usuarios.alerta.usuarie-deletado'))
				listSystemUsers();
				toggleModal('delete');
				resetCheckboxes();
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}
	}

	const getPage = (direction) => {
        let current = currentPage;
		let isMounted = true;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
                return 0;
            }
            current = current - 1;
        }else if (direction === 1){
            if (current === lastPage){
				settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
                return 0;
            }
            current = current + 1;
        }
		if(isMounted){
			setCurrentPage(current);
			setFilteredList(listedUsers.slice((current-1)*(10), ((current-1)*(10)+10)));
		}
    }

	const toggleModal = (name) => {
		if(name === 'admin') setModalAdmin(!modalAdmin);
		else if(name === 'deletarAdmin') setModalDeleteAdmin(!modalDeleteAdmin);
		else setModalDelete(!modalDelete);
	}

	const getUserInfo = (index) => {
		setUserIndex(index);
	}

	return(<>
		{ (user === null || (user !== undefined && filteredList === null)) &&
			<div className="fa-3x carregando-pagina">
				<i className="fas fa-spinner fa-pulse"></i>
			</div>
		}
		{ (user === undefined || ( user && !user.is_admin) ) &&
			<SemPermissaoPanel/>
		}
		{ user && user?.is_admin && filteredList &&
			<div>
				<h1 className="page-header d-flex flex-column fs-18px">{t('gerencia.gerencia-usuarios.titulo')} </h1>

				<div className="row">
					<div className="col-xl-6 mb-4">
						<Panel>
							<PanelHeader className="h-40px">
								{t('gerencia.gerencia-usuarios.usuáriesAtives')}
								<div className="d-flex flex-row justify-content-end fs-13px mt-n3 mb-n3">
									<p onClick={() => getPage(0)} style={{cursor: "pointer"}}><i className="fas fa-angle-left me-3"></i></p>
									<p>{t('paginação.page')}: {currentPage} / {lastPage}</p>
									<p onClick={() => getPage(1)} style={{cursor: "pointer"}}><i className="fas fa-angle-right ms-3"></i></p>
									<p className="ms-4"></p>
								</div>
							</PanelHeader>
							<PanelBody>
								<div className="table-responsive">
									<table className="table m-0 mt-n2 fs-13px">
										<thead>
											<tr>
												<th></th>
												<th>{t('gerencia.gerencia-usuarios.resultados.nome')}</th>
												<th>{t('gerencia.gerencia-usuarios.resultados.status')}</th>
											</tr>
										</thead>
										<tbody>
											{
												filteredList.map( (u, index)=> {
													if(u.is_admin === "SuperAdmin"){
														return <tr key={u.id} onClick={() => getUserInfo(index)}>
														<td></td>
														<td>
															{u.name}
														</td>
														<td>
															{u.is_admin}
														</td>
													</tr> 
													}
													return <tr key={u.id} onClick={() => getUserInfo(index)}>
														<td>
															<input 
																className="form-check-input" 
																type="checkbox" 
																onChange={(e) => handleChange(e)} 
																id={u.id} name={u.name}
															/>
														</td>
														<td>
															{u.name}
														</td>
														<td>
															{u.is_admin}
														</td>
													</tr> 
												})
											}
										</tbody>
									</table>
								</div>
							</PanelBody>
						</Panel>

						<div className="d-flex flex-row justify-content-center">
							{
								user.is_admin > 1 &&
								<div className="d-flex flex-row">
									<button 
										type="submit" 
										onClick={() => handleSubmitUser(1)} 
										className="btn btn-warning d-block h-auto w-auto btn-lg fs-12px me-3"
									>{t('gerencia.gerencia-usuarios.botão.tornarAdmin')}</button>
									<button 
										type="submit" 
										onClick={() => handleSubmitUser(2)} 
										className="btn btn-warning d-block h-auto w-auto btn-lg fs-12px me-3"
									>{t('gerencia.gerencia-usuarios.botão.tirarAdmin')}</button>
								</div>
							}
							<button 
								type="submit" 
								onClick={() => handleSubmitUser(3)} 
								className="btn btn-danger d-block h-auto w-auto btn-lg fs-12px"
							>{t('gerencia.gerencia-usuarios.botão.deletar')}</button>
						</div>
					</div>
					<div className="col-xl-6">
						<Panel>
							<PanelHeader>{t('userInfo.titulo')}</PanelHeader>
							<PanelBody>
								<div className="d-flex mt-4">
									{
										(userIndex===null) &&
										<p className="fs-5">{t('authPendente.texto.nenhumSelecionado')}</p>
									}
									{
										(userIndex!==null) &&
										<div className="fs-5">
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.nome')}: </p>
												<p className='text-break'> {filteredList[userIndex]?.name}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.apelido')}: </p>
												<p className='text-break'> {filteredList[userIndex]?.username}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.email')}: </p>
												<p className='text-break'> {filteredList[userIndex]?.email}</p>
											</div>
											<hr className="bg-gray-600 opacity-2" />
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.instituição')}: </p>
												<p className='text-break'> {filteredList[userIndex]?.institution}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.país')}: </p> 
												<p className='text-break'> {countriesList[[filteredList[userIndex].country_id]-1]?.name}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.titulação')}: </p>
												<p className='text-break'> {titlesList[[filteredList[userIndex].title_id]-1]?.name}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.formaçãoAcadêmica')}: </p>
												<p className='text-break'> {filteredList[userIndex]?.academic}</p>
											</div>
											<div className='d-flex flex-row'>
												<p className='fw-bold me-2'>{t('userInfo.areasDeInteresse')}: </p>
												<p className='text-break'> {filteredList[userIndex]?.interest}</p>
											</div>
										</div>
									}
								</div>
							</PanelBody>
					</Panel>
					</div>
				</div>

				<Modal isOpen={modalAdmin} toggle={() => toggleModal('admin')}>
					<ModalHeader 
						toggle={() => toggleModal('admin')} 
						close={
							<button className="btn-close" onClick={() => toggleModal('admin')}></button>
						}>
							<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<form onSubmit={handleUpdate(makeApiCall)}>
						<ModalBody>
							<div className="alert alert-warning mb-0 fs-5">
								<p>{t('gerencia.gerencia-usuarios.modal.textoTornarAdmin')}:</p>
								<ul>
									{
										nameArray.map(u => <li key={u}> {u}</li>)
									}
								</ul>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('admin')}>{t('botão.cancelar')}</button>
							<button className="btn btn-danger" type="submit" disabled={isUpdating || !isValidUp}>
							{isUpdating && (<i className="fas fa-spinner fa-pulse m-2"></i>)}	
							{t('botão.continuar')}</button>
						</ModalFooter>
					</form>
				</Modal>

				<Modal isOpen={modalDeleteAdmin} toggle={() => toggleModal('deletarAdmin')}>
					<ModalHeader 
						toggle={() => toggleModal('deletarAdmin')} 
						close={<button className="btn-close" onClick={() => toggleModal('deletarAdmin')}></button>}>
							<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<form onSubmit={handleUpdate(makeApiCall)}>
						<ModalBody>
							<div className="alert alert-warning mb-0 fs-5">
								<p>{t('gerencia.gerencia-usuarios.modal.textoTirarAdmin')}:</p>
								<ul>
									{
										nameArray.map(u => <li key={u}> {u}</li>)
									}
								</ul>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('deletarAdmin')}>{t('botão.cancelar')}</button>
							<button className="btn btn-danger" type="submit" disabled={isUpdating || !isValidUp}>
							{isUpdating && (<i className="fas fa-spinner fa-pulse m-2"></i>)}	
							{t('botão.continuar')}</button>
						</ModalFooter>
					</form>
				</Modal>

				<Modal isOpen={modalDelete} toggle={() => toggleModal('deletar')}>
					<ModalHeader 
						toggle={() => toggleModal('deletar')} 
						close={<button className="btn-close" onClick={() => toggleModal('deletar')}></button>}>
							<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<form onSubmit={handleDelete(makeApiCall)}>
						<ModalBody>
							<div className="alert alert-danger mb-0 fs-5">
								<p>{t('gerencia.gerencia-usuarios.modal.textoDeletarUsuarie')}:</p>
								<ul>
									{
										nameArray.map(u => <li key={u}> {u}</li>)
									}
								</ul>
								<p>{t('gerencia.gerencia-usuarios.modal.cuidadoAviso')}</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('deletar')}>{t('botão.cancelar')}</button>
							<button className="btn btn-danger" type="submit" disabled={isDeleting}>
							{isDeleting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
							{t('botão.continuar')}</button>
						</ModalFooter>
					</form>
				</Modal>
			</div>
		}
	</>)
}