import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../../config/app-settings.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useHistory } from 'react-router';
import SemPermissaoPanel from '../../../components/sem-permissao-panel/sem-permissao-panel.jsx';

import adminService from '../../../services/AdminService/admin-service.js';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function GerenciaTaxonomiaEspecie(props) {
	const { push } = useHistory();
	const { user, ...settings } = useContext(AppSettings);
	const { t } = useTranslation();
	const [ listedFamilies, setListedFamilies ] = useState(null);
	const [ listedGenera, setListedGenera ] = useState(null);
	const [ listedSpecies, setListedSpecies ] = useState(null);
	const [ filteredGenera, setFilteredGenera ] = useState(null);
	const [ filteredList, setFilteredList ] = useState(null);
	const [ lastPage, setLastPage ] = useState(1);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ editModal, setEditModal ] = useState(false);
	const [ modalDelete, setModalDelete ] = useState(false);
	const [ speciesId, setSpeciesId ] = useState(0);
	const [ selectedFamilyId, setSelectedFamilyId ] = useState(-10);
	const [ selectedGenusId, setSelectedGenusId ] = useState(-10);
	const [ name, setName ] = useState('');
	const [ searchEsp, setSearchEsp ] = useState('');

	const { register, handleSubmit, reset, formState: { errors, isSubmitting, isValid } } = useForm({ mode: "onBlur" });
	const { register: update, handleSubmit: handleUpdate, reset: reset2, formState: { errors: errors2, isSubmitting: isUpdating, isValid: isValidUp } } = useForm({ mode: "onBlur" });
	const { register: reg3, handleSubmit: handleDelete, formState: { isSubmitting: isDeleting } } = useForm({ mode: "onBlur" });

	let _isMounted; //pra impedir que o componente tente alterar os estados nas funções assíncronas quando não estiver mais montado

	const registerOptions = {
		obrigatorio: {
			required: t('registerOptions.obrigatorio')
		}
	}

	useEffect(() => {
		if (user) listFamiliesAndGenera();

		return () => { _isMounted = false; };
	}, [user]);

	function listFamiliesAndGenera(){
		return adminService.listAllFamilies().then(
			response => {
				setListedFamilies(response.data);
				let familyId = response.data[0]?.id;
				setSelectedFamilyId(familyId);
				adminService.getGenus().then(
					response => {
						let tempFilteredGenera = response.data.filter(genus => genus.family_id === familyId);
						setListedGenera(response.data);
						setFilteredGenera(tempFilteredGenera);
						if(tempFilteredGenera.length !== 0) setSelectedGenusId (tempFilteredGenera[0]?.id);
						listSpecies(tempFilteredGenera[0].id);
					}
				)
			}
		).catch(err => {
			console.log(err);
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
		})
	}

	function listSpecies(generaId){
		return adminService.getSpecies().then(
			response => {
				setListedSpecies(response.data);
				setCurrentPage(1);
				
				let tempFilteredSpecies = response.data.filter(species => species.genus_id == generaId);
				if(tempFilteredSpecies.length <= 10) {
					setFilteredList(tempFilteredSpecies);
					setLastPage(1);
				}else{
					setFilteredList(tempFilteredSpecies.slice(0, 10));
					setLastPage(Math.ceil(tempFilteredSpecies.length/10));
				}
			}
		)
	}

	const openModal = (species, type) => {
		setSpeciesId(species.g.id);
		setName(species.g.name);

		if(type === 1) setEditModal(true);
		else setModalDelete(true);
	}

	const makeApiCall = (data) => {
		if(editModal){
			const request = {
				'name': data.name,
                'family_id': selectedFamilyId
			}
			return adminService.updateSpecies(request, speciesId).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.taxonomia.especie.alerta.atualizado'))
				reset2();
				setListedSpecies(null);
				listSpecies(selectedGenusId);
				toggleModal('edit');
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			})
		}else if(modalDelete){
			return adminService.deleteSpecies(speciesId).then(()=>{
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.taxonomia.especie.alerta.deletado'))
				setListedSpecies(null);
				listSpecies(selectedGenusId);
				toggleModal('delete');
			}).catch((err)=>{
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
			});
		}
	}

	const getPage = (direction) => {
        let current = currentPage;
		let isMounted = true;

        if(direction === 0){
            if(current === 1){
                settings.handleSetAlert('info', '', t('alerta.paginação.ini'));
                return 0;
            }
            current = current - 1;
        }else if (direction === 1){
            if (current === lastPage){
				settings.handleSetAlert('info', '', t('alerta.paginação.fim'));
                return 0;
            }
            current = current + 1;
        }
		if(isMounted){
			setCurrentPage(current);
			setFilteredList(
				listedSpecies.filter(species => species.genus_id == selectedGenusId).slice((current-1)*(10), ((current-1)*(10)+10))
			);
		}
    }

	const toggleModal = (name) => {
		if(name === 'edit') setEditModal(!editModal);
		else setModalDelete(!modalDelete);	
	}

	const addSpecies = (newSpecies) => {
		if(selectedGenusId === -10){
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.taxonomia.especie.alerta.faltaPai'));
            return; 
		}
		if(newSpecies.name === ''){
			settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.taxonomia.especie.alerta.nomeVazio'));
			return;
		}

		const request = {
			'name': newSpecies.name,
			'genus_id': selectedGenusId
		}
		return adminService.createSpecies(request).then(()=>{
			settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('gerencia.taxonomia.especie.alerta.cadastrado'));
			reset();
			setListedSpecies(null);
			listSpecies(selectedGenusId);
		}).catch((err)=>{
			if(err.request.status === 409)
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('gerencia.taxonomia.especie.alerta.jaExiste'));
			else
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado'));
		})
	}

	const handleFamilySelection = (id) => {
		const tempFilteredGenera = listedGenera.filter(genus => genus.family_id == id);
		setSelectedFamilyId(id);
		setFilteredGenera(tempFilteredGenera);
		
		if(tempFilteredGenera.length !== 0){
			handleGenusSelection(tempFilteredGenera[0].id);

		}else{
			handleGenusSelection(-10);
		}
    }

	const handleGenusSelection = (id) => {
		const tempFilteredSpecies = listedSpecies.filter(species => species.genus_id == id)
		setSelectedGenusId(id);
		setFilteredList(tempFilteredSpecies.slice(0, 10));
		setLastPage(Math.ceil(tempFilteredSpecies.length/10));
		setCurrentPage(1);
    }

	return (<>
		{ (user === null || (user !== undefined && filteredList === null)) &&
			<div className="fa-3x carregando-pagina">
				<i className="fas fa-spinner fa-pulse"></i>
			</div>
		}
		{ (user === undefined || (user && !user.is_admin)) &&
			<SemPermissaoPanel/>
		}
		{ (user && user.is_admin && filteredList) &&
			<div>
				<h1 className="page-header d-flex flex-column fs-18px">{t('gerencia.taxonomia.titulo')} - {t('glossario.taxonomias.especie_other')}</h1>

				<div className="row">
					<div className="col">
						<Panel>
							<PanelHeader>
								{t('gerencia.taxonomia.especie.cabeçalho.filtrar')}
							</PanelHeader>
							<PanelBody>
								<div className="mb-n2 mt-0 me-4 ms-4">
									<form onSubmit={handleSubmit(addSpecies)}>
										<div className="row mb-2 center justify-content-between">
											{/* <label htmlFor='name' className="form-label col-form-label ">{t('glossario.taxonomias.familia')}:</label> */}
											<select className="form-select w-25" value={selectedFamilyId} onChange={(e) =>handleFamilySelection(e.target.value)}>
												{	listedFamilies.length !== 0 &&
													listedFamilies.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
												}
												{	listedFamilies.length === 0 &&
													<option value={-10}>{t('gerencia.taxonomia.semFamilia')}</option>
												}
											</select>
											{/* <label htmlFor='name' className="form-label col-form-label ">{t('glossario.taxonomias.genero')}:</label> */}
											<select className="form-select w-25 ms-2" value={selectedGenusId} onChange={(e) =>handleGenusSelection(e.target.value)}>
												{	selectedGenusId !== -10 &&
													filteredGenera?.map((u)=> <option key={u.id} value={u.id}>{u.name}</option>)
												}
												{	selectedGenusId === -10 &&
													<option value={-10}>{t('gerencia.taxonomia.semGenero')}</option>
												}
											</select>
											{/* <label htmlFor='name' className="form-label col-form-label ">{t('gerencia.taxonomia.especie.novaEspecie')}:</label> */}
											<input type="text" 
												   name="newSpeciesName"
												   className="form-control fs-14px fw-lighter w-25 ms-2" 
												   placeholder={t('gerencia.taxonomia.especie.novaEspeciaNome')}
												   {...register('name', registerOptions.obrigatorio)}
											/>
											<button type="submit" disabled={isSubmitting || !isValid} className="btn btn-success d-block h-auto w-auto btn-sm fs-14px ms-2">
												{isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
												{t('gerencia.botão.adicionar')}</button>
										</div>
									</form>
								</div>
							</PanelBody>
						</Panel>
						<Panel>
							<PanelHeader>
								<div className="d-flex align-items-center">
                                    <div className="col-8">{t('glossario.taxonomias.especie_other')}</div>
                                    <div className="col d-flex align-items-center">
                                        <div className="col d-flex align-items-center me-3">
											<div className="col">
                                                <input name="searchgem" type="text" className="form-control mt-n2 mb-n2 fs-14px fw-lighter"  placeholder={t('gerencia.taxonomia.especie.buscaEspecie')} id="searchgem" onChange= {(e) => setSearchEsp(e.target.value) }   />
                                    		</div>
                                        </div>                             
                                    </div>
                                </div>
							</PanelHeader>
							<PanelBody className="mt-n2">
								{
									(filteredList?.length === 0) &&
									<p><br></br>{t('gerencia.taxonomia.especie.semEspecie')}</p>
								}
								{
									(filteredList?.length !== 0) &&
									<div>
										<div className="table-responsive">
											<table className="table mb-1 fs-14px">
												<thead>
													<tr>
														<th width="40%">{t('glossario.taxonomias.genero')}</th>
														<th width="40%">{t('glossario.taxonomias.especie')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{
														(searchEsp == "") &&
														filteredList?.map(g => (
															<tr key={g.id}>
																<td width="40%">{g.genus_name}</td>
																<td width="40%">{g.name}</td>
																<td className='row me-0 ms-0 '>
																	<div className="col">
																		<button type="submit" 
																				onClick={() => openModal({g}, 1)} 
																				className="btn btn-warning d-block h-auto w-100 btn-sm fs-14px">
																					{t('gerencia.botão.editar')} 
																		</button>
																	</div>
																	<div className="col">
																		<button type="submit" 
																				onClick={() => openModal({g}, 0)} 
																				className="btn btn-danger d-block h-auto w-100 btn-sm fs-14px">
																					{t('gerencia.botão.remover')} 
																		</button>
																	</div>
																</td>
															</tr> )
														)
													}
													{
														(searchEsp !== "") &&
														listedSpecies.filter(species => species.genus_id == selectedGenusId).filter((val) => {
															if( searchEsp == "" ){
																return val
															} else if (val.name.toLowerCase().includes(searchEsp.toLowerCase())){
																return val
															}
														}).map(g => (
															<tr key={g.id}>
																<td width="40%">{g.genus_name}</td>
																<td width="40%">{g.name}</td>
																<td className='row me-0 ms-0 '>
																	<div className="col">
																		<button type="submit" 
																				onClick={() => openModal({g}, 1)} 
																				className="btn btn-warning d-block h-auto w-100 btn-sm fs-14px">
																					{t('gerencia.botão.editar')} 
																		</button>
																	</div>
																	<div className="col">
																		<button type="submit" 
																				onClick={() => openModal({g}, 0)} 
																				className="btn btn-danger d-block h-auto w-100 btn-sm fs-14px">
																					{t('gerencia.botão.remover')} 
																		</button>
																	</div>
																</td>
															</tr> )
														)
													}
												</tbody>
											</table>
										</div>

										<div className="d-flex flex-row justify-content-center fs-14px mt-3 mb-n2">
											<p onClick={() => getPage(0)}><i className="fas fa-angle-left me-3"></i></p>
											<p>{t('paginação.page')}:  {currentPage} / {lastPage}</p>
											<p onClick={() => getPage(1)}><i className="fas fa-angle-right ms-3"></i></p>
										</div>
									</div>
								}								
							</PanelBody>
						</Panel>
					</div>
				</div>
				
				<Modal isOpen={editModal} toggle={() => toggleModal('edit')}>
					<ModalHeader
						toggle={() => toggleModal('edit')}
						close={
							<button className="btn-close" onClick={() => toggleModal('edit')}></button>
						}>
							<i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}
					</ModalHeader>
					<form onSubmit={handleUpdate(makeApiCall)}>
						<ModalBody>
							<div className="alert mb-0 fs-5">
								<p>{t('gerencia.taxonomia.especie.modal.editar.texto1')}:</p>
								<input type="text" name="name" className="form-control h-35px fs-16px fw-lighter" placeholder="Nome da espécie"
								{...update('name', registerOptions.obrigatorio)}/>
								<br/><p>{t('gerencia.taxonomia.especie.modal.editar.texto2')}</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('edit')}>{t('botão.cancelar')}</button>
							<button className="btn btn-success" type="submit" disabled={isUpdating || !isValidUp}>
							{isUpdating && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
							{t('botão.confirmar')}</button>
						</ModalFooter>
					</form>
				</Modal>

				<Modal isOpen={modalDelete} toggle={() => toggleModal('deletar')}>
					<ModalHeader toggle={() => toggleModal('deletar')} close={<button className="btn-close" onClick={() => toggleModal('deletar')}></button>}><i className="fa fa-circle-exclamation"></i> {t('modal.atenção')}</ModalHeader>
					<form onSubmit={handleDelete(makeApiCall)}>	
						<ModalBody>
							<div className="alert alert-danger mb-0 fs-5">
								<p>{t('gerencia.taxonomia.especie.modal.remover.texto1', { nome: name })}</p>
								<p>{t('gerencia.taxonomia.especie.modal.remover.texto2')}</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-white" onClick={() => toggleModal('deletar')}>{t('botão.cancelar')}</button>
							<button className="btn btn-danger" type="submit" disabled={isDeleting}>
							{isDeleting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
							{t('gerencia.botão.removerComCerteza')}</button>
						</ModalFooter>
					</form>
				</Modal>
			</div>
		}
	</>)
}