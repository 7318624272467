import React, { useState, useEffect } from 'react';

import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

//import Select from 'react-select'
import fieldService from '../../services/CoalService/field-service.js';

import { useTranslation } from 'react-i18next';

const Categorias = [
    { label: "Carvões/Lenhos de Referência", value: "Carvões/Lenhos de Referência" },
    { label: "Carvões/Lenhos de Referência", value: "Carvões/Lenhos de Referência" },
    { label: "Literatura", value: "Literatura" },
]

export default function FichaCarvao({register, setListedFields, textFields, setImageModal, coalImages = null, coalSearch = false, resetTabs = false, setResetTabs, settings}) {
    const [ pagePosition, setPagePosition ] = useState(0);
    const [ activeTab, setActiveTab ] = useState(0);
    const [ activeList, setActiveList ] = useState([]);
    const [ tabList, setTabList ] = useState([]);
    const [ selectedImages, setSelectedImages ] = useState(null);

    const { t, i18n } = useTranslation();

    const swapName = e => {return Object.assign({}, e, {name: e["name_" + i18n.language]});}

    useEffect(() => {
		// acontece quando o componente é montado
		let _isMounted = true;

        fieldService.getAllFields().then(
            response => {
                let auxiliarTabList = response.data.map(swapName);
                if(setListedFields) setListedFields(response.data);
                if(coalSearch){
                    response.data.pop(); 
                    auxiliarTabList.pop(); // Tira a aba de imagens (pop() tira a última coisa da lista)
                    auxiliarTabList.pop(); // Tira a aba de referências
                }
                setTabList(auxiliarTabList);
                setActiveList(response.data[0].children.map(swapName));
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
		//acontece quando o componente é desmontado
		return () => {
			_isMounted = false;
		}
	}, [])

    useEffect(() => {
        if(resetTabs) {
            toggleTab(0);
            setResetTabs(false);
        }
    }, [resetTabs])

    useEffect(() => {

        setActiveList(activeList.map( swapName ))

        setTabList(tabList.map( swapName ))

    }, [i18n.language])
    
    function toggleTab(tab){
		if (activeTab !== tab){
            setActiveTab(tab);
            setActiveList(tabList[tab].children.map(swapName));
		}
	}

    function downloadImage(base64Image, fileName) {
        let downloadLink = document.createElement("a");
        
        downloadLink.href = base64Image;
        downloadLink.download = fileName + '.jpg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); 
    }

    function removeImage(image){
        let index = selectedImages.indexOf(image);
        if(index > -1){
            selectedImages.splice(index, 1);
            window.scrollTo(0, pagePosition);
            pagePosition == 0 ? setPagePosition(1) : setPagePosition(0);
        }
        return selectedImages;
      }

    function createImageElement(i){
        return(
            <img src={selectedImages[i]} className="rounded mx-auto d-block h-250px w-250px" style={{cursor: "pointer"}} onClick={() => {
                setImageModal([true, selectedImages[i]]);
            }}/>
        );
    }

    function createDownloadButtonElement(i){
        return(
            <button type="button" className="btn btn-primary d-block h-auto w-25 btn-sm mt-3 ms-3 me-3 fs-14px"
                onClick={() => {
                    downloadImage(selectedImages[i], "imagem" + (i + 1));
                }}>
                    {t('imagens.botão.downloadImagem')}
            </button>
        );
    }

    function createRemoveButtonElement(i){
        return(
            <button type="button" className="btn btn-danger d-block h-auto w-25 btn-sm mt-3 ms-3 me-3 fs-14px"
                onClick={() => {
                    removeImage(selectedImages[i]);
                }}>
                    {t('gerencia.botão.remover')}
            </button>
        );
    }

    function displayImages(){
        if(coalImages != null && selectedImages == null) setSelectedImages(coalImages);
        if(selectedImages){
            let elements = [];

            // Versão duas imagens por vez
            for(let i = 0; i < selectedImages.length; i += 2){
                elements.push(<div key={i}>
                                <div className="d-flex justify-content-center">
                                    {
                                        createImageElement(i)
                                    }

                                    {
                                        (i != selectedImages.length - 1) && createImageElement(i + 1)
                                    }
                                </div>
                                <div className="d-flex justify-content-center">
                                    {
                                        createDownloadButtonElement(i)
                                    }

                                    {
                                        createRemoveButtonElement(i)
                                    }

                                    {
                                        (i != selectedImages.length - 1) && createDownloadButtonElement(i + 1)
                                    }

                                    {
                                        (i != selectedImages.length - 1) && createRemoveButtonElement(i + 1) 
                                    }
                                </div>
                                <hr className="bg-gray-600 opacity-2"/>
                              </div>);
            }

            register('images', {value: selectedImages});
            return elements;
        }
    }
    
    return (
        <div>
            <div className='row'>
                <div className='col'>
                    <Panel>
                        <PanelHeader className="mb-n1">
                            
                        </PanelHeader>
                        
                        { !tabList.length &&
                            <PanelBody>
                                <div className="fa-3x carregando-pagina ">
                                    <i className="fas fa-spinner fa-pulse"></i>
                                </div>
                            </PanelBody>
                        }

                        { tabList.length>0 &&
                            <PanelBody className="d-flex flex-row">
                                <PerfectScrollbar className="w-25">
                                    <Nav tabs className='d-flex flex-column pb-2'>
                                        {
                                            tabList.map((tab, index) => (
                                                <NavItem key={index} className="w-100">
                                                    <NavLink onClick={() => { toggleTab(index); }} className={classnames({ active: activeTab === index, 'bg-black-300' : activeTab === index, '' : activeTab !== index})} >                                                    
                                                        <span>
                                                            {t(tab.name)}
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            ))
                                        }
                                    </Nav>
                                </PerfectScrollbar>

                                <TabContent className="tab-content bg-black-300 p-3 panel bg rounded-0 mb-0 w-75" activeTab={activeTab}>
                                    <TabPane tabId={activeTab} >
                                        <PerfectScrollbar style={{height: '100%'}} options={{suppressScrollX: true}}>
                                            {
                                                activeList.map((child, i, array) => {
                                                    i = child.id;
                                                    switch(child.type){
                                                        case 'multi-select':
                                                            return(
                                                                <div className='row' key={i}>                                
                                                                    <div className='col'>
                                                                        <div className="row mb-2">
                                                                            <label className="form-label col-form-label col-md-3">{t(child.name)}</label>
                                                                            <div className="col-md-9">
                                                                                {
                                                                                    child.children.map((c, i) => {
                                                                                        i = c.id;
                                                                                        c = swapName(c);
                                                                                        if(c.type === 'multi-select-option'){
                                                                                            return(
                                                                                                <div key={i}>
                                                                                                    <div className="form-check form-check">
                                                                                                        <input className="form-check-input" name={c.name} type="checkbox" id={'inlineCheckbox' + i} {...register("charsAr." + c.id)}/>
                                                                                                        <label className="form-check-label" htmlFor={'inlineCheckbox' + i}>{t(c.name)}</label>
                                                                                                    </div>
                                                                                                    {
                                                                                                        c.children &&
                                                                                                        c.children.map((cc, i) => {
                                                                                                            i = cc.id;
                                                                                                            cc = swapName(cc);
                                                                                                            if(cc.type === 'mso-child'){
                                                                                                                return(
                                                                                                                    <div className="ms-4 form-check form-check" key={i}>
                                                                                                                        <input className="form-check-input" name={cc.name} type="checkbox" id={'inlineCheckbox' + i} {...register("charsAr." + cc.id)}/>
                                                                                                                        <label className="form-check-label" htmlFor={'inlineCheckbox' + i}>{t(cc.name)}</label>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            else if(cc.type === 'text' && textFields){
                                                                                                                return(
                                                                                                                    <div className="form-check form-check row" key={i} >
                                                                                                                        <label className="form-check-label" htmlFor={'text' + i}>{(cc.name != '' ? cc.name : c.name)}</label>
                                                                                                                        <div className='input-group input-group-sm w-25' id={'text' + i}>
                                                                                                                            <input type="text" name={(cc.name != '' ? cc.name : c.name)} className="form-control fs-12px bg-gray-800 h-auto fw-lighter" {...register("texts." + i)}/>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </div>    
                                                                                            )
                                                                                        }
                                                                                        else if((c.type === 'text') && textFields){
                                                                                            return(
                                                                                                <div key={i}>
                                                                                                    <div className='col input-group input-group-sm pt-2' id={'text' + i}>
                                                                                                        <input type="text" name={(c.name != '' ? c.name : child.name)} className="form-control fs-12px w-100 bg-gray-800 h-auto fw-lighter" placeholder={(c.name != '' ? c.name : child.name)} {...register("texts." + i)}/>
                                                                                                        { c.complement && <span className='input-group-text'>{c.complement}</span> }
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }else if((c.type === 'long-text') && textFields){
                                                                                            return(
                                                                                                <div key={i}>
                                                                                                    {
                                                                                                        c.name &&
                                                                                                        <label className="col form-check-label" htmlFor={'text' + i}>{(c.name != '' ? c.name : child.name)}</label>
                                                                                                    }
                                                                                                    <div className='col'>
                                                                                                        <div className="form mb-3">
                                                                                                            <div className='col input-group input-group-sm' id={'text' + i}>
                                                                                                                <textarea type="text" name={(c.name != '' ? c.name : child.name)} className="form-control fs-14px h-100px fw-lighter" {...register("texts." + i)}/>
                                                                                                                { c.complement && <span className='input-group-text'>{c.complement}</span> }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }else return;
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="bg-gray-600 opacity-2" />
                                                                </div>                                                            
                                                            )
                                                        case 'long-text':
                                                            if(textFields){
                                                                return(
                                                                    <div className='row' key={i}>
                                                                        <div className='col'>
                                                                            <div className="form mb-3">
                                                                                <label htmlFor='interest' className="form-label col-form-label">{child.name}</label>
                                                                                <textarea type="text" name={child.name} className="form-control fs-14px h-100px fw-lighter" {...register("texts." + i)}/>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="bg-gray-600 opacity-2" />
                                                                    </div>                                                                     
                                                                )
                                                            }else return;                                                        
                                                        case 'text':
                                                            if(textFields){
                                                                return(
                                                                    <div className='row' key={i}>                                
                                                                        <div className='col'>
                                                                            <div className="row mb-2">
                                                                                <label className="form-label col-form-label col-md-3">{child.name}</label>
                                                                                <div className="col-md-9 form-check form-check row">
                                                                                    <input type="text" name={child.name} className="form-control fs-12px w-100 bg-gray-800 h-auto fw-lighter mb-n1" {...register("texts." + i)}/> 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="bg-gray-600 opacity-2" />
                                                                    </div>
                                                                )
                                                            }else return;
                                                        case 'area':
                                                            return(
                                                                <div className='row' key={i}>
                                                                    <br></br>
                                                                    <div className='col d-flex justify-content-center'>
                                                                        <h5>{child.name}</h5>
                                                                    </div>
                                                                    <hr className="bg-gray-600 opacity-2" />
                                                                </div>
                                                            )
                                                        case 'image':
                                                            return(
                                                                <div key={i}>
                                                                    <label htmlFor="image-upload" className="btn btn-success d-block h-auto w-auto btn-lg fs-12px me-3"> {t('imagens.botão.selecionarImagens')} </label>
                                                                    <hr className="bg-gray-600 opacity-2"/>

                                                                    {displayImages()}

                                                                    <input multiple type="file" id="image-upload" accept="image/png, image/jpg, image/jpeg, image/tiff" className="d-none" 
                                                                      onChange={(event) => {

                                                                        let files = event.target.files;
                                                                        let images;

                                                                        selectedImages == null ? images = [] : images = selectedImages;
                                                                        
                                                                        for(let img in Array.from(files)){
                                                                            let roundedFileSize = Math.round(files[img].size / 1024)
                                                                            if(roundedFileSize > 500){
                                                                                settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('imagens.alerta.tamanhoExcedido'))
                                                                            }
                                                                            else{
                                                                                let reader = new FileReader();
                                                                                reader.readAsDataURL(event.target.files[img]);
                                                                                reader.onload = function(){
                                                                                    images.includes(reader.result) ? settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('imagens.alerta.imagemRepetida'))
                                                                                        : images.push(reader.result);
                                                                                    window.scrollTo(0, pagePosition);
                                                                                    pagePosition == 0 ? setPagePosition(1) : setPagePosition(0);
                                                                                }
                                                                            }
                                                                        }

                                                                        setSelectedImages(images);
                                                                    }}/>
                                                                </div>
                                                            )
                                                        default:
                                                            return(
                                                                <p key={i}> teste </p>
                                                            )
                                                    }
                                                })                                                        
                                            }
                                        </PerfectScrollbar>
                                    </TabPane>
                                </TabContent>
                            </PanelBody>
                        }
                    </Panel>
                </div>
            </div>
        </div>
    )
}