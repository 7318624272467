import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { useHistory } from 'react-router';

import { NewsFeedLogin } from '../../components/content/newsFeedLogin';
import { LoginHeader } from '../../components/header/loginHeader';	

import userService from '../../services/UserService/user-service.js';
import { useTranslation, Trans } from 'react-i18next';

export default function NovaSenha(props) {
	const settings = useContext(AppSettings);
	const { push } = useHistory();
	const { t } = useTranslation();

	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

	const registerOptions = {
		username: { 
			required: t('registerOptions.obrigatorio')
		},
		email: { 
			required: t('registerOptions.obrigatorio'),
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: t('registerOptions.nãoÉVálido')
			}
		}
	}

	useEffect(() => {
		// acontece quando o componente é montado
		settings.handleSetAppSidebarNone(true);
		settings.handleSetAppHeaderNone(true);
		settings.handleSetAppContentClass('p-0');

		//acontece quando o componente é desmontado
		return () => {
			settings.handleSetAppSidebarNone(false);
			settings.handleSetAppHeaderNone(false);
			settings.handleSetAppContentClass('');
		}
	}, []) 

	const handleFormSubmit = (data) => { 
		
		return userService.recoverPassword(data)
			.then(() => {
				settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('user.recuperarSenha.alerta.sucesso'));
				push('/acesso');
			})
			.catch((err) => {
				console.log(err);
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
			})
	}

	return (
		<div className="login login-with-news-feed">
			<div className="login-container">
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<LoginHeader title={t('user.recuperarSenha.titulo')} />

				<div className="login-content">
					<form onSubmit={handleSubmit(handleFormSubmit)} className="fs-13px">
						<div className="form-floating mb-15px">
							<input name="email" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder="Email" {...register('email', registerOptions.email)}/>
							<label htmlFor="email" className="d-flex align-items-center fs-14px text-gray-600">{t('userInfo.email')}<span className="text-danger">*{errors?.email && errors.email.message}</span></label>
						</div>
						<div className="mb-15px">
							<button type="submit" 
									disabled={isSubmitting}
									className="btn btn-primary d-block h-35px w-100 btn-lg fs-14px" >
									{
										isSubmitting ?
											(<i className="fas fa-spinner fa-pulse"></i>):
											t('botão.enviar')
									}
							</button>
						</div>
						<div className="text-gray-400 mb-40px pb-40px">
							<Trans i18nKey="user.link.voltarAcesso">
								Clique <Link to="/acesso" className="text-info">aqui</Link> para retornar para a página de acesso.
							</Trans>
							<br></br>
							<Trans i18nKey="user.link.voltarInicio">	
								Para Voltar a página inicial como convidade clique <Link to="/" className="text-info">aqui</Link>.
							</Trans>
						</div>

					</form>
				</div>
			</div>

			<NewsFeedLogin bgImg='url(/assets/img/login-bg/login-3.jpeg)' />
		</div>	
	)
}