import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from "react-i18next";

const DropdownLanguage = (props) => {
	const { i18n } = useTranslation();

	const changeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	};

	return <DropdownLanguageClass {...{changeLanguage, i18n}}/>;
} 

class DropdownLanguageClass extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	render() {
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="navbar-item navbar-language dropdown" tag="div">
				<DropdownToggle className="navbar-link dropdown-toggle" tag="a">
					<span style={{ cursor:"pointer"}}>
						<i className="fas fa-globe fa-fw"></i> {' '}
						{(this.props.i18n.language).toUpperCase()} {' '}
						<b className="caret"></b>
					</span> 
					
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-end" tag="div" >
					<DropdownItem onClick={this.props.changeLanguage} value="pt"> Português</DropdownItem>
					<DropdownItem onClick={this.props.changeLanguage} value="en"> English</DropdownItem>
					<DropdownItem onClick={this.props.changeLanguage} value="fr"> Français</DropdownItem>
					
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default DropdownLanguage;
