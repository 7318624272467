import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../panel/panel.jsx';

export default function SemResultPanel () {

	return(
        <Panel> 
            <PanelHeader>
            </PanelHeader>
            <PanelBody className="d-flex flex-column">
                <p>Nenhum resultado encontrado</p>
            </PanelBody>
        </Panel>
	)
}