import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import { NewsFeedLogin } from '../../components/content/newsFeedLogin';

import userService from '../../services/UserService/user-service.js';
import { LoginHeader } from '../../components/header/loginHeader.jsx';

import { useTranslation, Trans } from 'react-i18next';

export default function Login (props) {
	const { push } = useHistory();
	const settings = useContext(AppSettings);
	const { t } = useTranslation();

	const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm();

 	useEffect(() => {
		// acontece quando o componente é montado
		settings.handleSetAppSidebarNone(true);
		settings.handleSetAppHeaderNone(true);
		settings.handleSetAppContentClass('p-0');

		//acontece quando o componente é desmontado
		return () => {
			settings.handleSetAppSidebarNone(false);
			settings.handleSetAppHeaderNone(false);
			settings.handleSetAppContentClass('');
		}
	}, []) 

	const handleFormSubmit = (data) => { 
		const { username, password } = data;
		const { rememberMe } = data;

		
		return	userService.login( { username, password }, rememberMe)
				.then((user) => {
					settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('user.acesso.alerta.acessoBemSucedido.mensagem'));
					settings.setUser(user);
					push('/');
				})
				.catch((err) => {
					reset({password: ''});
					if(err.response?.status === 401){
						settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('user.acesso.alerta.apelidoSenhaErrados.mensagem'))
					}else{
						settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
					}
				})

	}

	return (
			<div className="login login-with-news-feed">
				<div className="login-container">
					<LoginHeader title={t('user.acesso.titulo')} subtitle={t('user.acesso.subtitulo')}/>

					<div className="login-content">
						<form onSubmit={handleSubmit(handleFormSubmit)} className="fs-13px">

							<div className="form-floating mb-15px">
								<input type="text" name="username" className="form-control h-35px fs-16px fw-lighter" placeholder="Login" 
									{...register('username')} />
								<label htmlFor="username" className="d-flex align-items-center fs-14px text-gray-600">{t('userInfo.apelido')}</label>
							</div>
							<div className="form-floating mb-15px">
								<input name="password" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder="Password" 
									{...register('password')}/>
								<label htmlFor="password" className="d-flex align-items-center fs-14px text-gray-600">{t('userInfo.senha')}</label>
							</div>
							<div className="form-check mb-30px">
								<input className="form-check-input" type="checkbox" value="1" id="rememberMe" 
									{...register('rememberMe')}/>
								<label className="form-check-label" htmlFor="rememberMe">
									{t('user.acesso.lembrarDeMim')}
								</label>
							</div>
							<div className="mb-15px">
								<button type="submit" 
										disabled={isSubmitting}
										className="btn btn-primary d-block h-35px w-100 btn-lg fs-14px" >
										{isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
										{t('user.acesso.botão.acessar')}
								</button>
							</div>
							<div className="text-gray-400 mb-40px pb-40px">
								
								<Trans i18nKey="user.link.cadastro">
									Ainda não possui uma conta? Clique <Link to="/cadastro" className="text-info">aqui</Link> para cadastrar-se.
								</Trans>
								<br></br>
								<Trans i18nKey="user.link.recuperarSenha">
									Esqueceu sua senha? Clique <Link to="/esqueci-minha-senha" className="text-info">aqui</Link> para recuperá-la.
								</Trans>
								<br></br>
								<Trans i18nKey="user.link.voltarInicio">
									Para Voltar a página inicial como convidade clique <Link to="/" className="text-info">aqui</Link>.
								</Trans>

							</div>

						</form>
					</div>
				</div>

				<NewsFeedLogin bgImg='url(/assets/img/login-bg/login-1.jpeg)' />
			</div>	
		)
}