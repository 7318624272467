import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { useHistory } from 'react-router';

import { NewsFeedLogin } from '../../components/content/newsFeedLogin';
import { LoginHeader } from '../../components/header/loginHeader';	

import userService from '../../services/UserService/user-service.js';
import { useTranslation, Trans } from 'react-i18next';

export default function NovaSenha(props) {
	const settings = useContext(AppSettings);
	const { push } = useHistory();
	const { t } = useTranslation();

	const { watch, register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

	const { token } = useParams();

	const newPasswordOptions = {
		password: {
            required: t('registerOptions.obrigatorio'),
            minLength: {
              value: 8,
              message: t('registerOptions.minDigito', {x: 8})
            },
          },
          confirmPwd: {
              validate: (val) => {
                  if (watch('password') != val) {
                    return t('registerOptions.naoConfere');
                  }
                }
          }
	}

	useEffect(() => {
		// acontece quando o componente é montado
		settings.handleSetAppSidebarNone(true);
		settings.handleSetAppHeaderNone(true);
		settings.handleSetAppContentClass('p-0');

		//acontece quando o componente é desmontado
		return () => {
			settings.handleSetAppSidebarNone(false);
			settings.handleSetAppHeaderNone(false);
			settings.handleSetAppContentClass('');
		}
	}, []) 

	const handleFormSubmit = (data) => { 
		return userService.basicFindUser(token).then(
			response => {
				if(response.data === "ExpiredToken"){
					settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('user.alterarSenha.alerta.tokenExpirado'));
					push('/acesso');
				}
				else{
					userService.newPassword(data, response.data.email).then(
						response => {
							settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('user.alterarSenha.alerta.sucesso'));
							push('/acesso');
						}
					).catch((err) => {
						console.log(err);
						settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
					})
				}
			}
		)
	}

	return (
		<div className="login login-with-news-feed">
			<div className="login-container">
				<LoginHeader title={t('user.alterarSenha.titulo')} />

				<div className="login-content">
					<form onSubmit={handleSubmit(handleFormSubmit)} className="fs-13px">
						<div className="form-floating mb-15px">
							<input name="password" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder="Senha" 
								{...register('password', newPasswordOptions.password)}/>
							<label htmlFor="password" className="d-flex align-items-center fs-14px text-gray-600">
								{t('userInfo.senha')}
								<span className="text-danger">* {errors?.password && errors.password.message}</span>
							</label>
						</div>
                        <div className="form-floating mb-15px">
							<input name="confirmPwd" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder="Confirme a senha" 
								{...register('confirmPwd', newPasswordOptions.confirmPwd)}/>
							<label htmlFor="password" className="d-flex align-items-center fs-14px text-gray-600">
								{t('userInfo.confirmeSenha')}
								<span className="text-danger">* {errors?.confirmPwd && errors.confirmPwd.message}</span>
							</label>
						</div>
						<div className="mb-15px">
							<button type="submit" 
									disabled={isSubmitting}
									className="btn btn-primary d-block h-35px w-100 btn-lg fs-14px" >
									{
										isSubmitting ?
											(<i className="fas fa-spinner fa-pulse"></i>):
											t('botão.enviar')
									}
							</button>
						</div>
					</form>
				</div>
			</div>

			<NewsFeedLogin bgImg='url(/assets/img/login-bg/login-3.jpeg)' />
		</div>	
	)
}