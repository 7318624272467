import React, { useState, useEffect, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useForm } from 'react-hook-form';
import { AppSettings } from '../../config/app-settings.js';

import SemPermissaoPanel from '../../components/sem-permissao-panel/sem-permissao-panel.jsx';

import userService from '../../services/UserService/user-service.js';

import { useTranslation } from 'react-i18next';

export default function MeusDados () {
	const { user, ...settings } = useContext(AppSettings);
	const { t } = useTranslation();
	const [ countriesList, setCountriesList ] = useState([]);
	const [ titlesList, setTitlesList ] = useState([]);

	const { register, handleSubmit, setValue, getValues, reset, formState: { errors, isSubmitting, isValid } } = useForm({ mode: "onBlur" });
	const { register: registerEmail, handleSubmit: handleEmail, reset: resetEmail, formState: { errors: errorsEmail, isSubmitting: isSubmittingEmail, isValid: isValidEmail }, watch: watchEmail } = useForm({ mode: "onBlur" });
	const { register: registerPwd, handleSubmit: handlePwd, reset: resetPwd, formState: { errors: errorsPwd, isSubmitting: isSubmittingPwd, isValid: isValidPwd }, watch: watchPwd } = useForm({ mode: "onBlur" });

	const registerOptions = {
		name: { required: t('registerOptions.obrigatorio') },
		username: { 
			required: t('registerOptions.obrigatorio'),
			minLength: {
				value: 4,
				message: t('registerOptions.minDigito', {x: 4})
			}
		},
		email: { 
			required: t('registerOptions.obrigatorio'),
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: t('registerOptions.nãoÉVálido')
			}
		},
		confirmEmail: {
			validate: (val) => {
				if (watchEmail('email') != val) {
				  return t('registerOptions.naoConfere');
				}
			  }
		},
		oldPassword: {
		  required: t('registerOptions.obrigatorio'),
		  minLength: {
			value: 8,
			message: t('registerOptions.minDigito', {x: 8})
		  }
		},
		password: {
		  required: t('registerOptions.obrigatorio'),
		  minLength: {
			value: 8,
			message: t('registerOptions.minDigito', {x: 8})
		  }
		},
		confirmPwd: {
			validate: (val) => {
				if (watchPwd('password') != val) {
				  return t('registerOptions.naoConfere');
				}
			  }
		},
		institution: { required: t('registerOptions.obrigatorio') },
		title_id: { required: t('registerOptions.obrigatorio') },
		country_id: { required: t('registerOptions.obrigatorio') },
		interest: { required: t('registerOptions.obrigatorio') },
		academic: { required: t('registerOptions.obrigatorio') }
	  };

	// acontece quando o estado de user é alterado
	useEffect(() => {
		let isMounted = true;

		if (user) {
			userService.listCountries().then( (response) => {
				if (isMounted) {
					setCountriesList(response.data);
					register('country_id', { value: user.country_id });
					setValue('country_id', user.country_id);
				}
			});
			userService.listTitles().then( (response) => {
				if (isMounted) {
					setTitlesList(response.data);
					register('title_id', { value: user.title_id });
					setValue('title_id', user.title_id);
				}
			});
			
			register('academic', { value: user.academic });
			setValue('academic', user.academic);
		
			register('interest', { value: user.interest });
			setValue('interest', user.interest);

			document.getElementById("campo-dados-email").value = user.email;

			let userNorm = normalizeData(user);
			reset(userNorm);
		}

		return () => isMounted=false;
	}, [user]);

	const handleFormSubmit = (data) => {
		return userService.updateUser( normalizeData(data) ).then( () => {
			settings.setUser(data); // Atualiza usuário atual do site pra ter as novas informações
			settings.handleSetAlert('success', t('alerta.titulo.sucesso'), t('user.meusDados.alerta.atualizados'));
		}).catch( (error) => {
			if(error.request.status == 400){
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('user.cadastro.alerta'));
			}else{
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
			}
		})
	}

	const handleEmailSubmit = (data) => { 
		return userService.changeEmail(data).then( () => {
			setValue('email', data.email);
			settings.setUser(getValues());
			resetEmail();
			settings.handleSetAlert('info', t('alerta.titulo.sucesso'), t('user.alterarEmail.alerta.sucesso'));
		}).catch( (error) => {
			if(error.request.status == 400){
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('user.cadastro.alerta.senhaAtualIncorreta'));
			}else{
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
			}
		})
	}

	const handlePwdSubmit = (data) => { 
		return userService.changePassword(data).then( () => {
			resetPwd();
			settings.handleSetAlert('info', t('alerta.titulo.sucesso'), t('user.alterarSenha.alerta.sucesso'));
		}).catch( (error) => {
			if(error.request.status == 400){
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('user.cadastro.alerta.senhaAtualIncorreta'));
			}else{
				settings.handleSetAlert('danger', t('alerta.titulo.erro'), t('alerta.algoDeuErrado.mensagem'));
			}
		})
	}

	const normalizeData = (data) => 
		Object.fromEntries(
			Object.entries(data).map(([k, v]) => {
				if ((k === 'country_id' || k==='title_id') && v === null) return [k, -1];
				if ((k === 'country_id' || k==='title_id') && v === -1) return [k, null];
				return [k,v];
			})
		)

	return(
		<>
			{ user === null && 
				<div className="fa-3x carregando-pagina">
					<i className="fas fa-spinner fa-pulse"></i>
				</div>
			}
			{ user === undefined && 
				<>
					<SemPermissaoPanel/>
				</>
			}
			{ user && 
				<>
					<h1 className="page-header d-flex flex-column fs-18px">{t('user.meusDados.titulo')}{' '}</h1>
					<div className="row">
						<div className='col w-50'>
							<form onSubmit={handleSubmit(handleFormSubmit)}>	
								<Panel>
									<PanelHeader>{t('userInfo.titulo')}</PanelHeader>
									<PanelBody>
											<div className='meus-dados'>

												<div className="row mb-1 center">
													<label htmlFor='name' className="form-label col-form-label ">
														{t('userInfo.nome')}: <span className="text-danger">* {errors?.name && errors.name.message}</span>
													</label>
													<input type="text" name="name" className="form-control fs-14px fw-lighter" placeholder={t('userInfo.nome')}
														{...register('name', registerOptions.name)} />
												</div>

												<div className="row mb-1 center">
													<label className="form-label col-form-label ">
														{t('userInfo.apelido')}: <span className="text-danger">* {errors?.username && errors.username.message}</span>
													</label>
													<input type="text" name="username" className="form-control fs-14px fw-lighter" placeholder={t('userInfo.apelido')}
														{...register('username', registerOptions.username)} />
												</div>

												<div className="row mb-1 center">
													<label htmlFor='email' className="form-label col-form-label ">
														{t('userInfo.email')}:  <span className="text-danger">* {errors?.email && errors.email.message}</span>
													</label>
													<input disabled type="text" name="email" id="campo-dados-email"className="form-control fs-14px fw-lighter" placeholder={t('userInfo.email')}
														/> {/* Não registra email */}
												</div>

												<div className="row mb-1 center">
													<label htmlFor='institution' className="form-label col-form-label ">
														{t('userInfo.instituição', registerOptions.name)}: <span className="text-danger">* {errors?.institution && errors.institution.message}</span>
													</label>
													<input type="text" name="institution" className="form-control fs-14px fw-lighter" placeholder={t("userInfo.instituição")}
														{...register('institution', registerOptions.name)} />
												</div>

												<div className="row mb-1 center">
													<label className="form-label col-form-label ">
														{t('userInfo.país')}: <span className="text-danger">* {errors?.country_id && errors.country_id.message}</span>
													</label>
													<select name="country" className="form-select fs-14px fw-lighter" {...register('country_id', registerOptions.name)}>
														<option defaultValue disabled value={-1}>{t('dropDown.país')}</option>
														{countriesList.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
													</select>
												</div>

												<div className="row mb-1 center">
													<label className="form-label col-form-label ">
														{t('userInfo.titulação')}: <span className="text-danger">* {errors?.title_id && errors.title_id.message}</span>
													</label>
													<select name="title" className="form-select fs-14px fw-lighter" {...register('title_id', registerOptions.name)}>
														<option defaultValue disabled value={-1}>{t('dropDown.título')}</option>
														{titlesList.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
													</select>
												</div>

												<div className="row mb-1 center">
													<label htmlFor='academic' className="form-label col-form-label ">
														{t('userInfo.formaçãoAcadêmica')}: <span className="text-danger">* {errors?.academic && errors.academic.message}</span>
													</label>
													<input type="text" name="academic" ref={register} className="form-control fs-14px fw-lighter" placeholder="Descreva resumidamente sua formação acadêmica."
														{...register('academic', registerOptions.name)} />
												</div>

												<div className="row mb-1 center">
													<label htmlFor='interest' className="form-label col-form-label ">
														{t('userInfo.areasDeInteresse')}: <span className="text-danger">* {errors?.interest && errors.interest.message}</span>
													</label>
													<input type="text" name="interest" ref={register} className="form-control fs-14px fw-lighter" placeholder="Apresente suas áreas de interesse em Arqueologia e Arqueobotânica."
														{...register('interest', registerOptions.name)} />
												</div>
												
												<button type="submit" disabled={isSubmitting || !isValid} className="btn btn-success d-block h-35px w-100 btn-lg fs-14px" id="b1">
													{isSubmitting && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
													{t('botão.salvar')}
												</button>

											</div>
									</PanelBody>
								</Panel>									
							</form>
						</div>

						<div className='col w-50'>
							<form onSubmit={handleEmail(handleEmailSubmit)}>
								<Panel>
									<PanelHeader>{t('user.alterarEmail.titulo')}</PanelHeader>
									<PanelBody>
										<div className='meus-dados'>

											<div className="row mb-1 center">
												<label className="form-label col-form-label">
													{t('userInfo.senhaAtual')}: <span className="text-danger">* {errorsEmail?.oldPassword && errorsEmail.oldPassword.message}</span>
												</label>
												<input name="oldPassword" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder={t("userInfo.senhaAtual")} id="senhaAtual1" 
													{...registerEmail('oldPassword', registerOptions.oldPassword)} />
											</div>

											<div className="row mb-1 center">
												<label htmlFor='email' className="form-label col-form-label ">
													{t('userInfo.email')}:  <span className="text-danger">* {errorsEmail?.email && errorsEmail.email.message}</span>
												</label>
												<input name="email" type="text" className="form-control fs-16px fw-lighter" placeholder={t('userInfo.email')}
													{...registerEmail('email', registerOptions.email)} />
											</div>

											<div className="row mb-1 center">
												<label className="form-label col-form-label">
													{t('userInfo.confirmeEmail')}: <span className="text-danger">* {errorsEmail?.confirmEmail && errorsEmail.confirmEmail.message}</span>
												</label>
												<input name="confirmPwd" type="text" className="form-control h-35px fs-16px fw-lighter" placeholder={t("userInfo.confirmeEmail")} 
													{...registerEmail('confirmEmail', registerOptions.confirmEmail)} />
											</div> 

											<button type="submit" disabled={isSubmittingEmail || !isValidEmail} className="btn btn-success d-block h-35px w-100 btn-lg fs-14px" id="b2">
												{isSubmittingEmail && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
												{t('botão.salvar')}
											</button>

										</div>
									</PanelBody>
								</Panel>
							</form>

							<form onSubmit={handlePwd(handlePwdSubmit)}>
								<Panel>
									<PanelHeader>{t('user.alterarSenha.titulo')}</PanelHeader>
									<PanelBody>
										<div className='meus-dados'>

											<div className="row mb-1 center">
												<label className="form-label col-form-label">
													{t('userInfo.senhaAtual')}: <span className="text-danger">* {errorsPwd?.oldPassword && errorsPwd.oldPassword.message}</span>
												</label>
												<input name="oldPassword" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder={t("userInfo.senhaAtual")} id="senhaAtual2" 
													{...registerPwd('oldPassword', registerOptions.oldPassword)} />
											</div>

											<div className="row mb-1 center">
												<label className="form-label col-form-label">
													{t('userInfo.novaSenha')}: <span className="text-danger">* {errorsPwd?.password && errorsPwd.password.message}</span>
												</label>
												<input name="password" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder={t("userInfo.novaSenha")} id="senha" 
													{...registerPwd('password', registerOptions.password)} />
											</div>

											<div className="row mb-1 center">
												<label className="form-label col-form-label">
													{t('userInfo.confirmeSenha')}: <span className="text-danger">* {errorsPwd?.confirmPwd && errorsPwd.confirmPwd.message}</span>
												</label>
												<input name="confirmPwd" type="password" className="form-control h-35px fs-16px fw-lighter" placeholder={t("userInfo.confirmeSenha")} id="confirma-senha" 
													{...registerPwd('confirmPwd', registerOptions.confirmPwd)} />
											</div>

											<button type="submit" disabled={isSubmittingPwd || !isValidPwd} className="btn btn-success d-block h-35px w-100 btn-lg fs-14px" id="b3">
												{isSubmittingPwd && (<i className="fas fa-spinner fa-pulse m-2"></i>)}
												{t('botão.salvar')}
											</button>

										</div>
									</PanelBody>
								</Panel>
							</form>
						</div>
					</div>
				</>
			}
		</>
	)
}