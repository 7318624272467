import instance from '../../axios-instance';
import Cookies from 'js-cookie';

export default{

    async getAllFields(){
        try{
            const response = await instance.get('/campos');
            return response;
        }catch(err){
            throw err;
        }
    }
    
}