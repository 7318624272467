import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'

// TODO
export default function useMenuTitles(){
  const { t, i18n } = useTranslation();

  const [ guest, setGuest ] = useState([]);
  const [ user, setUser ] = useState([]);
  const [ admin, setAdmin ] = useState([]);

  const defaultChildren = [
    { path: '/registros/todos', title: t('navegação.dbs.todos')},
    { path: '/registros/arq-fosseis', title: t('navegação.dbs.fosseis')},
    { path: '/registros/referencia', title: t('navegação.dbs.carvaoRef')},
    { path: '/registros/literatura', title: t('navegação.dbs.literatura')},
    { path: '/registros/publicos', title: t('navegação.dbs.publicos')}
  ]

  function handleChangeLanguage(){
    const guest = [
      { path: '/pesquisa', icon: 'fa fa-magnifying-glass', title: t('navegação.pesquisa') },
    
      { path: '/registros', icon: 'fa fa-table-list', title: t('navegação.dbs.titulo'),
        children: defaultChildren
      }
    ]

    const user = [
    
      { path: '/novo-registro', icon: 'fa fa-square-plus', title: t('navegação.novoRegistro') },
    
      guest[0],

      { path: '/registros', icon: 'fa fa-table-list', title: t('navegação.dbs.titulo'),
        children: [
          ...defaultChildren,
          { path: '/registros/meus', title: t('navegação.dbs.meusRegistros')}
        ]
      },
    
      { path: '/taxonomia', icon: 'fa fa-book-open', title: t('navegação.buscaTax.titulo'),
            children: [
              { path: '/taxonomia/familia', title: t('navegação.buscaTax.familia')},
              { path: '/taxonomia/genero', title: t('navegação.buscaTax.genero') }
            ]
      },

      {path: '/sitios', icon: 'fa fa-location-dot', title: t('navegação.sitios')},
    ]
  
    const admin = [
      
      ...user,
    
      { path: '/admin', icon: 'fa fa-folder', title: t('navegação.admin.titulo'),
        children: [
          {path: '/admin/taxonomia', title: t('navegação.admin.taxonomia.titulo'),
            children: [
              {path: '/admin/taxonomia/familias', title: t('navegação.admin.taxonomia.familia')},
              {path: '/admin/taxonomia/generos', title: t('navegação.admin.taxonomia.genero')},
              {path: '/admin/taxonomia/especies', title: t('navegação.admin.taxonomia.especie')}
            ]
          },
          { path: '/admin/usuaries', title: t('navegação.admin.users.titulo'),
            children: [
              {path: '/admin/usuaries/pendentes', title: t('navegação.admin.users.authPendente')},
              {path: '/admin/usuaries/gerencia', title: t('navegação.admin.users.gerencia')},
            ]
          }
        ]
      }
    
    ]

    return {
      guest, user, admin
    }
  }
  
  useEffect(() => {
    const { guest, user, admin } = handleChangeLanguage();
    setGuest(guest);
    setUser(user);
    setAdmin(admin);
  }, [i18n.language]);

  return {
    guest, user, admin
  }
}