import instance from '../../axios-instance';
import Cookies from 'js-cookie'

export default{

    /*           Cadastro, Login e Logout               */

    async register(form) {
        try{
            const response = await instance.post('/cadastro', form);

            return response;
        }catch(err){
            throw err;
        }
    },

    async login(form, rememberMeStatus){
        //const doisMinutos = 1/720;
        // TODO caccavo, luciana - quando a aplicaçãoa virar https a gente tem q mudar esse secure pra true
        try{
            const response = await instance.post('/login', form);

            if(rememberMeStatus == 1){
                Cookies.set('anth-token', response.data.success.token, {expires: 30, secure: false, sameSite: 'strict'});
                Cookies.set('username', form.username, {expires: 30, secure: false, sameSite: 'strict'});
            }else{
                Cookies.set('anth-token', response.data.success.token, {expires: 2/6, secure: false, sameSite: 'strict'});
                Cookies.set('username', form.username, {expires: 2/6, secure: false, sameSite: 'strict'});
            }
            
            return response.data.user;
        }catch(err){
            throw err;
        }
    },
    
    async logout() {
        try{
            const token = Cookies.get('anth-token')
            await instance.get('/logout/', {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            Cookies.remove('anth-token');
            Cookies.remove('username');
        }catch(err){
            throw err;
        }
    },



    /*           Checando permissões do usuário               */

    async getUser(){ //se o data da resposta vier vazio, não é usuário. Se for usuário, checar o is_admin. 0 é usuario normal, 1 é admin e 2 é super.
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.get('/user', {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch(err){
            throw err;
        }
    },



    /*                   Alterar email                         */

    async changeEmail(data) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/trocarEmail', data, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch(err){
            throw err;
        }
    },



    /*                   Alterar senha                         */

    async changePassword(data) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/trocarSenha', data, {
                headers: { 'Authorization': 'Bearer ' + token}
            });

            Cookies.remove('anth-token');
            Cookies.set('anth-token', response.data.success.token, {expires: 2/6, secure: false, sameSite: 'strict'});

            return response;
        }catch(err){
            throw err;
        }
    },

    async recoverPassword(data) {
        try{
            const response = await instance.post('/recuperarSenha', data);
            return response;
        }catch(err){
            throw err;
        }
    },

    async newPassword(data, id) {
        try{
            const response = await instance.post('/newPassword/' + id, data);
            return response;
        }catch(err){
            throw err;
        }
    },

    async basicFindUser(data) {
        try{
            const response = await instance.post('/pegarUsuarioParaSenha', data);
            return response;
        }catch(err){
            throw err;
        }
    },



    /*             Atualizar dados do user                   */

    async updateUser(data) {
        try{
            const token = Cookies.get('anth-token')
            const response = await instance.put('/user', data, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch(err){
            throw err;
        }
    },



    /*            Pegar carvões do user                      */

    async getCoalsByUser(id){
        try{
            const token = Cookies.get('anth-token');
            const response = await instance.get('search/author/' + id, {
                headers: { 'Authorization': 'Bearer ' + token}
            });
            return response;
        }catch(err){
            throw err;
        }
    },



    /*                    Extras                      */

    async listCountries(){
        try{
            const response = await instance.get('/countries');
            return response;
        }catch (err){
            throw err;
        }
    },

    async listTitles(){
        try{
            const response = await instance.get('/titles');
            return response;
        }catch (err){
            throw err;
        }
    }

}